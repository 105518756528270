import {Component} from '@angular/core';
import SwiperCore, {Navigation, Pagination, SwiperOptions} from 'swiper';
SwiperCore.use([Navigation, Pagination]);
import {PublicationsService} from "@app/_services/publications.service";

@Component({
  selector: 'homepage-news',
  templateUrl: 'homepage-news.component.html',
  styleUrls: ['homepage-news.component.scss']
})
export class HomepageNewsComponent {
  public config: SwiperOptions | any = {
    lazy: true,
    spaceBetween: 40,
    slidesPerView: 1,
    pagination: {
      el: 'homepage-news .bullets',
      clickable: true
    },
    navigation: {
      prevEl: 'homepage-news .btn.prev',
      nextEl: 'homepage-news .btn.next'
    },
    breakpoints: {
      960: {
        slidesPerView: 2
      },
    }
  };

  public items: any[] = [];

  constructor(
    private publicationsService: PublicationsService,
  ) {
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.publicationsService.list({type: 'news', limit: 5}).subscribe(result => {
      this.items = result.data;
    });
  }
}
