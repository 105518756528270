import {Component} from '@angular/core';

@Component({
  selector: 'homepage-cards',
  templateUrl: 'homepage-cards.component.html',
  styleUrls: ['homepage-cards.component.scss']
})
export class HomepageCardsComponent {
  constructor() {
  }

  ngOnInit() {
  }

}
