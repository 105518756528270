import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";
import {FormGroup, FormControl} from "@angular/forms";

@Component({
    selector: 'feedback-section',
    templateUrl: 'feedback-section.component.html',
    styleUrls: ['feedback-section.component.scss']
})
export class FeedbackSectionComponent {
    @Input() section: any;

    public formGroup: FormGroup;
    public loading = false;
    public sent = false;

    constructor(private formsService: FormsService, private objectsService: ObjectsService) {
    }

    get objectType(): any {
      return this.objectsService.getValue(this.section, 'feedback-form-type');
    }
    get email(): string {
      return this.objectsService.getValue(this.section, 'feedback-support-email');
    }


    ngOnInit() {
      this.formGroup = new FormGroup({mailto: new FormControl(this.email)});
    }

    onSubmit() {
      this.formGroup.markAllAsTouched();
      if (this.formGroup.valid) this.formsService.save('model', this.objectType.name, null, this.formGroup.value).subscribe(res => {
        this.sent = true;
      }, error => {
        console.log(error);
      });
    }
}
