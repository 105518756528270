<slider side="left">

    <div body>
        <pages-menu *ngIf="rootPages" [root]="rootPages" class="top-menu"></pages-menu>
        <div class="btn-menu">
            <div *ngIf="isRusPage">
                <a href="https://ais.vniigaz-cert.ru/" target="_blank">
                    Личный кабинет (АИС СЦ)
                </a>
            </div>
            <div>
                <locale></locale>
            </div>
        </div>
    </div>        
</slider>