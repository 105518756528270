<div class="department" [class.active]="active" (click)="touched = true">
  <div class="bar">
    <div class="left" (click)="toggle()"></div>
    <div class="logo" (click)="toggle()">{{ftl}}</div>
    <div class="mid" (click)="toggle()"><div class="name">{{department?.title}}</div></div>
    <div class="right" *ngIf="editable">
      <button type="button" title="Создать подразделение" class="btn icon add-department" (click)="addDepartment()"></button>
      <button type="button" title="Добавить сотрудника" class="btn icon add-member" (click)="addMember()"></button>
      <button type="button" title="Редактировать" class="btn icon edit" (click)="edit()"></button>
      <button *ngIf="isDeletable" title="Удалить" type="button" class="btn icon delete" (click)="delete()"></button>
    </div>
  </div>
  <div class="items">
    <company-member [member]="member" [parent]="department" [editable]="editable" *ngFor="let member of members"></company-member>
    <company-department [department]="dep" [parent]="department" [editable]="editable" *ngFor="let dep of departments"></company-department>
  </div>
</div>
