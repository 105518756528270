<ng-container *ngIf="licenceService.isActive">
  <div class="site-admin-control">
    <div class="site-admin-control-toggle" (click)="toggle()">
        Показать скрытые
        <switch [val]="showDeleted"></switch>        
    </div>
    <div class="site-admin-page-block">
        <div class="site-admin-company">
            <ico ico="cloud_24" class="page-lable"  color="#FFF"></ico>
            <span>Сайт компании</span>            
        </div>        
        <ico ico="home_plus_24" class="page-control" (click)="addLocale()"></ico>
    </div>
</div>
<pages-tree></pages-tree>

</ng-container>
<ng-container *ngIf="!licenceService.isActive">
  <p>Лицензия не активна. Перейдите в раздел <a routerLink="/administrate/licence">Данные о лицензии</a></p>
</ng-container>


