<div class="slider-overlay" (click)="close()"></div>
<div class="slider-bar" [style.width]="width" [ngClass]="css" [ngStyle]="direction">
  <div class="header">
    <div class="title">
      <ng-content select="[header]"></ng-content>
        <ico (click)="close()"></ico>
    </div>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
    <ng-content select="[footer]"></ng-content>
</div>