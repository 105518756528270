import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-relation-multiple',
  templateUrl: 'field-relation-multiple.component.html',
  styleUrls: ['field-relation-multiple.component.scss']
})
export class FieldRelationMultipleComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;

  constructor() {
  }

  ngOnInit() {
  }

}
