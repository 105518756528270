import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-boolean',
  templateUrl: 'form-field-boolean.component.html',
  styleUrls: ['form-field-boolean.component.scss']
})
export class FormFieldBooleanComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }


}
