import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'filter-field-boolean',
  templateUrl: 'filter-field-boolean.component.html',
  styleUrls: ['filter-field-boolean.component.scss']
})
export class FilterFieldBooleanComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.control.setValue(this.initialValue, {emitEvent: false});
  }

  get initialValue() {
    return this.field.value?.data[0] || '';
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }


}
