<div *ngIf="page">
  <!-- <div  class="limiter">
    <page-breadcrumbs [page]="page"></page-breadcrumbs>
  </div> -->
  <div class="pages" [ngSwitch]="page?.type?.name || page?.type">
    <content-page *ngSwitchCase="'content'" [page]="page" [editMode]="editMode"></content-page>
    <publications-page *ngSwitchCase="'publications'" [page]="page" [editMode]="editMode"></publications-page>
    <registry-page *ngSwitchCase="'registry'" [page]="page" [editMode]="editMode"></registry-page>
    <publication-page *ngSwitchCase="'publication'" [page]="page" [editMode]="editMode"></publication-page>
    <tk-structure-page *ngSwitchCase="'tk-structure'" [page]="page" [editMode]="editMode"></tk-structure-page>
    <p *ngSwitchDefault>Page type {{page?.type?.name}} is undefined</p>
  </div>
</div>
<div *ngIf="!loading && !page">
  <page-not-found></page-not-found>
</div>
<!--div class="loader" *ngIf="loading"></div-->
