import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {FormFieldImageComponent} from "@app/_modules/form-fields/types/image/form-field-image.component";
import {FieldImageReadonlyComponent} from "@app/_modules/form-fields/types/image/readonly/field-image-readonly.component";
import {FieldImageSingleComponent} from "@app/_modules/form-fields/types/image/single/field-image-single.component";
import {FieldImageMultipleComponent} from "@app/_modules/form-fields/types/image/multiple/field-image-multiple.component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SortablejsModule
  ],
    declarations: [
        FormFieldImageComponent,
        FieldImageReadonlyComponent,
        FieldImageSingleComponent,
        FieldImageMultipleComponent
    ],
  exports: [
    FormFieldImageComponent
  ]
})
export class FormFieldImageModule {}
