import {Component, Input} from '@angular/core';
import {PagesService} from "@app/_services/pages.service";
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'pages-tree-item',
  templateUrl: 'pages-tree-item.component.html',
  styleUrls: ['../pages-tree.component.scss', 'pages-tree-item.component.scss']
})
export class PagesTreeItemComponent {
  @Input() page: any;
  @Input() parent: any;
  public active = false;
  public touched = false;
  public subscription: Subscription;

  constructor(
    public pagesService: PagesService, 
    private formsService: FormsService, 
    private listsService: ListsService,
    private dialog: DialogService) {
  }

  ngOnInit() {
    this.subscription = this.listsService.controls(this.listId).subscribe(res => {
      if (this.touched) this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  get listId() {
    return this.page.id;
  }

  get parentListId() {
    return this.parent?.id || 'pages-tree-root';
  }

  get logo() {
    return this.page?.logo?.data.links?.full;
  }

  get noLogoLetters() {
    return this.page.name.replace(' ', '').slice(0, 2);
  }

  get children() {
    return this.page.children?.data;
  }

  get isDeleted() {
    return !!this.page.deletedAt;
  }
  get isHidden() {
    return this.isDeleted && !this.showDeleted;
  }
  get showDeleted() {
    return this.pagesService.showDeleted;
  }

  get hasChildren() {
    return this.showDeleted ? this.children?.length : this.children?.filter(page => {return !page.deletedAt}).length;
  }


  fetch() {
    let include = ['children','locale'];
    this.pagesService.show(this.page.id, {include: include.join(','), withTrashed: true}).subscribe(res => {
      this.page = res.data;
    });
  }

  add() {
    this.formsService.createModel('page', {extraProps: {parent: this.page.id}}, this.listId);
    this.active = true;
    this.refresh()
  }

  edit() {
    this.formsService.editModel('page', this.page.id, null, this.listId);
  }

  delete() {
    this.dialog.confirm(`Удалить страницу ${this.page.name}?`).subscribe(
      resp=>{
        if (resp) this.pagesService.delete(this.page.id).subscribe(res => {
          this.listsService.refresh(this.parentListId);
          this.refresh();
        });
      }
    )
  }

  restore() {
    this.dialog.confirm(`Восстановить страницу ${this.page.name}?`).subscribe(
      resp=>{
        if (resp) this.pagesService.restore(this.page.id, {}).subscribe(res => {
          this.listsService.refresh(this.parentListId);
          this.refresh();
        });
      }
    )    
  }

  clone() {
    this.dialog.confirm(`Копировать страницу ${this.page.name}?`).subscribe(
      resp=>{        
        if (resp) {
          this.dialog.waiting('Выполняется копирование данных. Подождите, пожалуйста')
          this.pagesService.clone(this.page.id, {recursive: true}).subscribe(res => {
            this.dialog.waiting(null);
            this.listsService.refresh(this.parentListId);
            this.refresh();
          });
        }
      }
    )
  }

  private refresh(){
    this.pagesService.root({include:'children'}).subscribe(
      res => { this.pagesService.rootPages = res }
    )
  }

  toggle() {
    this.active = !this.active;
  }
}
