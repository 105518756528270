<div class="menu" *ngIf="editMode">
  <div class="block">
    <a (click)="edit()">
      <ico ico="edit_24"></ico>
    </a>
    <a (click)="delete()">
      <ico ico="delete_24"></ico>
    </a>
  </div>
</div>
<div [class.not-published]="!isPublished" class="publication-content">
  <div class="date">{{publication.publishedAt | date : 'dd.MM.yy'}}</div>
  <div class="name">
    <a [routerLink]="publication.link">
      <h3>{{publication.name}}</h3>
    </a>
  </div>
  <gellery [images]="posters" *ngIf="posters"></gellery>
  
  <div class="excerpt">{{publication.excerpt}}</div>
  <a class="publication-read-more" [routerLink]="publication.link">
    Читать далее 
    <ico ico="chevron_right_24"></ico>
  </a>
</div>
