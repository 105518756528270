import {Component, Input} from '@angular/core';

@Component({
  selector: 'field-relation-readonly',
  templateUrl: 'field-relation-readonly.component.html',
  styleUrls: ['field-relation-readonly.component.scss']
})
export class FieldRelationReadonlyComponent {
  @Input() field!: any;

  constructor() {
  }

  ngOnInit() {
  }

  get value() {
    return this.field.value?.data || [];
  }
}
