import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AssetsService {
  constructor(private http: HttpClient) {
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/assets/${id}`, data);
  }

  upload(file: any, params?: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', file);
    if (params) params.forEach(el => {
        formData.append(el.name, el.value);
      });
    return this.http.post<any>(`${environment.apiUrl}/api/assets`, formData, {reportProgress: true, observe: 'events'});
  }


}
