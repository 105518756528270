<div class="member">
  <div class="add" *ngIf="editMode">
    <div class="title">
      <span [innerHTML]="title"></span>
    </div>
    <div class="toggle" (click)="add()"></div>
  </div>
  <div class="list">
    <div class="item" *ngFor="let member of members">
      <page-menu [editMode]="editMode" [section]="member" [modelId]="modelId" [modelType]="modelType" dir="ltr"></page-menu>
      <ng-template ngFor let-group [ngForOf]="member.groups.data">
        <div class="image">
          <a [href]="getLink(group)" target="_blank" *ngIf="getLink(group)">
            <img [src]="getImg(group)" />
          </a>
          <img [src]="getImg(group)"  *ngIf="!getLink(group)"/>
        </div>
      </ng-template>
    </div>
  </div>
</div>
