<slider>
  <div header>
    <h2>{{title}}</h2>
    <div class="stepper" *ngIf="frames.length > 1">
      <div class="items">
        <div class="item" *ngFor="let frame of frames; let i = index" [class.active]="frame.active">
          <div class="num">{{i + 1}}</div>
          <div class="name">{{frame.title}}</div>
        </div>
      </div>
    </div>
  </div>
  <form-frame body [formGroup]="formGroup" [form]="activeFrame" [readonly]="readonly" [params]="formParams"></form-frame>
  <div footer>
    <div class="left">
      <div *ngIf="noticeVisible" class="notice">* Поля, обязательные для заполнения</div>
    </div>
    <div class="right">
      <button type="button" class="btn outline" (click)="back()">{{isFirstFrame ? 'Отмена' : 'Назад'}}</button>
      <button type="button" class="btn" *ngIf="!btnSaveHidden" (click)="proceed()">{{isLastFrame ? btnSaveLabel : 'Продолжить'}}</button>
    </div> 
  </div>
</slider>
