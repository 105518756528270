
<div class="poster" *ngIf="currentImage" (click)="fullScreen=true">
    <img  [src]="currentImage.links?.full" />
  </div>
  <div class="posters" *ngIf="images.length>1">
    <div class="item" *ngFor="let image of images; let i=index">
      <img [src]="image.links?.thumb" (click)="currentImage=image; id=i"/>
    </div>
  </div>
  <div class="gellery-fullscreen" *ngIf="fullScreen" >
    <swiper-gallery [items]="images" [closing]="true" [start]="id" (event)="fullScreen=false"></swiper-gallery>
  </div>
