<div class="items">
  <div class="item">
    <img src="/assets/images/partners/minstroy_logo.png"/>
    <p>Минстрой России</p>
  </div>
  <div class="item">
    <img src="/assets/images/partners/rosstandart_logo.png"/>
    <p>Росстандарт</p>
  </div>
  <div class="item">
    <img src="/assets/images/partners/glavgosekspertiza_logo.png"/>
    <p>Главгосэкспертиза</p>
  </div>
  <div class="item">
    <img src="/assets/images/partners/iso_logo.png"/>
    <p>ISO</p>
  </div>
  <div class="item">
    <img src="/assets/images/partners/eaes_logo.png"/>
    <p>ЕАЭС</p>
  </div>
</div>
