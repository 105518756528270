import { Component, Input, Output, EventEmitter } from '@angular/core';
import {WindowScrollingService} from "@app/_services/window-scrolling.service";

@Component({
  selector: 'cms-modal',
  templateUrl: './cms-modal.component.html',
  styleUrls: ['./cms-modal.component.scss']
})
export class CmsModalComponent {
  @Output() close = new EventEmitter<string>();
  @Input() blocked:boolean;

  constructor( private windowScrollingService: WindowScrollingService ){}

  ngOnInit(){
    this.windowScrollingService.disable();
  }
  ngOnDestroy(){
    this.windowScrollingService.enable();
  }

  cls(){
    this.close.emit('close')
  }
}
