<div class="setting-site" *ngIf="isShow">
  <div class="block">
    <div class="font">
      <div class="title">Размер шрифта</div>
      <div class="controls">
        <div class="icon minus" (click)="setParam('font', false)"></div>
        <div (click)="setParam('font', null)">Т</div>
        <div class="icon plus" (click)="setParam('font', true)"></div>
      </div>
    </div>
    <div class="color">
      <div class="title">Цвет сайта</div>
      <div class="controls">
        <ng-template ngFor let-item [ngForOf]="classes">
          <div class="{{item.class}}" [class.active]="params.class===item.class" (click)="setClass(item.class)">Ц</div>
        </ng-template>
      </div>
    </div>
    <div class="images">
      <div class="title">Изображение</div>
      <div class="controls">
        <div class="icon image" [class.active]="params.showImages" (click)="setImages(true)"></div>
        <div class="icon ban" [class.active]="!params.showImages" (click)="setImages(false)"></div>
      </div>
    </div>
    <div class="bright">
      <div class="title">Яркость</div>
      <div class="controls">
        <div class="icon minus" (click)="setParam('bright', false)"></div>
        <div class="icon sun" (click)="setParam('bright', null)"></div>
        <div class="icon plus" (click)="setParam('bright', true)"></div>
      </div>
    </div>
    <div class="contrast">
      <div class="title">Контрастность</div>
      <div class="controls">
        <div class="icon minus" (click)="setParam('contrast', false)"></div>
        <div class="icon circle-half-stroke" (click)="setParam('contrast', null)"></div>
        <div class="icon plus" (click)="setParam('contrast', true)"></div>
      </div>
    </div>
    <div class="default" (click)="setDefaultParams()"></div>
  </div>
  <div class="exit" (click)="close()"></div>
</div>
