
<div class="video-box">
        <img class="poster" [src]="poster" alt="" *ngIf="!start"  (click)="start=true">
        <img class="icon" src="assets\images\ico\video_play_60.svg" *ngIf="!start"  (click)="start=true">
        <iframe *ngIf="start"
                [src]="url"
                frameborder="0"
                [autoplay]="source == 'youtube.com'"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
        </iframe>
</div>