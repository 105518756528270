<table class="default">
  <tr>
    <td class="caption">Состояние лицензии</td>
    <td class="value" [ngClass]="classState">{{licence?.state?.title || 'Не найдено'}}</td>
  </tr>
  <tr>
    <td class="caption">Номер лицензии</td>
    <td class="value">{{number || '-'}}</td>
  </tr>
  <tr>
    <td class="caption">Продукт</td>
    <td class="value">{{product?.title || '-'}}</td>
  </tr>
  <tr>
    <td class="caption">Доменное имя</td>
    <td class="value">{{licence?.domain || '-'}}</td>
  </tr>
  <tr>
    <td class="caption">Дата начала действия</td>
    <td class="value">{{licence?.activeSince | date: 'dd.MM.yyyy'}}</td>
  </tr>
  <tr>
    <td class="caption">Дата окончания действия</td>
    <td class="value">{{licence?.activeTill?(licence?.activeTill | date: 'dd.MM.yyyy'):'Бессрочная'}}</td>
  </tr>
</table>

<h3>Включённые опции</h3>
<div *ngIf="options?.length">
  <p *ngFor="let option of options">{{option.title}}</p>
</div>
<p *ngIf="!options?.length">отсутствуют</p>
