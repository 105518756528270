<div class="item" [class.hidden]="isHidden" [class.home]="!parent" (click)="touched = true">
  <div class="bar">
    <div class="left">
      <drop-down ico="chevron_right_24" [angle]="[0,90]" *ngIf="hasChildren"  (toggle)="active=$event"></drop-down>
    </div>
    <div class="mid" [class.deleted]="isDeleted">
      <div class="info">
        <div class="logo" *ngIf="parent">
          <ico ico="web_page_24" color="#FFF"></ico>
        </div>
        <div class="logo" *ngIf="!parent">
          <ico ico="home_24" color="#FFF"></ico>
        </div>
        <div class="name">
          <a [routerLink]="page.link" target="_blank">{{page.name}}</a>
          <a [routerLink]="page.link" target="_blank" *ngIf="page.h1">{{page.h1}}</a>
        </div>
      </div>
    </div>
    <div class="right">
      <div *ngIf="parent && !isDeleted" title="Копировать" (click)="clone()">
        <ico ico="copy_24" class="page-control"></ico>
      </div>
      <div  *ngIf="!isDeleted" title="Добавить страницу" (click)="add()">
        <ico ico="webpage_plus_24" class="page-control"></ico>
      </div>
      <div *ngIf="!isDeleted" title="Редактировать" (click)="edit()">
        <ico ico="edit_24" class="page-control"></ico>
      </div>
      <div *ngIf="isDeleted" title="Восстановить" (click)="restore()">
        <ico ico="visibility_on_24" class="page-control"></ico>
      </div>
      <div *ngIf="isDeleted" title="Удалить безвозвратно" (click)="delete()">
        <ico ico="delete_24" class="page-control"></ico>
      </div>
      <div *ngIf="!isDeleted" title="Удалить" (click)="delete()">
        <ico ico="visibility_off_24" class="page-control"></ico>
      </div>
    </div>
  </div>
  <div class="items" *ngIf="active">
    <pages-tree  [parent]="page"></pages-tree>
  </div>
</div>
