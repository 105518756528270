import {Component, Input} from '@angular/core';

@Component({
  selector: 'pages-list-item',
  templateUrl: 'pages-list-item.component.html',
  styleUrls: ['pages-list-item.component.scss']
})
export class PagesListItemComponent {
  @Input() page: any;

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
