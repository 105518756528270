import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardsSectionComponent} from "@app/_modules/pages/sections/types/basic/cards/cards-section.component";
import {CardsSectionItemsComponent} from "@app/_modules/pages/sections/types/basic/cards/items/cards-section-items.component";
import {CardsSectionItemComponent} from "@app/_modules/pages/sections/types/basic/cards/items/item/cards-section-item.component";
import {CardsItemPersonsComponent} from "@app/_modules/pages/sections/types/basic/cards/items/item/types/persons/cards-item-persons.component";
import {CardsItemPartnersComponent} from "@app/_modules/pages/sections/types/basic/cards/items/item/types/partners/cards-item-partners.component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";
import {SwiperModule} from "swiper/angular";
import { WidjetModule } from '@app/_modules/widjet/widjet.module';
import { CardsItemDefaultComponent } from './items/item/types/default/cards-item-default.component';
import { CardsItemSlidesComponent } from './items/item/types/slides/cards-item-slides.component';

@NgModule({
  imports: [
    CommonModule,
    SortablejsModule,
    WidjetModule,
    SwiperModule
  ],
  declarations: [
    CardsSectionComponent,
    CardsSectionItemsComponent,
    CardsSectionItemComponent,
    CardsItemPersonsComponent,
    CardsItemPartnersComponent,
    CardsItemDefaultComponent,
    CardsItemSlidesComponent
  ],
  exports: [
    CardsSectionComponent
  ]
})
export class CardsSectionModule {
}
