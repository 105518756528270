<div class="top-panel pt-40">
  <h1>Журнал заявок</h1>
  <div class="buttons" *ngIf="!authService.isMainCompanyMember">
    <button type="button" class="btn create" (click)="create()">Сформировать заявку</button>
  </div>
  <div class="filters fullwidth" [formGroup]="filters">
    <div class="limiter">
      <div class="search">
        <label>Поиск</label>
        <input type="text" formControlName="search" />
      </div>
      <div class="advanced">
        <button type="button" (click)="showFilters()">Фильтр</button>
      </div>
    </div>
  </div>
</div>
<applications-list></applications-list>
<!--phonebook-list [companyId]="'main'"></phonebook-list-->
