import {Component, Input} from '@angular/core';

@Component({
  selector: 'document-section',
  templateUrl: 'document-section.component.html',
  styleUrls: ['document-section.component.scss']
})
export class DocumentSectionComponent {
  @Input() section: any;

  constructor(
  ) {
  }


  get title(){
    return this.section.groups.data[0].fields.data[1].title;
  }

  get name(){
    return this.section.groups.data[0].fields.data[1].value.data[0];
  }

  get description(){
    return this.section.groups.data[0].fields.data[2].value.data[0];
  }

  get docIcon(){
    const ext = ICON_TYPE[
      this.section.groups.data[0].fields.data[0].value.data[0].extension
    ]
    return `url('/assets/images/icons/document/${ext}.svg')`

  }

  get download(){
    return this.section.groups.data[0].fields.data[0].value.data[0].links.download;
  }
}

const ICON_TYPE:any={
  rtf:'DOC',
  docx:'DOC',
  doc:'DOC',
  xl:'XLS',
  xls:'XLS',
  xlsx:'XLS',
  ppt:'PPT',
  pptx:'PPT',
  jpg:'JPG',
  jpeg:'JPG',
  pdf:'PDF'
}

