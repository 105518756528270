import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-html-single',
  templateUrl: 'field-html-single.component.html',
  styleUrls: ['field-html-single.component.scss', '../form-field-html.component.scss']
})
export class FieldHtmlSingleComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  public config: any = {
    base_url: '/tinymce',
    suffix: '.min',
    language: 'ru',
    language_url: '/assets/lang/ru.js',
    menubar: false,
    plugins: 'lists link image paste help wordcount hr',
    toolbar: 'bold italic removeformat | link hr forecolor | bullist numlist outdent indent',
    color_map: [
      '0070BA', 'blue-1',
      '005799', 'blue-2',
      '004077', 'blue-3',
      'A1CAE5', 'blue-1',
      'C0C0C0', 'grey-1',
      '6C6C6C', 'grey-2',
      '4D4D4D', 'grey-3',
      'FFFFFF', 'white',
      '2D2D2D', 'black',
    ]
  };



  constructor() {
  }

  ngOnInit() {
    if (this.value) this.field.value = {data: [this.value]};
    setTimeout(() => {
      this.value = this.value || this.initialValue
    });
  }



  get initialValue() {
    return this.field.value?.data[0] || null;
  }

  get control() {
    return this.formGroup.controls[this.field.name];
  }

  get value() {
    return this.control?.value;
  }

  set value(value) {
    this.control?.setValue(value);
  }
}
