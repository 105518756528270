<div class="swiper-gallery">
  <div class="gallery-top">
    <swiper #swiper [config]="config" (swiper)="onSwiperInit()" (slideChange)="onSlideChange($event)">
      <ng-template *ngFor="let item of items; let i = index" swiperSlide>
        <img [src]="item?.links?.full" />
      </ng-template>
    </swiper>
  </div>
  <div class="gallery-thumbs" *ngIf="!isSingle">
    <swiper #thumb [config]="thumbConfig" (swiper)="onSwiperInit()" (slideChange)="onSlideChange($event)">
        <ng-template *ngFor="let item of items; let i = index" swiperSlide>
          <img [src]="item?.links?.thumb" />
        </ng-template>
    </swiper>
  </div>
  <button class="close" *ngIf="closing" (click)="close()">
    <ico></ico>
  </button>
</div>
