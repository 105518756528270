import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";


@Component({
  selector: 'filter-field-relation',
  templateUrl: 'filter-field-relation.component.html',
  styleUrls: ['filter-field-relation.component.scss']
})
export class FilterFieldRelationComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    //this.formGroup.addControl(this.field.name, new FormControl(''), {emitEvent: false});
  }

}
