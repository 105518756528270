import {Component} from '@angular/core';

@Component({
  selector: 'left-content',
  templateUrl: './left-content.component.html',
  styleUrls: ['./left-content.component.scss']
})
export class LeftContentComponent {

}



