import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'contact-section',
  templateUrl: 'contact-section.component.html',
  styleUrls: ['contact-section.component.scss']
})
export class ContactSectionComponent {
  @Input() section: any;

  constructor(
    private objectsService: ObjectsService
  ) {
  }

  ngOnInit() {
  }


  get name() {
    return this.objectsService.getValue(this.section, 'contact-name');
  }
  get legalAddress() {
    return this.objectsService.getValue(this.section, 'contact-legal-address');
  }
  get locationAddress() {
    return this.objectsService.getValue(this.section, 'contact-location-address');
  }
  get email() {
    return this.objectsService.getValue(this.section, 'contact-email');
  }
  get phone() {
    return this.objectsService.getValue(this.section, 'contact-phone');
  }
  get description() {
    return this.objectsService.getValue(this.section, 'contact-description');
  }
}
