<form [formGroup]="form" (submit)="onSubmit()">
  <div class="body">
    <div class="field" [class.invalid]="email.invalid && email.touched">
      <label for="email">Электронная почта*</label>
      <input id="email" formControlName="email" type="email" />
      <!--p *ngIf="email.errors?.required">Поле обязательно для заполнения</p-->
      <p *ngIf="email.errors?.email">Адрес почты указан не корректно</p>
      <p *ngFor="let err of asyncErrors.email">{{err}}</p>
    </div>
    <div class="field" [class.invalid]="name.invalid && name.touched">
      <label for="name">Фамилия, имя, отчество*</label>
      <input id="name" formControlName="name" type="text" />
      <!--p *ngIf="name.errors?.required">Поле обязательно для заполнения</p-->
    </div>
    <div class="field">
      <label for="phone">Телефон</label>
      <input id="phone" formControlName="phone" type="text" />
    </div>
    <div class="field" [class.invalid]="password.invalid && password.touched">
      <label for="password">Пароль</label>
      <input id="password" formControlName="password" type="password" />
      <!--p *ngIf="password.errors?.required">Поле обязательно для заполнения</p-->
      <p *ngFor="let err of asyncErrors.password">{{err}}</p>
    </div>
    <div class="field" [class.invalid]="confirmation.invalid && confirmation.touched">
      <label for="confirmation">Подтверждение пароля*</label>
      <input id="confirmation" formControlName="passwordConfirmation" type="password" />
      <!--p *ngIf="confirmation.errors?.required">Поле обязательно для заполнения</p-->
    </div>
    <!--div class="field" [class.invalid]="agree.invalid || read.invalid">
      <div class="checkbox">
        <input type="checkbox" id="agree" formControlName="agree" />
        <label for="agree">Согласен на обработку <a href="/src/assets/documents/privacy-policy.pdf" target="_blank">персональных данных</a></label>
      </div>
      <p *ngIf="agree.errors?.required && agree.touched">Необходимо поставить галочку</p>
      <div class="checkbox">
        <input type="checkbox" id="read" formControlName="read" />
        <label for="read">С Политикой обработки <a href="/src/assets/documents/privacy-policy.pdf" target="_blank">персональных данных</a> ознакомлен</label>
      </div>
      <p *ngIf="read.errors?.required && read.touched">Необходимо поставить галочку</p>
    </div-->
    <div *ngIf="error" class="error">{{error}}</div>
  </div>
  <div class="bottom">
    <button type="button" class="btn secondary" (click)="login()">Авторизоваться</button>
    <button type="submit" [disabled]="loading" class="btn">Зарегистрироваться</button>
  </div>
</form>

