import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class FormFieldsService {
  prepareGroup(group: any, params: any) {
    let hiddenGroups = params.hidden?.groups || [];
    let readonlyGroups = params.readonly?.groups || [];
    if (params.hideGroupsTitles) group.title = null;
    if (hiddenGroups.indexOf(group.name) !== -1) group.hidden = true;
    if (readonlyGroups.indexOf(group.name) !== -1) group.readonly = true;
    group.fields.data = group.fields?.data.map(field => {
      return this.prepareField(field, params);
    });
    return group;
  }

  prepareField(field: any, params: any) {
    let hiddenFields = params.hidden?.fields || [];
    let readonlyFields = params.readonly?.fields || [];
    let fieldTypes = params.fieldTypes || [];
    if (fieldTypes.length && (fieldTypes.indexOf(field.type) === -1)) field.hidden = true;
    if (hiddenFields.indexOf(field.name) !== -1) field.hidden = true;
    if (readonlyFields.indexOf(field.name) !== -1) field.readonly = true;
    return field;
  }

  prepareFieldValue(field: any) {
    let result = field.value?.data || field.value || [];
    if (result.data !== undefined) result = result.data;
    if (!Array.isArray(result)) result = ((result !== null) && (result !== undefined)) ? [result] : [];
    return result;
  }


}
