import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'filter-field-string',
  templateUrl: 'filter-field-string.component.html',
  styleUrls: ['filter-field-string.component.scss']
})
export class FilterFieldStringComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.control.setValue(this.initialValue, {emitEvent: false});
  }


  get initialValue() {
    return this.field.value?.data[0] || '';
  }

  get control() {
    return this.formGroup.controls[this.field.name];
  }

}
