import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

import {FilterFieldRelationComponent} from "@app/_modules/filter-fields/types/relation/filter-field-relation.component";
import {RelationCheckboxComponent} from "@app/_modules/filter-fields/types/relation/checkbox/relation-checkbox.component";
import {RelationComboboxComponent} from "@app/_modules/filter-fields/types/relation/combobox/relation-combobox.component";
import {RelationRadioComponent} from "@app/_modules/filter-fields/types/relation/radio/relation-radio.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
    declarations: [
        FilterFieldRelationComponent,
        RelationCheckboxComponent,
        RelationComboboxComponent,
        RelationRadioComponent
    ],
    exports: [
        FilterFieldRelationComponent,
        RelationCheckboxComponent
    ]
})
export class FilterFieldRelationModule {}
