<div class="field" [class.readonly]="readonly" *ngIf="!field.hidden && field.type!=='topics'">
  <div *ngIf="field?.title" class="caption">
    <label [for]="field.name">{{field.title}}<span *ngIf="field.required && !field.readonly">*</span></label>
  </div>
  <div class="value" [class.invalid]="isInvalid">
    <div class="control" [ngSwitch]="field.type">
      <form-field-boolean *ngSwitchCase="'boolean'" [field]="field" [formGroup]="formGroup"></form-field-boolean>
      <form-field-date *ngSwitchCase="'date'" [field]="field" [formGroup]="formGroup"></form-field-date>
      <form-field-datetime *ngSwitchCase="'datetime'" [field]="field" [formGroup]="formGroup"></form-field-datetime>
      <form-field-time *ngSwitchCase="'time'" [field]="field" [formGroup]="formGroup"></form-field-time>
      <form-field-float *ngSwitchCase="'float'" [field]="field" [formGroup]="formGroup"></form-field-float>
      <form-field-integer *ngSwitchCase="'integer'" [field]="field" [formGroup]="formGroup"></form-field-integer>
      <form-field-relation *ngSwitchCase="'relation'" [field]="field" [formGroup]="formGroup"></form-field-relation>
      <!--form-field-text *ngSwitchCase="'string'" [field]="field" [formGroup]="formGroup" [maxLength]="500"></form-field-text-->
      <form-field-string *ngSwitchCase="'string'" [field]="field" [formGroup]="formGroup"></form-field-string>
      <form-field-text *ngSwitchCase="'text'" [field]="field" [formGroup]="formGroup"></form-field-text>
      <form-field-html *ngSwitchCase="'html'" [field]="field" [formGroup]="formGroup"></form-field-html>
      <form-field-document *ngSwitchCase="'document'" [field]="field" [formGroup]="formGroup"></form-field-document>
      <form-field-image *ngSwitchCase="'image'" [field]="field" [formGroup]="formGroup"></form-field-image>
      <form-field-hidden *ngSwitchCase="'hidden'" [field]="field" [formGroup]="formGroup"></form-field-hidden>
      <p *ngSwitchDefault>{{field.type}} field control is undefined</p>
    </div>
    <div class="error" *ngIf="isInvalid">Это поле обязательно для заполнения</div>
  </div>
</div>
<form-field-topics *ngIf="field.type==='topics'" [field]="field" [formGroup]="formGroup"></form-field-topics>
