import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import {JwtInterceptor, ErrorInterceptor} from './_helpers';
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {LayoutModule} from "@app/_modules/layout/layout.module";
import {ObjectsModule} from "@app/_modules/objects/objects.module";
import {AuthModule} from "@app/_modules/auth/auth.module";
import {SortablejsModule} from '@dustfoundation/ngx-sortablejs';
import {PublicationsModule} from "@app/_modules/publications/publications.module";
import {AdministrationModule} from "@app/_modules/administration/administration.module";
import {PhonebookModule} from "@app/_modules/phonebook/phonebook.module";
import {ApplicationsModule} from "@app/_modules/applications/applications.module";
import {UsersModule} from "@app/_modules/users/users.module";
import {SearchModule} from "@app/_modules/search/search.module";
import {PagesRoutingModule} from "@app/_modules/pages/pages-routing.module";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    SortablejsModule.forRoot({animation: 150}),
    ObjectsModule,
    AuthModule,
    UsersModule,
    SearchModule,
    AdministrationModule,
    PhonebookModule,
    ApplicationsModule,
    LayoutModule,
    PublicationsModule,
    PagesRoutingModule // should be last
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
