import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private http: HttpClient) {
  }

  list(params?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/users`, {params: params});
  }

  show(id: string, params?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/users/${id}`, {params: params});
  }

  me(params?: any): Observable<any> {
    if (!params) params = {include: 'avatar,membership.company,roles,privileges'};
    return this.http.get(`${environment.apiUrl}/api/me`, {params: params});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/users/${id}`);
  }
}
