import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageSectionsModule} from "@app/_modules/pages/sections/page-sections.module";
import {PageSidebarsModule} from "@app/_modules/pages/sidebars/page-sidebars.module";
import {RegistriesModule} from "@app/_modules/registries/registries.module";
import {PublicationsModule} from "@app/_modules/publications/publications.module";
import {PagesListComponent} from "@app/_modules/pages/list/pages-list.component";
import {PagesListItemComponent} from "@app/_modules/pages/list/item/pages-list-item.component";
import {PagesMenuComponent} from "@app/_modules/pages/menu/pages-menu.component";
import {PagesMenuItemComponent} from "@app/_modules/pages/menu/item/pages-menu-item.component";
import {RouterModule} from "@angular/router";
import {PaginationModule} from "@app/_modules/pagination/pagination.module";
import {SearchSectionsComponent} from "@app/_modules/pages/sections/search/search-sections.component";
import {SearchSectionsItemComponent} from "@app/_modules/pages/sections/search/item/search-sections-item.component";
import {SwiperModule} from "swiper/angular";
import {PagesTreeComponent} from "@app/_modules/pages/tree/pages-tree.component";
import {PagesTreeItemComponent} from "@app/_modules/pages/tree/item/pages-tree-item.component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";
import { WidjetModule } from '../widjet/widjet.module';
import { SliderMenuComponent } from './menu/slider-menu/slider-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PageSectionsModule,
    PageSidebarsModule,
    RegistriesModule,
    PublicationsModule,
    PaginationModule,
    SwiperModule,
    SortablejsModule,
    WidjetModule
  ],
  declarations: [
    PagesMenuComponent,
    PagesMenuItemComponent,
    PagesListComponent,
    PagesListItemComponent,
    PagesTreeComponent,
    PagesTreeItemComponent,
    SearchSectionsComponent,
    SearchSectionsItemComponent,
    SliderMenuComponent,
  ],
  exports: [
    PagesMenuComponent,
    PagesListComponent,
    SearchSectionsComponent,
    PagesTreeComponent,
    PagesTreeItemComponent,
  ]
})
export class PagesModule {}
