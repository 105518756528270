import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const oauthToken = this.authService.token;
      const isApiUrl = request.url.startsWith(environment.apiUrl);
      if (this.authService.isLoggedIn && isApiUrl) {
        request = request.clone({
          setHeaders: {Authorization: `${oauthToken.token_type} ${oauthToken.access_token}`}
        });
      }
      return next.handle(request);
    }
}
