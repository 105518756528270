import {Component, Input} from '@angular/core';
import {RegistryCategoriesService} from "@app/_services/registry-categories.service";
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import {SortableOptions} from "sortablejs";

@Component({
  selector: 'registry-categories',
  templateUrl: 'registry-categories.component.html',
  styleUrls: ['registry-categories.component.scss']
})
export class RegistryCategoriesComponent {
  @Input() registry: any;
  @Input() parent: any;
  @Input() editMode: boolean;
  public categories = <any>[];

  public options: SortableOptions = {
    group: 'registry-categories',
    handle: '.move',
    onUpdate: (event: any) => {
      this.move(event);
    },
    onAdd: (event: any) => {
      this.move(event);
    }
  };

  subscription: Subscription;

  constructor(private categoriesService: RegistryCategoriesService, private listsService: ListsService) {
  }

  get listId() {
    return (this.parent?.id || this.registry?.id) + '-categories';
  }

  ngOnInit() {
    this.subscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  fetch() {
    this.categoriesService.list({registry: this.registry.id, parent: this.parent?.id}).subscribe(res => {
      this.categories = res.data;
    });
  }


  move(event: any) {
    this.categoriesService.move(event.item.id, {registry: this.registry.id, category: this.parent?.id, ord: event.newIndex}).subscribe(res => {
      //this.listsService.refresh((this.parent?.id || this.registry?.id) + '-categories');
    });
  }

}
