<div class="cms-modal-overlay" (click)="cls()">

</div>
<div class="cms-modal-bar">
    <div class="cms-modal-header" *ngIf="!blocked">
        <ng-content select="[header]"></ng-content>
        <ico (click)="cls()"></ico>
    </div>
    <div class="cms-modal-body">
        <ng-content></ng-content>
    </div>
    <div class="cms-modal-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>