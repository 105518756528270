import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'button-section',
  templateUrl: 'button-section.component.html',
  styleUrls: ['button-section.component.scss']
})
export class ButtonSectionComponent {
  @Input() section: any;

  constructor(
    private objectsService: ObjectsService
  ) {
  }


  get url() {
    return this.objectsService.getValue(this.section, 'button-url');
  }
  get title() {
    return this.objectsService.getValue(this.section, 'button-title');
  }
  get targetSelf() {
    return this.objectsService.getValue(this.section, 'target-self');
  }

  ngOnInit() {
  }
}

