<div class="member">
  <div class="left">
    <div class="avatar">
      <img *ngIf="avatar" [src]="avatar" alt="" />
      <div class="initials" *ngIf="!avatar">{{user?.initials}}</div>
    </div>
  </div>
  <div class="mid">
    <div class="name">{{user?.name}}. {{member.position}}</div>
    <div class="sub">{{role?.title}}</div>
  </div>
  <div class="right" *ngIf="editable">
    <button type="button" title="Редактировать" class="btn icon edit" (click)="edit()"></button>
    <button type="button" title="Удалить" class="btn icon delete" (click)="delete()"></button>
  </div>
</div>
