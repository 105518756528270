<div *ngIf="items?.length">

  <div *ngIf="imageType!=='carousel'&&!(imageType=='large-preview'&&items.length==1)"
  class="items" [ngClass]="imageType">
    <div class="item" *ngFor="let item of items; let i = index">
      <img [src]="item.links?.full" (click)="show(i)"/>
    </div>
  </div>


  <div *ngIf="imageType==='carousel'" id="section-{{section.id}}" class="carousel">
    <div class="swiper-box">
      <swiper-gallery [items]="items"></swiper-gallery>
    </div>
  </div>

</div>
