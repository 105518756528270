<div class="member">

  <page-menu [editMode]="editMode" [section]="member" [modelId]="modelId" [modelType]="'modelType'" dir="ltr"></page-menu>

  <img [src]="image" class="image" [class.pointer]="imageFull"   (click)="showImage=imageFull&&true"/>

  <div class="name" [innerHTML]="name"></div>

  <div class="position" [innerHTML]="position" *ngIf="position"></div>

  <a class="link tel" [innerHTML]="phone" [href]="'tel:'+phone" *ngIf="phone"></a>

  <a class="link mail" [innerHTML]="email" [href]="'mailto:'+email" *ngIf="email"></a>

  <div class="doc" *ngIf="docLink">
    <div class="doc-icon" [style.backgroundImage]="docIcon">
    </div>
    <div class="doc-link">
        <a [textContent]="docName" class="title" [href]="docLink" target="_blank"></a>
    </div>      
  </div>

  <div class="more">
    <a  *ngIf="description" (click)="showDescription=true">
      Подробнее <img src="/assets/images/icons/chevron_right_24dp.svg" alt="">
    </a>
    <div *ngIf="showDescription"  class="modal" (click)="showDescription=false">
      <div  [innerHTML]="description"></div>
    </div>
  </div>

  <img [src]="image" class="full-screen" [hidden]="!showImage"  (click)="showImage=false"/>

</div>

