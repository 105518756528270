import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {AuthGuard} from "@app/_helpers";
import {UserProfileComponent} from "@app/_modules/users/profile/user-profile.component";
import {UsersListComponent} from "@app/_modules/users/list/users-list.component";
import {UsersListItemComponent} from "@app/_modules/users/list/item/users-list-item.component";
import { WidjetModule } from '../widjet/widjet.module';

const routes = [
  {path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    CommonModule,
    WidjetModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    UserProfileComponent,
    UsersListComponent,
    UsersListItemComponent
  ],
  exports: [
    RouterModule,
    UsersListComponent
  ]
})
export class UsersModule {}
