import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {PagesService} from "@app/_services/pages.service";
import { environment } from '@environments/environment';
import {Subscription} from "rxjs";

@Component({
  selector: 'locale',
  templateUrl: './locale.component.html',
  styleUrls: ['./locale.component.scss']
})
export class LocaleComponent {

  public locales = <any>[];
  public ddMenu:boolean;
  private rootSubscription: Subscription

  constructor(
    public pagesService: PagesService,
    private router: Router
  ){}

  ngOnInit() {
    this.rootSubscription = this.pagesService.rootPagesSubject.subscribe(
      res=>{
        this.locales = res?.data
      }
    )
  }

  ngOnDestroy() {
    this.rootSubscription.unsubscribe()
  }
  
  get rootPage(){
    return this.pagesService.rootPage
  }

  get rootPages() {
    return this.pagesService.rootPages;
  }

  flag(localeName:string){
    if (!localeName) localeName = environment.defaultLocale;
    return 'assets/images/locale/'+(localeName||'ru')+'.svg'
  }

  close() {
    setTimeout(() => {
      this.ddMenu = false;
    }, 10);    
  }

  select(locale){
    let link = locale.link
    this.router.navigate([{outlets: {slider: null}}]).then(() => {
      this.router.navigate([link])});
  }

}
