import {Component, OnInit, Input, HostListener, ElementRef, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UtilityService} from "@app/_services/utility.service";

@Component({
  selector: 'relation-combobox',
  templateUrl: 'relation-combobox.component.html',
  styleUrls: ['relation-combobox.component.scss', '../filter-field-relation.component.scss']
})
export class RelationComboboxComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;
  @ViewChild("searchInput") searchInput?: ElementRef;

  public active = false;
  public options = <any>[];
  public selected = <any>[];
  public search = '';

  constructor(private eRef: ElementRef, private utilityService: UtilityService) {
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (!this.eRef.nativeElement.contains(target)) this.active = false;
  }

  ngOnInit(): void {
    this.initValue();
    this.updateOptions();
  }

  get initialValue() {
    return this.field.value?.data || [];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value: any[]) {
    this.selected = value;
    this.control.setValue(value.map(val => {return val.id}).filter(val => {return (val !== undefined) && (val !== null)}));
  }

  get caption() {
    return this.value?.length ? `Выбрано ${this.value?.length} значений` : 'Не выбрано';
  }


  initValue() {
    this.selected = this.initialValue;
    this.control.setValue(this.initialValue.map(val => {return val.id}).filter(val => {return (val !== undefined) && (val !== null)}), {emitEvent: false});
  }


  onInput(event) {
    this.search = event.target.value;
    this.updateOptions();
  }

  updateOptions() {
    this.options = this.utilityService.filterOptions(this.search, this.field.represented?.data || []);
  }

  toggleValue(option: any) {
    this.isSelected(option) ? this.removeValue(option) : this.addValue(option);
    this.active = false;
  }
  addValue(option: any) {
    let val = this.selected;
    val.push(option);
    this.value = val;
  }
  removeValue(option: any) {
    this.value = this.selected.filter(val => {return option.id !== val.id});
  }
  isSelected(option: any) {
    return this.value.indexOf(option.id) !== -1;
  }

  toggleActivity() {
    this.active = !this.active;
    if (this.active) setTimeout(() => {this.searchInput?.nativeElement.focus()}, 10);
  }
}
