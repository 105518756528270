import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {SortableOptions} from "sortablejs";

import SwiperCore, {Navigation, SwiperOptions} from 'swiper';
import { SwiperComponent } from "swiper/angular";
SwiperCore.use([Navigation]);

@Component({
  selector: 'cards-section-items',
  templateUrl: 'cards-section-items.component.html',
  styleUrls: ['cards-section-items.component.scss']
})
export class CardsSectionItemsComponent {
  @Input() section: any;
  @Input() type: any;
  @Input() editMode = false;

  public options: SortableOptions = {
    onUpdate: (event: any) => {
      this.move(event);
    }
  };

  public config: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 24,
    centeredSlides: true,
    pagination: {
      clickable: true,
    },
  };

  constructor(private objectsService: ObjectsService) {
  }

  get cards() {
    return this.section.objects?.data;
  }

  ngOnInit() {
    console.log(this.type)
    console.log(this.slides)
  }
  get slides(){
    return this.type.name == 'cards-section-slides'
  }

  move(event: any) {
    this.objectsService.move(event.item.id, {modelType: 'object', modelId: this.section.id, ord: event.newIndex}).subscribe(res => {
    });
  }


}

