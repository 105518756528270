<div class="date">{{page.publishedAt | date : 'dd.MM.yy'}}</div>
<div class="name">
    <h3>{{page.name}}</h3>
</div>

    <div class="intro" *ngIf="!page.isBlank">
      <gellery [images]="posters" *ngIf="posters"></gellery>
    </div>
    <div class="content" *ngIf="page.content" [innerHTML]="page.content"></div>
    <page-sections [page]="page" [editMode]="editMode" [modelType]="'publication'"></page-sections>

    <a (click)="back()" class="publication-page-back">
      <ico ico="chevron_left_24"></ico>
      Назад      
    </a>

