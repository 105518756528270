import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GalleryService {
  public imagesSubject = new BehaviorSubject<any>(null);
  public positionSubject = new BehaviorSubject<any>(null);

  constructor() {
  }

  get images(): any {
    return this.imagesSubject.value;
  }
  get position() {
    return this.positionSubject.value;
  }


  show(images: any, position: number) {
    this.imagesSubject.next(images);
    this.positionSubject.next(position);
  }

  close() {
    this.imagesSubject.next(null);
    this.positionSubject.next(null);
  }

}
