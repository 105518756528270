import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

import {FormFieldTopicsComponent} from "@app/_modules/form-fields/types/topics/form-field-topics.component";
import {
  FormFieldDocumentModule,
  FormFieldRelationModule,
  FormFieldStringModule,
  FormFieldTextModule
} from "@app/_modules/form-fields/types";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormFieldDocumentModule,
    FormFieldRelationModule,
    FormFieldStringModule,
    FormFieldTextModule,
  ],
  declarations: [
    FormFieldTopicsComponent,
  ],
  exports: [
    FormFieldTopicsComponent
  ]
})
export class FormFieldTopicsModule {}
