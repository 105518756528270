import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterFieldBooleanComponent} from "@app/_modules/filter-fields/types/boolean/filter-field-boolean.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FilterFieldBooleanComponent
  ],
  exports: [
    FilterFieldBooleanComponent
  ]
})
export class FilterFieldBooleanModule {}
