import {Component} from '@angular/core';

@Component({
  selector: 'administrate-committee',
  templateUrl: 'administrate-committee.component.html',
  styleUrls: ['administrate-committee.component.scss']
})
export class AdministrateCommitteeComponent {

  constructor() {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

}
