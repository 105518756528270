import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {FormFieldsService} from "@app/_services/form-fields.service";

@Component({
  selector: 'filter-field',
  templateUrl: 'filter-field.component.html',
  styleUrls: ['filter-field.component.scss']
})
export class FilterFieldComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor(private fieldsService: FormFieldsService) {
  }

  ngOnInit() {
    this.field.value = {data: this.fieldsService.prepareFieldValue(this.field)};
    this.formGroup.addControl(this.field.name, new FormControl(), {emitEvent: false});
  }
}
