import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";
import {Router} from "@angular/router";

@Component({
  selector: 'member-section-item',
  templateUrl: 'member-section-item.component.html',
  styleUrls: ['member-section-item.component.scss']
})
export class MemberSectionItemComponent {
  @Input() id: any;
  @Input() editMode: boolean;
  @Input() member:any;
  @Input() section: any;
  public cover = '/assets/images/icons/view_man_273_370dp.svg';

  public showImage:boolean;

  constructor(
    private formsService: FormsService,
    private router: Router,
    private objectsService: ObjectsService,
  ) {
  }

  public item: any;
  public fullScreen = false;
  public showDescription = false;

  ngOnInit() {
  
  }

  get modelId() {
    return this.section.id;
  }


  getFields(member){

    let fields:any = {}; 
    member.groups.data.forEach(group =>{
      group.fields.data.forEach(field =>{          
        if(field.name&&field?.value?.data[0]){
          
          fields[field.name] = field?.value?.data[0]
        }
      })
    })
    return fields
  }

  get image(){
    return this.getFields(this.member)['member-image']?.links?.full || this.cover
  }
  get imageFull(){
    return !!this.getFields(this.member)['member-image']?.links?.full
  }

  get name(){
    return this.getFields(this.member)['member-name-required']
  }

  get position(){
    return this.getFields(this.member)['member-position-required']
  }

  get phone(){
    return this.getFields(this.member)['member-phone']
  }

  get email(){
    return this.getFields(this.member)['member-email']
  }

  get docLink(){
    return this.getFields(this.member)['member-doc']?.links.download
  }
  get docIcon(){
    return this.iconUrl(this.getFields(this.member)['member-doc'].extension)
  }
  private iconUrl(name:string){
    if (name)return ICON_TYPE[name] && `url(/assets/images/icons/document/${ICON_TYPE[name]}.svg)`
  }
  get docName(){
    return this.getFields(this.member)['member-doc'].name
  }

  get description(){
    return this.getFields(this.member)['member-description']
  }

}

const ICON_TYPE:any={
  rtf:'DOC',
  docx:'DOC',
  doc:'DOC',
  xl:'XLS',
  xls:'XLS',
  xlsx:'XLS',
  ppt:'PPT',
  pptx:'PPT',
  jpg:'JPG',
  jpeg:'JPG',
  pdf:'PDF'
}
