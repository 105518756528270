import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthenticationService} from '@app/_services';

@Component({
  selector: 'auth-form-password-reset',
  templateUrl: 'auth-form-password-reset.component.html',
  styleUrls: ['auth-form-password-reset.component.scss']
})
export class AuthFormPasswordResetComponent implements OnInit {
  form: FormGroup;
  loading = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmation: ['', [Validators.required]]
    });
  }

  get f() {
    return this.form.controls;
  }
  get password() {
    return this.f?.password?.value;
  }
  get confirmation() {
    return this.f?.confirmation?.value;
  }
  get token() {
    return this.route.snapshot.paramMap.get('token');
  }
  get email() {
    return this.route.snapshot.paramMap.get('email');
  }

  onSubmit() {
    this.error = '';
    if (this.form.invalid) return;
    if (this.password !== this.confirmation) {
      this.setError('Пароль и подтверждение пароля не совпадают');
      return;
    }

    this.loading = true;
    this.authenticationService.restore({email: this.email, token: this.token, password: this.password}).subscribe(res => {
      this.router.navigate(['login']).then();
    }, error => {
      this.setError(error);
      this.loading = false;
    });
  }

  setError(error: string) {
    let trans = {'The user credentials were incorrect.': 'Имя пользователя или пароль указаны неверно.'};
    this.error = trans[error] || error;
  }

}
