import {NgModule} from '@angular/core';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {RegistryComponent} from "@app/_modules/registries/registry/registry.component";
import {RegistryCategoriesComponent} from "@app/_modules/registries/registry/categories/registry-categories.component";
import {RegistryEntriesComponent} from "@app/_modules/registries/registry/entries/registry-entries.component";
import {RegistryEntryComponent} from "@app/_modules/registries/registry/entries/entry/registry-entry.component";
import {EntryOperationsComponent} from "@app/_modules/registries/registry/operations/entry-operations.component";
import {RegistryCategoryComponent} from "@app/_modules/registries/registry/categories/category/registry-category.component";
import {EntryOperationComponent} from "@app/_modules/registries/registry/operations/operation/entry-operation.component";
import {RegistryFiltersComponent} from "@app/_modules/registries/registry/filters/registry-filters.component";
import {PaginationModule} from "@app/_modules/pagination/pagination.module";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";
import {ReactiveFormsModule} from "@angular/forms";
import {EntryPropertiesComponent} from "@app/_modules/registries/registry/entries/entry/properties/entry-properties.component";
import { WidjetModule } from '../widjet/widjet.module';

@NgModule({
    imports: [
      BrowserModule,
      RouterModule,
      ReactiveFormsModule,
      PaginationModule,
      SortablejsModule,
      WidjetModule
    ],
    declarations: [
        RegistryComponent,
        RegistryCategoriesComponent,
        RegistryEntriesComponent,
        RegistryEntryComponent,
        EntryOperationsComponent,
        RegistryCategoryComponent,
        EntryOperationComponent,
        RegistryFiltersComponent,
        EntryPropertiesComponent
    ],
    exports: [
        RegistryComponent,
        RegistryEntriesComponent
    ]
})
export class RegistriesModule {
}
