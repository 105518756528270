import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'html-section',
  templateUrl: 'html-section.component.html',
  styleUrls: ['html-section.component.scss']
})
export class HtmlSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService, private sanitized: DomSanitizer) {
  }

  get content() {
    let res = this.objectsService.getValue(this.section, 'html-required');
    res = res.replace(/<br><br>/g,"</p><p>").replace(/<br>/g,"</p><p>");
    return this.sanitized.bypassSecurityTrustHtml(res);
  }

  ngOnInit() {

  }
}
