import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {CompanyMembersComponent} from "@app/_modules/companies/members/company-members.component";
import {CompanyStructureComponent} from "@app/_modules/companies/structure/company-structure.component";
import {CompanyDepartmentComponent} from "@app/_modules/companies/structure/department/company-department.component";
import {CompanyMemberComponent} from "@app/_modules/companies/members/member/company-member.component";
import {CompanyAdvisoriesComponent} from "@app/_modules/companies/advisories/company-advisories.component";
import {CompanyComponent} from "@app/_modules/companies/company/company.component";


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CompanyComponent,
    CompanyMembersComponent,
    CompanyMemberComponent,
    CompanyStructureComponent,
    CompanyDepartmentComponent,
    CompanyAdvisoriesComponent
  ],
  exports: [
    CompanyComponent,
    CompanyMembersComponent,
    CompanyStructureComponent,
    CompanyAdvisoriesComponent
  ]
})
export class CompaniesModule {}
