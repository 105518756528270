import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FiltersService} from "@app/_services";

@Component({
  selector: 'registry-filters',
  templateUrl: 'registry-filters.component.html',
  styleUrls: ['registry-filters.component.scss']
})
export class RegistryFiltersComponent {
  @Input() registry: any;
  @Input() filters: FormGroup;
  @Input() hasCustomFilters: boolean;
  @Input() isFiltersApplied: boolean;
  @Input() listId: string;

  constructor(private filtersService: FiltersService) {
  }

  ngOnInit() {
  }

  showFilters() {
    this.filtersService.slider('model', 'registryEntries', {extraProps: {registry: this.registry.id}}, this.listId);
  }

}
