import { Component, EventEmitter, Input, Output, ViewChild, HostListener} from '@angular/core';
import SwiperCore, {Navigation, SwiperOptions} from 'swiper';
import { SwiperComponent } from "swiper/angular";
SwiperCore.use([Navigation]);

@Component({
  selector: 'swiper-gallery',
  templateUrl: './swiper-gallery.component.html',
  styleUrls: ['./swiper-gallery.component.scss']
})
export class SwiperGalleryComponent {
  @Input() items: any;
  @Input() start = 0;
  @Input() closing:boolean;

  @Output() event = new EventEmitter<string>()

  @ViewChild('swiper') swiper?: SwiperComponent;
  @ViewChild('thumb') thumb?: SwiperComponent;

  @HostListener('window:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') this.event.emit('close');
  }

  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: true
  };

  public thumbConfig: SwiperOptions = {
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true
  }


  ngOnInit() {
  }


  get isSingle(): boolean {
    return this.items?.length < 2;
  }


  onSwiperInit() {
    this.swiper.swiperRef.slideTo(this.start, 0);
    this.thumb.swiperRef.slideTo(this.start, 0);
  }

  onSlideChange(event) {
    this.thumb.swiperRef.slideTo(event[0].realIndex);
    this.swiper.swiperRef.slideTo(event[0].realIndex);
  }


  close(){
    this.event.emit('close')
  }
}
