<div *ngIf="editMode" class="items {{type?.name}}" [sortablejs]="cards" [sortablejsOptions]="options">
  <cards-section-item [id]="card.id" [card]="card" [type]="type" [editMode]="editMode" *ngFor="let card of cards"></cards-section-item>
</div>
<div *ngIf="!editMode && !slides" class="items {{type?.name}}">
  <cards-section-item [card]="card" [type]="type" [editMode]="editMode" *ngFor="let card of cards"></cards-section-item>
</div>
<div *ngIf="!editMode && slides" class="slides {{type?.name}}">
  <swiper [config]="config">
    <ng-template *ngFor="let card of cards" swiperSlide>
      <cards-section-item [card]="card" [type]="type" [editMode]="editMode" ></cards-section-item>
    </ng-template>
  </swiper>  
</div>
