import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-integer-multiple',
  templateUrl: 'field-integer-multiple.component.html',
  styleUrls: ['field-integer-multiple.component.scss']
})
export class FieldIntegerMultipleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {this.value = this.initialValue});
  }


  get initialValue() {
    return this.field.value?.data || [];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value) {
    this.control?.setValue(value);
  }


  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      this.addValue(event.target.value);
      event.target.value = '';
    }
  }

  addValue(val: number) {
    let value = this.value;
    if (value.indexOf(val) === -1) value.push(val);
    this.value = value;
  }

  removeValue(val: number) {
    this.value = this.value.filter(item => {
      return item !== val;
    });
  }



}
