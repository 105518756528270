import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterFieldIntegerComponent} from "@app/_modules/filter-fields/types/integer/filter-field-integer.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FilterFieldIntegerComponent
  ],
  exports: [
    FilterFieldIntegerComponent
  ]
})
export class FilterFieldIntegerModule {}
