import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@app/_services';

@Component({
  selector: 'auth-form-login',
  templateUrl: 'auth-form-login.component.html',
  styleUrls: ['auth-form-login.component.scss']
})
export class AuthFormLoginComponent implements OnInit {
  form: FormGroup;
  loading = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }
  get username() {
    return this.f.username.value;
  }
  get password() {
    return this.f.password.value;
  }

  onSubmit() {
    if (this.form.invalid) return;
    this.loading = true;
    this.authService.login(this.username, this.password).subscribe(res => {
      this.authService.token = res;
      this.router.navigate(['']).then();
    }, error => {
      this.setError(error.message);
      this.loading = false;
    });
  }

  setError(error: string) {
    let trans = {'The user credentials were incorrect.': 'Имя пользователя или пароль указаны неверно.'};
    this.error = trans[error] || error;
  }

  forget(){
    this.router.navigate(
      [ {outlets: {auth: 'password/forget'}}], 
      {skipLocationChange: true}
    ).then();
  }

  signup(){
    this.router.navigate(
      [ {outlets: {auth: 'signup'}}], 
      {skipLocationChange: true}
    ).then();
  }
}
