<div class="contact">
  <h2 [innerText]="name" *ngIf="name"></h2>
  <h2 *ngIf="legalAddress">Юридический адрес</h2>
  <p *ngIf="legalAddress" class="legal-address" [innerText]="legalAddress"></p>
  <h2 *ngIf="locationAddress">Фактический адрес</h2>
  <p *ngIf="locationAddress" class="local-address" [innerText]="locationAddress"></p>
  <h2 *ngIf="email">Email</h2>
  <p *ngIf="email" class="email"><a href="mailto:{{email}}">{{email}}</a></p>
  <h2 *ngIf="phone">Телефон</h2>
  <p *ngIf="phone" class="phone" [innerText]="phone"></p>
  <h2 *ngIf="description">Информация</h2>
  <div *ngIf="description" [innerHTML]="description"></div>
</div>
