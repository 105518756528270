<div class="top-panel pt-40">
  <h1>Телефонный справочник</h1>
  <div class="filters fullwidth" [formGroup]="filters">
    <div class="limiter">
      <div class="search">
        <label>Поиск</label>
        <input type="text" formControlName="search" />
      </div>
    </div>
  </div>
</div>
<phonebook-list [companyId]="'main'"></phonebook-list>
