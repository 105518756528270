<div class="user" (click)="ddHidden=!ddHidden" (mouseleave)="ddHidden=true" *ngIf="user">
  <div class="avatar">
    <img *ngIf="avatar" [src]="avatar.links?.thumb" />
    <span *ngIf="!avatar" class="initials">{{user.initials}}</span>
  </div>
  <div class="dropdown" [class.hidden]="ddHidden" (click)="close()" #menu>
    <div class="menu">
      <div (click)="link('profile')" class="type">
        <div class="row">Личный кабинет</div>
      </div>
      <!-- <li *ngIf="isJournalVisible" (click)="link('applications')">Журнал заявок</li> -->
      <div class="type" *ngIf="authService.isAdmin||authService.isEditor" (click)="link('administrate')">
        <div class="row">Панель управления</div>
      </div>
      <div class="type" (click)="logout()">
        <div class="row">Выход из системы</div>
      </div>
    </div>
  </div>
  <div class="name">
    {{user.name}}
  </div>

  <drop-down [target]="menu" [visible]="!ddHidden" ico="arrow_drop_down_24"></drop-down>
</div>