<div class="bar" (click)="toggle()">
  <div class="name">{{category.name}}</div>
  <div class="menu" *ngIf="editMode" (click)="$event.stopPropagation()">
    <ico ico="drag-n-drop_24" class="move" title="Переместить"></ico>
    <ico ico="edit_24" title="Редактировать" (click)="edit()"></ico>
    <ico ico="add_library_24" title="Добавить подкатегорию" (click)="addCategory()"></ico>
    <ico ico="add_notes_24" title="Добавить запись" (click)="addEntry()"></ico>
    <ico ico="delete_24" title="Удалить категорию" (click)="delete()"></ico>
  </div>
  <drop-down [visible]="isExpanded" ico="chevron_right_24" [angle]="[90,-90]"></drop-down>
</div>
<div class="details" *ngIf="isExpanded">
  <registry-categories [registry]="registry" [parent]="category" [editMode]="editMode"></registry-categories>
  <registry-entries [registry]="registry" [category]="category" [editMode]="editMode"></registry-entries>
</div>
