import {Component, Input} from '@angular/core';

@Component({
  selector: 'phonebook-list-item',
  templateUrl: 'phonebook-list-item.component.html',
  styleUrls: ['phonebook-list-item.component.scss']
})
export class PhonebookListItemComponent {
  @Input() member: any;

  constructor() {
  }

  get user() {
    return this.member?.user?.data;
  }
  get department() {
    return this.member?.department?.data;
  }

  ngOnInit() {
  }

}
