import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'video-section',
  templateUrl: 'video-section.component.html',
  styleUrls: ['video-section.component.scss']
})
export class VideoSectionComponent {
  @Input() section: any;

  constructor(
    private objectsService: ObjectsService,
    private sanitizer:  DomSanitizer
  ) {
  }

  public url:any = '';
  public source:string;
  public videoID:string;
  public poster:string;
  public start = false;

  ngOnInit() {
    const URL = this.parsedUrl(this.objectsService.getValue(this.section, 'video-url'));
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(URL);
  }

  parsedUrl(url: any) {
    let res = '';
    if (/https:\/\/youtu.be\//.test(url)) {
      this.videoID = url.replace('https://youtu.be/','').replace(/\?.*/,'').split('&')[0];
      this.source = 'youtube.com';
      res = 'https://www.youtube.com/embed/' + this.videoID + '?autoplay=1';
    } else if (/https:\/\/www\.youtube\.com\/watch\?v\=/.test(url)) {
      this.videoID = url.replace('https://www.youtube.com/watch?v=','').replace(/\?.*/,'').split('&')[0];
      this.source = 'youtube.com';
      res = 'https://www.youtube.com/embed/' + this.videoID + '?autoplay=1';
    } else if (/https:\/\/rutube.ru\/video\//.test(url)) {
      this.videoID = url.replace('https://rutube.ru/video/','');
      this.source = 'rutube.ru';
      this.start = true;
      res = 'https://rutube.ru/play/embed/' + this.videoID;
    } else {
      this.start = true;
      res = url;
    }
    if (this.source == 'youtube.com') {
      this.poster = 'https://img.youtube.com/vi/' + this.videoID + '/hqdefault.jpg'
    }
    return res;
  }
}
