import {Component, Input} from '@angular/core';

@Component({
  selector: 'field-boolean-readonly',
  templateUrl: 'field-boolean-readonly.component.html',
  styleUrls: ['field-boolean-readonly.component.scss']
})
export class FieldBooleanReadonlyComponent {
  @Input() field: any;

  constructor() {
  }

  ngOnInit() {
  }


  get value() {
    return this.field.value?.data[0];
  }
  get caption() {
    return ((this.value === undefined) || (this.value === null)) ? 'Не указано' : (this.value ? 'Да' : 'Нет');
  }

}
