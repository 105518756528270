import {Component, Input} from '@angular/core';
import {CompaniesService} from "@app/_services/companies.service";

@Component({
  selector: 'company-advisories',
  templateUrl: 'company-advisories.component.html',
  styleUrls: ['company-advisories.component.scss']
})
export class CompanyAdvisoriesComponent {
  @Input() companyId: string;
  @Input() editable = false;
  public company: any;

  constructor(private companiesService: CompaniesService) {
  }


  get advisoryCompanies() {
    return this.company?.advisoryCompanies?.data;
  }


  ngOnInit() {
    this.fetch();
  }


  fetch() {
    this.companiesService.fetch(this.companyId, {include: 'advisoryCompanies.advisory.logo'}).subscribe(res => {
      this.company = res.data;
    });
  }




}
