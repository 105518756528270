import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import {CompanyMembersService} from "@app/_services/company-members.service";

@Component({
  selector: 'phonebook-list',
  templateUrl: 'phonebook-list.component.html',
  styleUrls: ['phonebook-list.component.scss']
})
export class PhonebookListComponent {
  @Input() companyId: string;
  public items = <any>[];

  private controlsSubscription: Subscription;
  private resultSubscription: Subscription;

  constructor(private membersService: CompanyMembersService, private listsService: ListsService) {
  }

  get listId() {
    return 'phonebook-list';
  }

  ngOnInit() {
    this.listsService.setFilters({company: this.companyId}, this.listId);
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch(controls);
    });
    this.resultSubscription = this.listsService.result(this.listId).subscribe(res => {
      this.items = res?.data || [];
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultSubscription?.unsubscribe();
  }

  fetch(controls: any) {
    let include = ['user', 'department'];
    this.membersService.list({page: controls.page || 0, filters: JSON.stringify(controls.filters), include: include.join(',')}).subscribe(res => {
      this.listsService.result(this.listId).next(res);
    });
  }

}
