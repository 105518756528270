import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import {ApplicationsService} from "@app/_services/applications.service";

@Component({
  selector: 'applications-list',
  templateUrl: 'applications-list.component.html',
  styleUrls: ['applications-list.component.scss']
})
export class ApplicationsListComponent {
  public items = <any>[];

  private controlsSubscription: Subscription;
  private resultSubscription: Subscription;

  constructor(private applicationsService: ApplicationsService, private listsService: ListsService) {
  }

  ngOnInit() {
    this.controlsSubscription = this.listsService.controls().subscribe(controls => {
      this.fetch(controls);
    });
    this.resultSubscription = this.listsService.result().subscribe(res => {
      this.items = res?.data || [];
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultSubscription?.unsubscribe();
  }

  fetch(controls: any) {
    let include = ['submitter', 'product', 'permissions'];
    this.applicationsService.list({page: controls.page || 0, filters: JSON.stringify(controls.filters), include: include.join(',')}).subscribe(res => {
      this.listsService.result().next(res);
    });
  }

}
