<div class="add">
  <div class="toggle">
    <div class="button" (click)="add('page-sidebar')"></div>
  </div>
  <!--div class="toggle"><button type="button" (click)="toggle()">Добавить секцию</button></div>
  <div class="dropdown" *ngIf="ddVisible">
    <ul class="menu">
      <li *ngFor="let type of types"><span (click)="add(type.name)">{{type.title}}</span></li>
    </ul>
  </div-->
</div>
