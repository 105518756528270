import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {CompanyMembersService} from "@app/_services/company-members.service";

@Component({
  selector: 'company-member',
  templateUrl: 'company-member.component.html',
  styleUrls: ['company-member.component.scss']
})
export class CompanyMemberComponent {
  @Input() member: any;
  @Input() parent: any;

  @Input() editable = false;
  @Input() draggable = false;

  constructor(private membersService: CompanyMembersService, private formsService: FormsService, private listsService: ListsService) {
  }

  get user() {
    return this.member?.user?.data;
  }
  get phone() {
    return this.user?.phone;
  }
  get email() {
    return this.user?.email;
  }
  get avatar() {
    return this.user?.avatar?.data.links?.thumb;
  }
  get role() {
    return this.member?.role;
  }

  ngOnInit() {
  }


  edit() {
    this.formsService.editModel('companyMember', this.member.id, null, this.parent?.id || null);
  }

  delete() {
    if (confirm(`Удалить сотрудника «${this.user.name}»?`)) this.membersService.delete(this.member.id).subscribe(res => {
      this.listsService.refresh(this.parent.id);
    });
  }

}
