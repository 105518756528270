import {Component, Input} from '@angular/core';

@Component({
  selector: 'publications-page',
  templateUrl: 'publications-page.component.html',
  styleUrls: ['publications-page.component.scss'],
})
export class PublicationsPageComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
