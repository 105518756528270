import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterFieldDateComponent} from "@app/_modules/filter-fields/types/date/filter-field-date.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {NoopAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NoopAnimationsModule
  ],
  declarations: [
    FilterFieldDateComponent
  ],
  exports: [
    FilterFieldDateComponent
  ]
})
export class FilterFieldDateModule {}
