import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PublicationsListComponent} from "@app/_modules/publications/list/publications-list.component";
import {PublicationsListItemComponent} from "@app/_modules/publications/list/item/publications-list-item.component";
import {PublicationsListItemMenuComponent} from "@app/_modules/publications/list/item/menu/menu.component";
import {PaginationModule} from "@app/_modules/pagination/pagination.module";
import {PublicationPageComponent} from "@app/_modules/publications/publication/publication-page.component";
import {PageSectionsModule} from "@app/_modules/pages/sections/page-sections.module";
import {RouterModule} from "@angular/router";
import { WidjetModule } from '../widjet/widjet.module';
import { GelleryComponent } from './list/item/gellery/gellery.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PaginationModule,
    PageSectionsModule,
    WidjetModule
  ],
  declarations: [
    PublicationsListComponent,
    PublicationsListItemComponent,
    PublicationsListItemMenuComponent,
    PublicationPageComponent,
    GelleryComponent
  ],
  exports: [
    PublicationsListComponent,
    PublicationPageComponent
  ]
})
export class PublicationsModule {}
