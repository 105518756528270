<div class="section">
  <add-section *ngIf="editMode" [page]="page" [ord]="ord" [modelType]="modelType"></add-section>
  <page-menu [editMode]="editMode" [index]="index" [page]="page" [section]="section" [modelId]="modelId" [modelType]="modelType" dir="ltr"></page-menu>
  <div [ngSwitch]="type?.name">
    <header-section [section]="section" *ngSwitchCase="'page-section-header'"></header-section>
    <text-section [section]="section" *ngSwitchCase="'page-section-text'"></text-section>
    <html-section [section]="section" *ngSwitchCase="'page-section-html'"></html-section>
    <list-section [section]="section" *ngSwitchCase="'page-section-list'"></list-section>
    <images-section [section]="section" *ngSwitchCase="'page-section-images'"></images-section>
    <documents-section [section]="section" *ngSwitchCase="'page-section-documents'"></documents-section>
    <video-section [section]="section" *ngSwitchCase="'page-section-video'"></video-section>
    <button-section [section]="section" *ngSwitchCase="'page-section-button'"></button-section>
    <iframe-section [section]="section" *ngSwitchCase="'page-section-iframe'"></iframe-section>
    <contact-section [section]="section" *ngSwitchCase="'page-section-contacts'"></contact-section>
    <feedback-section [section]="section" *ngSwitchCase="'page-section-feedback'"></feedback-section>
    <maps-section [section]="section" *ngSwitchCase="'page-section-maps'"></maps-section>
    <cards-section [section]="section" [editMode]="editMode" *ngSwitchCase="'page-section-cards'"></cards-section>
    <!--member-section [section]="section" *ngSwitchCase="'page-section-list-members'" [page]="page" [editMode]="editMode"></member-section>
    <image-group-section [section]="section" *ngSwitchCase="'page-section-image-group'" [page]="page" [editMode]="editMode"></image-group-section>
    <document-section [section]="section" *ngSwitchCase="'page-section-document'"></document-section-->
    <p *ngSwitchDefault>section {{type?.name}} is undefined</p>
  </div>
</div>
