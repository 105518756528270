import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterFieldFloatComponent} from "@app/_modules/filter-fields/types/float/filter-field-float.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FilterFieldFloatComponent
  ],
  exports: [
    FilterFieldFloatComponent
  ]
})
export class FilterFieldFloatModule {}
