import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent {
  constructor(private router: Router) {
  }

  get url() {
    let str = this.router.url
    return str.substring(
      str.indexOf(':') + 1, 
      str.lastIndexOf(')')
    );
  }

  close() {
    this.router.navigate([{outlets: {auth: null}}]).then();
  }
}
