import {Component} from '@angular/core';
import {FormGroup, FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {ListsService} from "@app/_services";

@Component({
  templateUrl: 'phonebook-page.component.html',
  styleUrls: ['phonebook-page.component.scss']
})
export class PhonebookPageComponent {
  public filters: FormGroup;

  constructor(private listsService: ListsService) {
  }

  get listId() {
    return 'phonebook-list';
  }

  ngOnInit() {
    this.filters = new FormGroup({search: new FormControl('')});
    this.filters.valueChanges.pipe(debounceTime(200)).subscribe(val => {
      this.listsService.addFilters(val, this.listId);
    });
  }

  ngOnDestroy() {
  }


}
