import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-sections',
  templateUrl: 'page-sections.component.html',
  styleUrls: ['page-sections.component.scss']
})
export class PageSectionsComponent {
  @Input() page: any;
  @Input() editMode: boolean;
  @Input() modelType: string = 'page';

  constructor() {
  }

  ngOnInit() {
  }


  get sections() {
    return this.page?.sections?.data;
  }
}
