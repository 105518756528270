import {Component, HostListener, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {AssetsService} from "@app/_services/assets.service";
import {isNumeric} from "rxjs/internal-compatibility";

@Component({
  selector: 'field-document-single',
  templateUrl: 'field-document-single.component.html',
  styleUrls: ['../form-field-document.component.scss', 'field-document-single.component.scss']
})
export class FieldDocumentSingleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;
  public asset: any;

  public upload: any = {file: null, progress: 0};
  public dragOver = false;

  @HostListener("dragover", ["$event"])
  @HostListener("dragenter", ["$event"])
  @HostListener("dragend", ["$event"])
  @HostListener("dragleave", ["$event"])
  @HostListener("drop", ["$event"])
  onDragEvent(event: DragEvent) {
    if ((event.type === 'drop') && this.dragOver && event.dataTransfer?.files.length) {
      this.uploadFiles(event.dataTransfer?.files);
    }
    this.dragOver = event.type === 'dragover';
    event.preventDefault()
  }


  constructor(private assetsService: AssetsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {this.value = this.initialValue});
  }


  get initialValue() {
    return this.field.value?.data[0];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(asset: any) {
    this.asset = asset;
    this.control?.setValue(asset?.id);
  }



  clear() {
    this.control.markAsTouched();
    this.value = null;
  }



  onFileInput(event: any) {
    this.uploadFiles(event.target.files);
    event.target.value = null;
  }
  uploadFiles(files: any) {
    for (let file of files) {
      this.uploadFile(file);
    }
  }
  uploadFile(file: File) {
    this.upload.file = file;
    this.assetsService.upload(file).subscribe((result) => {
      if (result.type === HttpEventType.UploadProgress) {
        this.upload.progress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        this.upload = {file: null, progress: 0};
        this.value = result.body.data;
        this.control.markAsTouched();
      }
    }, error => {
      this.upload = {file: null, progress: 0};
      alert(error.error.message);
    });
  }
}

const ICON_TYPE:any={
  rtf:'DOC',
  docx:'DOC',
  doc:'DOC',
  xl:'XLS',
  xls:'XLS',
  xlsx:'XLS',
  ppt:'PPT',
  pptx:'PPT',
  jpg:'JPG',
  jpeg:'JPG',
  pdf:'PDF'
}
