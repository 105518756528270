import {Component, Input} from '@angular/core';
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'registry-page',
  templateUrl: 'registry-page.component.html',
  styleUrls: ['../../page.component.scss', 'registry-page.component.scss']
})
export class RegistryPageComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  constructor(private pagesService: PagesService) {
  }


  get registry() {
    return this.page?.registry?.data;
  }


  ngOnInit() {

  }

  ngOnChanges() {
    this.fetch();
  }

  fetch() {
    let include = ['sections.type', 'sections.groups.fields.value', 'registry'];
    this.pagesService.show(this.page.id, {include: include.join(',')}).subscribe(res => {
      this.page = res.data;
    });
  }


}
