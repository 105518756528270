<div class="item {{advisory?.type?.name || 'company'}}" [class.active]="active" (click)="touched = true">
  <div class="bar">
    <div class="left" (click)="toggle()"></div>
    <div class="mid">
      <div class="info">
        <div class="logo"><img *ngIf="logo" src="{{logo}}" alt="" /></div>
        <div class="name">
          <p>{{company?.name || advisory?.caption}}. <span class="link" *ngIf="isViewable" (click)="open()">{{company ? 'Перейти' : 'Посмотреть'}}</span></p>
          <p class="sub" *ngIf="voter">С правом голоса - {{voter?.user?.data.name}}</p>
        </div>
      </div>
    </div>
    <div class="right" *ngIf="isEditable">
      <button type="button" class="btn icon add-person" (click)="addMembers()"></button>
      <button type="button" class="btn icon close-blue" (click)="delete()"></button>
    </div>
  </div>
  <div class="items">
    <advisories-tree-member [advisoryMember]="member" [parent]="advisoryCompany" *ngFor="let member of members"></advisories-tree-member>
  </div>
</div>
