import {Component, Input} from '@angular/core';

@Component({
  selector: 'field-text-readonly',
  templateUrl: 'field-text-readonly.component.html',
  styleUrls: ['field-text-readonly.component.scss']
})
export class FieldTextReadonlyComponent {
  @Input() field: any;

  constructor() {
  }

  ngOnInit() {
  }


  get value() {
    return this.field.value?.data || [];
  }


}
