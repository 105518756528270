import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ListsService} from "@app/_services/lists.service";
import {FormGroup} from "@angular/forms";

@Component({
  templateUrl: 'slider-filters.component.html',
  styleUrls: ['slider-filters.component.scss']
})
export class SliderFiltersComponent {
  public formGroup: FormGroup;
  public target: string;
  public type: string;
  public list: string;

  public total = 0;

  private modelTitles: any = {registryEntries: 'записей реестра', applications: 'заявок'};


  constructor(private router: Router, private route: ActivatedRoute, private listsService: ListsService) {
  }

  get title() {
    return 'Поиск ' + this.modelTitles[this.type] || '';
  }


  ngOnInit() {
    this.parseUrlParams(this.route.snapshot.params);
    this.formGroup = new FormGroup({});
  }

  parseUrlParams(params) {
    this.target = params.target;
    this.type = params.type;
    this.list = params.list;
  }



  save() {
    this.listsService.setFilters(this.formGroup.value, this.list);
    this.close();
  }

  clear() {
    for (let prop in this.formGroup.value) {
      if (this.formGroup.value.hasOwnProperty(prop)) {
        if (prop !== 'registry') this.formGroup.controls[prop].setValue(null, {emitEvent: false});
      }
    }
    this.formGroup.updateValueAndValidity();
    //this.formGroup.reset();
  }

  close() {
    this.router.navigate([{ outlets: { slider: null } }]).then();
  }


}
