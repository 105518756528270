import {Component, Input} from '@angular/core';
import {AuthenticationService} from "@app/_services";
import {Router} from "@angular/router";
import { PagesService } from '@app/_services/pages.service';
import { DialogService } from '@app/_services/dialog.service';


@Component({
  selector: 'header-user-bar',
  templateUrl: 'header-user-bar.component.html',
  styleUrls: ['header-user-bar.component.scss']
})
export class HeaderUserBarComponent {
  public menuItems = <any>[];
  public ddHidden = true;

  constructor(public authService: AuthenticationService, private router: Router, private pagesService: PagesService, private dialog: DialogService) {
  }

  get user() {
    return this.authService.user;
  }
  get avatar() {
    return this.user?.avatar?.data;
  }
  get isJournalVisible() {
    return !this.authService.isMainCompanyMember || this.authService.isExpert || this.authService.isAdmin;
  }

  ngOnInit() {

  }


  close() {
    setTimeout(() => {
      this.ddHidden = true;
    }, 10);
  }


  logout() {
    this.dialog.confirm('Вы действительно хотите выйти из системы?').subscribe(
      resp=>{
        if (resp) {
          this.pagesService.editMode = false;
          this.authService.logout();
          this.ddHidden = true;
          this.router.navigate(['']).then();
        }
      }
    )
  }

  link(link: string) {
    this.pagesService.currentPageSubject.next(null);
    this.close()
    this.router.navigate([link]).then();
  }
}
