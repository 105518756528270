import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";
import {FormGroup, FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'registry',
  templateUrl: 'registry.component.html',
  styleUrls: ['registry.component.scss']
})
export class RegistryComponent {
  @Input() registry: any;
  @Input() editMode: boolean;

  public filters: FormGroup;
  public isFiltersApplied = false;

  controlsSubscription: Subscription;

  constructor(private formsService: FormsService, private listsService: ListsService) {
  }

  get listId() {
    return this.registry.id;
  }
  get categoriesListId() {
    return this.listId + '-categories';
  }
  get entriesListId() {
    return this.listId + '-entries';
  }

  get options() {
    return this.registry.type?.options;
  }
  get hasCategories() {
    return !!this.options?.categories;
  }
  get hasCustomFilters() {
    return !!(this.options?.states || this.options?.operations || this.options?.properties);
  }



  ngOnInit() {
    this.filters = new FormGroup({search: new FormControl(), 'registry': new FormControl(this.registry.id)});
    this.filters.valueChanges.pipe(debounceTime(200)).subscribe(val => {
      this.listsService.addFilters(val, this.entriesListId);
    });
    this.controlsSubscription = this.listsService.controls(this.entriesListId).subscribe(controls => {
      this.detectFilters(controls.filters);
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
  }


  detectFilters(filters: any) {
    this.isFiltersApplied = false;
    for (let prop in filters) {
      if (filters.hasOwnProperty(prop) && (filters[prop]?.length || filters[prop]?.gt || filters[prop]?.lt) && (['registry', 'category', 'types'].indexOf(prop) === -1)) this.isFiltersApplied = true;
    }
  }


  addCategory() {
    this.formsService.createModel('registryCategory', {extraProps: {registry: this.registry.id}}, this.categoriesListId);
  }

  addEntry() {
    this.formsService.createModel('registryEntry', {extraProps: {registry: this.registry.id}}, this.entriesListId);
  }

}
