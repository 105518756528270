import {Component, Input} from '@angular/core';
import {RegistryEntriesService} from "@app/_services/registry-entries.service";
import {ObjectsService} from "@app/_services";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'entry-properties',
  templateUrl: 'entry-properties.component.html',
  styleUrls: ['entry-properties.component.scss']
})
export class EntryPropertiesComponent {
  @Input() entry: any;

  constructor(private entriesService: RegistryEntriesService, private objectsService: ObjectsService, private datePipe: DatePipe) {
  }

  get fields() {
    return this.objectsService.parsedFields(this.entry.properties?.data).filter(field => {return field.value?.length || field.value?.id});
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    let include = ['properties.groups.fields.value'];
    this.entriesService.show(this.entry.id, {include: include.join(',')}).subscribe(res => {
      this.entry = res.data;
    });
  }


  getValue(field: any) {
    let value = Array.isArray(field.value) ? field.value : [field.value];
    return value.filter(val => {return val?.length || val?.id;}).map(val => {
      if (field.type === 'date') return this.datePipe.transform(val, 'dd.MM.yyyy');
      else return val?.caption || val?.title || val;
    }).join('; ');
  }

}
