import {Component} from '@angular/core';
import {Router} from "@angular/router";

import {PublicationsService} from "@app/_services/publications.service";


@Component({
  templateUrl: 'homepage.component.html',
  styleUrls: ['homepage.component.scss']
})
export class HomepageComponent {
  public news: any[] = [];
  public portfolio: any[] = [];

  constructor(
    private publicationsService: PublicationsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    //this.router.navigate(['glavnaia']);
    this.load();
  }
  ngOnDestroy() {
  }

  load() {
    // this.fetchNews();
    // this.fetchPortfolio();
  }
  fetchNews() {
    this.publicationsService.list({subType: 'publication-news'}).subscribe(result => {
      this.news = result.data;
    });
  }
  seeNews(slug3: string) {
    this.router.navigate(['press-tsentr', 'novosti', slug3]);
  }
  fetchPortfolio() {
    this.publicationsService.list({subType: 'publication-portfolio', pageLimit: 5}).subscribe(result => {
      this.portfolio = result.data;
    });
  }
}
