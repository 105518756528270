import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'iframe-section',
  templateUrl: 'iframe-section.component.html',
  styleUrls: ['iframe-section.component.scss']
})
export class IframeSectionComponent {
  @Input() section: any;

  constructor(
    private objectsService: ObjectsService,
    private sanitized: DomSanitizer
  ) {
  }


  get url() {
    return this.sanitized.bypassSecurityTrustResourceUrl(this.objectsService.getValue(this.section, 'iframe-url'));
  }

  ngOnInit() {
  }
}
