<div class="authentication">
  <div class="form">
    <div class="center" [ngSwitch]="url">
      <div [ngSwitch]="url"header>
        <h3 *ngSwitchCase="'login'">Вход в систему</h3>
        <h3 *ngSwitchCase="'signup'">Регистрация</h3>
        <h3 *ngSwitchCase="'password/forget'">Восстановление пароля</h3>
        <h3 *ngSwitchCase="'password/reset'">Сброс пароля</h3>
      </div>
      <auth-form-login *ngSwitchCase="'login'"></auth-form-login>
      <auth-form-signup *ngSwitchCase="'signup'"></auth-form-signup>
      <auth-form-password-forget *ngSwitchCase="'password/forget'"></auth-form-password-forget>
      <auth-form-password-reset *ngSwitchCase="'password/reset'"></auth-form-password-reset>
      <p *ngSwitchDefault>{{url}} is undefined</p>
    </div>
  </div>
</div>
