import {Component} from '@angular/core';

@Component({
  selector: 'homepage-partners',
  templateUrl: 'homepage-partners.component.html',
  styleUrls: ['homepage-partners.component.scss']
})
export class HomepagePartnersComponent {
  constructor() {
  }

  ngOnInit() {
  }

}
