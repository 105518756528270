import {Component} from '@angular/core';
import {AuthenticationService} from "@app/_services";
import {PagesService} from "@app/_services/pages.service";
import {Meta, Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']})
export class AppComponent {
  public subscription: Subscription;

  constructor(private authService: AuthenticationService, private pagesService: PagesService, private titleService: Title, private metaService: Meta) {
    authService.getCurrentUser();
    this.subscription = this.pagesService.metaSubject.subscribe(data => {
      this.applyMetaData(data);
    });
  }

  get isRtl() {
    return this.pagesService.isRtl && this.pagesService.currentPage;
  }

  ngOnInit() {
    this.pagesService.root({include:'children'}).subscribe(
      res => { this.pagesService.rootPages = res }
    )
  }


  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  applyMetaData(data: any) {
    if (data?.title) {
      this.titleService.setTitle(data.title);
      this.metaService.updateTag({property: 'og:title', content: data.title}, "property='og:title'");
    }
    if (data?.description) {
      this.metaService.updateTag({name: 'description', content: data?.description}, "name='description'");
      this.metaService.updateTag({property: 'og:description', content: data.description}, "property='og:description'");
    }
    if (data?.keywords) this.metaService.updateTag({name: 'keywords', content: data?.keywords}, "name='keywords'");
  }
}
