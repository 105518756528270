import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldComponent} from "@app/_modules/form-fields/field/form-field.component";
import {
  FormFieldBooleanModule,
  FormFieldDateModule,
  FormFieldDatetimeModule,
  FormFieldDocumentModule,
  FormFieldImageModule,
  FormFieldFloatModule,
  FormFieldIntegerModule,
  FormFieldRelationModule,
  FormFieldStringModule,
  FormFieldTextModule,
  FormFieldTimeModule,
  FormFieldTopicsModule,
  FormFieldHiddenModule,
  FormFieldHtmlModule,
} from "@app/_modules/form-fields/types";

@NgModule({
    imports: [
        CommonModule,
        FormFieldStringModule,
        FormFieldTextModule,
        FormFieldIntegerModule,
        FormFieldFloatModule,
        FormFieldBooleanModule,
        FormFieldDateModule,
        FormFieldDatetimeModule,
        FormFieldRelationModule,
        FormFieldDocumentModule,
        FormFieldImageModule,
        FormFieldTimeModule,
        FormFieldTopicsModule,
        FormFieldHiddenModule,
        FormFieldHtmlModule,
    ],
  declarations: [
    FormFieldComponent
  ],
  exports: [
    FormFieldComponent,
  ]
})
export class FormFieldsModule {
}
