<div class="card" [ngSwitch]="type?.name">
  <div class="menu" *ngIf="editMode">
    <div class="block">
      <a>
        <ico ico="edit_24" (click)="edit()"></ico>
      </a>
      <a>
        <ico ico="delete_24" (click)="delete()"></ico>
      </a>
    </div>

  </div>
  <cards-item-persons *ngSwitchCase="'cards-section-persons'" [card]="card"></cards-item-persons>
  <cards-item-partners *ngSwitchCase="'cards-section-partners'" [card]="card"></cards-item-partners>
  <cards-item-default *ngSwitchCase="'cards-section-default'" [card]="card"></cards-item-default>
  <cards-item-slides *ngSwitchCase="'cards-section-slides'" [card]="card"></cards-item-slides>
  <p *ngSwitchDefault>{{type?.name}} is undefined</p>
</div>
