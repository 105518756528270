<div class="row" [formGroup]="controlGroup">
  <input formControlName="gt" [matDatepicker]="gt" (click)="gt.open()" placeholder="дд.мм.гггг" [min]="min" [max]="max">
  <mat-datepicker #gt></mat-datepicker>
  <p>-</p>
  <input formControlName="lt" [matDatepicker]="lt" (click)="lt.open()" placeholder="дд.мм.гггг" [min]="min" [max]="max">
  <mat-datepicker #lt></mat-datepicker>
</div>

<!--div class="row" [formGroup]="controlGroup">
  <input type="date" formControlName="gt" placeholder="от {{min}}" [min]="min" [max]="max" step="0.1" />
  <p>-</p>
  <input type="date" formControlName="lt" placeholder="до {{max}}" [min]="min" [max]="max" step="0.1" />
</div-->
