import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldTextComponent} from "@app/_modules/form-fields/types/text/form-field-text.component";
import {FieldTextReadonlyComponent} from "@app/_modules/form-fields/types/text/readonly/field-text-readonly.component";
import {FieldTextSingleComponent} from "@app/_modules/form-fields/types/text/single/field-text-single.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldTextMultipleComponent} from "@app/_modules/form-fields/types/text/multiple/field-text-multiple.component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SortablejsModule
  ],
  declarations: [
    FormFieldTextComponent,
    FieldTextReadonlyComponent,
    FieldTextSingleComponent,
    FieldTextMultipleComponent
  ],
  exports: [
    FormFieldTextComponent
  ]
})
export class FormFieldTextModule {}
