<div class="area" [class.hover]="dragOver">
  <input #fileInput multiple="multiple" type="file" accept="image/*" [id]="field.name" (change)="onFileInput($event)" />
  <p>Перетащите сюда или <a (click)="fileInput.click()">выберите файл</a>
    <!-- в формате JPEG или PNG общим объемом не более 10 Мбайт. -->
  </p>
  <div class="indicator" *ngIf="upload.file">
    <div class="label">Загружается файл {{upload.file?.name}}</div>
    <div class="progress"><div class="fill" [style.width]="upload.progress + '%'"></div></div>
  </div>
</div>
<div class="values" *ngIf="assets?.length" [sortablejs]="value">
  <div class="item" *ngFor="let asset of assets">
    <div class="preview"><img [src]="asset.links?.thumb" alt="" /></div>
    <button type="button" class="btn clear trash" (click)="removeValue(asset)"></button>
  </div>
</div>
