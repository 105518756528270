import {Component, Input} from '@angular/core';

@Component({
  selector: 'field-datetime-readonly',
  templateUrl: 'field-datetime-readonly.component.html',
  styleUrls: ['field-datetime-readonly.component.scss']
})
export class FieldDatetimeReadonlyComponent {
  @Input() field: any;

  constructor() {
  }

  ngOnInit() {
  }

  get value() {
    return this.field.value?.data || [];
  }

}
