import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'publication-page',
  templateUrl: 'publication-page.component.html',
  styleUrls: ['publication-page.component.scss']
})
export class PublicationPageComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  constructor(
    private router: Router
  ) {
  }

  get posters() {
    return this.page.posters?.data;
  }

  back(): void {
    window.history.back();
}

  ngOnInit() {
  }
}
