import { Component, Input } from '@angular/core';
import {ObjectsService} from "@app/_services";
import { Router } from '@angular/router';


@Component({
  selector: 'cards-item-slides',
  templateUrl: './cards-item-slides.component.html',
  styleUrls: ['./cards-item-slides.component.scss']
})
export class CardsItemSlidesComponent {

  constructor(private objectsService: ObjectsService, private router: Router) {}


  @Input() card:any;

  get header() {
    return this.objectsService.getValue(this.card, 'header');
  }
  get subheader() {
    return this.objectsService.getValue(this.card, 'subheader');
  }
  get text() {
    return this.objectsService.getValue(this.card, 'text');
  }
  get image() {
    return this.objectsService.getValue(this.card, 'image')?.links?.full+'?width=500';
  }
  get isImage(){
    return this.objectsService.getValue(this.card, 'image')
  }
  get link() {
    return this.objectsService.getValue(this.card, 'link');
  }

  navigate(){
    this.router.navigate([this.link]).then()
  }



}
