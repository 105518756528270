import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthenticationService} from "@app/_services";
import {PagesService} from "@app/_services/pages.service";

@Component({
  templateUrl: 'administration-page.component.html',
  styleUrls: ['administration-page.component.scss']
})
export class AdministrationPageComponent {
  public tabs = <any>[];
  routeSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthenticationService, private pagesService: PagesService) {
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && (this.route.snapshot.paramMap.get('tab') !== this.tab?.name)) this.switchTab(this.route.snapshot.paramMap.get('tab'));
    });
  }

  get tab() {
    return this.tabs.filter(tab => {return tab.active})[0];
  }

  ngOnInit() {
    this.authService.userSubject.subscribe(val => {
      this.makeTabs();
    });
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
  }

  makeTabs() {
    //this.tabs = [{name: 'company', title: 'Структура ФАУ «ФЦС»'}, {name: 'committee', title: 'Структура ТК 465'}];
    //if (this.authService.isSuperAdmin) this.tabs.push({name: 'site-pages', title: 'Структура сайта'});
    this.tabs = [
      {name: 'site-pages', title: 'Структура сайта', access: ["admin", "editor"]}, 
      {name: 'users', title: 'Пользователи', access: ["admin"]}, 
      {name: 'licence', title: 'Данные о лицензии', access: ["admin"]}];
    this.switchTab(this.route.snapshot.paramMap.get('tab'));
  }

  switchTab(name: string) {
    this.tabs.map(tab => {tab.active = tab.name === name});
  }

  showTab(tab){
    return this.privileges&&tab.access.filter(role=>this.privileges[role]).length
  }

  get privileges(){
    return this.authService.user?.privileges
  }

}
