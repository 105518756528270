import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-text',
  templateUrl: 'form-field-text.component.html',
  styleUrls: ['form-field-text.component.scss']
})
export class FormFieldTextComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }


}
