import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-sidebars',
  templateUrl: 'page-sidebars.component.html',
  styleUrls: ['page-sidebars.component.scss']
})
export class PageSidebarsComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  constructor() {
  }

  get sidebars() {
    return this.page?.sidebars?.data;
  }

  ngOnInit() {

  }

}
