<h2>Новости</h2>
<div class="holder">
  <swiper [config]="config"
  >
    <ng-template ngFor let-item [ngForOf]="items">
      <ng-template swiperSlide>
      <div class="slide">
        <img [src]="item?.poster?.data?.links?.full" />
        <a routerLink="{{item?.link}}">
          <div class="date" [innerText]="item?.publishDateRus"></div>
          <div class="excerpt" [innerText]="item?.name"></div>
        </a>
      </div>
      </ng-template>
    </ng-template>
  </swiper>
  <div class="navigation">
    <button class="btn icon prev"></button>
    <div class="bullets"></div>
    <button class="btn icon next"></button>
  </div>
</div>
<div class="more"><a routerLink="/press-tsentr/novosti">Все новости</a></div>
