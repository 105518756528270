<div class="image">
  <img  *ngIf="image?.links?.thumb" [src]="image?.links?.thumb" />
</div>
<div class="title" *ngIf="name">{{name}}</div>
<div class="subtitle" *ngIf="position">{{position}}</div>
<div class="phone" *ngIf="phone">{{phone}}</div>
<div class="email" *ngIf="email">{{email}}</div>
<div class="details" *ngIf="textOnly" (click)="showModal=true">
  <a>{{about}}<ico ico="chevron_right_24"></ico></a>
</div>
<modal *ngIf="showModal" (close)="showModal=false">
  <h4 header>{{name}}</h4>
  <img  *ngIf="image?.links?.thumb" [src]="image?.links?.thumb" />
  <div class="subtitle" *ngIf="position">{{position}}</div>
  <div class="phone" *ngIf="phone">{{phone}}</div>
  <div class="email" *ngIf="email">{{email}}</div>
  <div [innerHtml]="html"></div>

</modal>

