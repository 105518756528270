<div class="item">
  <div class="left">
    <div class="avatar">
      <img *ngIf="avatar" [src]="avatar.links.thumb" />
      <span *ngIf="!avatar" class="initials">{{user.initials}}</span>
    </div>
  </div>
  <div class="middle">
    <div class="user-data">
      <span class="name">{{user.name}}</span>
       <span class="role {{role.name}}">{{role.title}}</span></div>
    <div class="position">{{user.position}}</div>
  </div>
  <div class="right">
    <ico ico="edit_24" (click)="edit()"></ico>
    <ico ico="delete_24" (click)="delete()"></ico>
  </div>
</div>
