import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormFieldDatetimeComponent} from "@app/_modules/form-fields/types/datetime/form-field-datetime.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldDatetimeReadonlyComponent} from "@app/_modules/form-fields/types/datetime/readonly/field-datetime-readonly.component";
import {FieldDatetimeSingleComponent} from "@app/_modules/form-fields/types/datetime/single/field-datetime-single.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldDatetimeComponent,
    FieldDatetimeReadonlyComponent,
    FieldDatetimeSingleComponent
  ],
  exports: [
    FormFieldDatetimeComponent
  ],
  providers: [
    DatePipe
  ]
})
export class FormFieldDatetimeModule {}
