<div class="area" [class.hover]="dragOver">
  <input #fileInput type="file" accept="application/*" [id]="field.name" (change)="onFileInput($event)" />
  <p>Перетащите сюда или
    <a (click)="fileInput.click()">выберите файл</a>
    в формате PDF, XLS, DOC, JPG, PPTX<!-- общим объемом не более 10 Мбайт.-->
  </p>
  <div class="indicator" *ngIf="upload.file">
    <div class="label">Загружается файл {{upload.file?.name}}</div>
    <div class="progress"><div class="fill" [style.width]="upload.progress + '%'"></div></div>
  </div>
</div>
<div class="values" *ngIf="asset">
  <field-document-value [asset]="asset" (delete)="clear()"></field-document-value>
</div>
