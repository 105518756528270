import {Component, Input} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'object-form-group',
  templateUrl: 'object-form-group.component.html',
  styleUrls: ['object-form-group.component.scss']
})
export class ObjectFormGroupComponent {
  @Input() group!: any;
  @Input() formGroup!: FormGroup;
  @Input() readonly = false;
  @Input() params?: any = {};

  constructor() {
  }

  ngOnInit() {
    if (this.group.readonly !== undefined) this.readonly = this.group.readonly;
  }

}
