import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";

@Component({
  selector: 'setting-site',
  templateUrl: 'setting-site.component.html',
  styleUrls: ['setting-site.component.scss']
})
export class SettingSiteComponent {
  resultsSubscription?: Subscription;

  public isShow: boolean = false;

  public classes: any = [{class: 'style-color'}, {class: 'style-brown'}, {class: 'style-white-black'}, {class: 'style-black-white'}];
  public nameId = 'class';
  public params = {fontSize: 16, brightRange: 100, contrastRange: 100, class: 'style-color', showImages: true};

  constructor(
    private listsService: ListsService,
  ) {
  }
  ngOnInit() {
    this.resultsSubscription = this.listsService.result('showSettingSite').subscribe(val => {
      this.isShow = val;
    });
    this.setMainClass();
  }
  ngOnDestroy() {
    this.resultsSubscription?.unsubscribe();
  }


  get activeClass(): string {
    let res = this.params.class;
    if (!this.params.showImages) res += ' image-hidden';
    return res;
  }


  setClass(type: string) {
    this.params.class = type;
    this.setMainClass();
  }
  setMainClass() {
    this.listsService.result(this.nameId).next(this.activeClass);
  }
  setImages(isShow: boolean) {
    this.params.showImages = isShow;
    this.setMainClass();
  }
  setDefaultParams() {
    this.params = {fontSize: 16, brightRange: 100, contrastRange: 100, class: 'style-color', showImages: true};
    this.listsService.result(this.nameId).next(this.activeClass);
    this.applyParams();
  }
  setParam(type: string, value: any) {
    switch (type) {
      case 'font':
        switch (value) {
          case true: ++this.params.fontSize; break;
          case false: --this.params.fontSize; break;
          case null: this.params.fontSize = 16; break;
        }
      break;
      case 'bright':
        switch (value) {
          case true: this.params.brightRange += 5; break;
          case false: this.params.brightRange -= 5; break;
          case null: this.params.brightRange = 100; break;
        }
      break;
      case 'contrast':
        switch (value) {
          case true: this.params.contrastRange += 10; break;
          case false: this.params.contrastRange -= 10; break;
          case null: this.params.contrastRange = 100; break;
        }
      break;
    }
    this.applyParams();
  }
  applyParams(){
    let style = document.getElementById('html').style;
    style.fontSize = this.params.fontSize + 'px';
    style.filter = 'brightness(' + this.params.brightRange + '%) contrast(' + this.params.contrastRange + '%)';
  }
  close() {
    this.listsService.result('showSettingSite').next(false);
    localStorage.setItem('showSettingSite', 'no');
  }
}
