import {Component, Input, OnInit} from '@angular/core';
import {PagesService} from "@app/_services/pages.service";
import { combineLatest } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pages-menu',
  templateUrl: 'pages-menu.component.html',
  styleUrls: ['pages-menu.component.scss']
})
export class PagesMenuComponent implements OnInit {
  @Input() level = 0;
  @Input() items:any;
  private subscription: Subscription

  constructor(
    private pagesService: PagesService,
    
    ) {
  }

  ngOnInit() {
    if(!this.items){
      this.subscription = combineLatest([
        this.pagesService.currentPageSubject,
        this.pagesService.rootPagesSubject
      ]).subscribe(
        resp=>{    
          let [currentPage, rootPages] = resp     
          if(currentPage){
            let menuTree = currentPage.parents.data.reduceRight(
              (menuTree,item)=>{
                let fothersSonID = item.children.data.findIndex(
                  child => child.id == menuTree.id
                )                
                item.children.data[fothersSonID] = JSON.parse(JSON.stringify(menuTree))
                return item
              }, currentPage);
            this.items = menuTree.children.data;
          }else{
            if(rootPages){
              this.items = rootPages.data.find( 
                page => page.slug == this.pagesService.rootPage.slug
              )?.children.data
            }
          }
        }     
      )
    }
  }

  ngOnDestroy(){
    this.subscription?.unsubscribe()
  }

}