import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class LicenceService {
  private host = 'https://services.testnir.ru';
  private productName = 'cms';
  public licenceSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
  }

  get number() {
    return environment.licence;
  }

  get licence() {
    return this.licenceSubject.value;
  }
  set licence(value: any) {
    this.licenceSubject.next(value);
  }

  get product() {
    return this.licence?.product?.data;
  }
  get options() {
    return this.licence?.options?.data;
  }

  get isActive() {
    return this.isActiveState && this.isCorrectProduct;
  }
  get isActiveState() {
    return this.licence?.state?.name === 'active';
  }
  get isCorrectProduct() {
    return this.product?.name === this.productName;
  }


  public check() {
    this.fetch().subscribe(res => {
      this.licence = res?.data;
    });
  }

  fetch(): Observable<any> {
    let include = ['product', 'options'];
    return this.http.get(`${this.host}/api/licences/check/${this.number}`, {params: {include: include.join(',')}});
  }


  public hasOption(name: string): boolean {
    return !!this.options?.filter(item => {return item.name === name}).length;
  }

  public checkEditAvailability(page?: any) {
    if (!this.isActive) return this.error('Срок действия лицензии истек. Режим редактирования не доступен. Продлите лицензию или обратитесь в службу поддержки НИР (+7 499 490 04 65, help@nirgroup.ru)');
    if ((page?.type?.name === 'registry') && !this.hasOption('registries')) return this.error('Данная опция не входит в Вашу лицензию. Для включения опции обратитесь в службу поддержки НИР (+7 499 490 04 65, help@nirgroup.ru)');
    if ((page?.type?.name === 'publications') && !this.hasOption('publications')) return this.error('Данная опция не входит в Вашу лицензию. Для включения опции обратитесь в службу поддержки НИР (+7 499 490 04 65, help@nirgroup.ru)');
    return null;
  }

  public error(msg: string) {
    return msg;
  }

}
