import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable, BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class DialogService {

    public modalAllertSubject = new BehaviorSubject<string>(null)
    public modalPromptSubject = new BehaviorSubject<string>(null);
    public modalWaitingSubject = new BehaviorSubject<string>(null);
    public modalRespSubject = new BehaviorSubject<boolean>(null);
    private userResp: BehaviorSubject<boolean>

    constructor(){
        this.modalRespSubject.subscribe(
            resp=>{
                if (resp !== null){
                    this.userResp.next(resp);
                    this.userResp.complete();
                }
            }
        )
    }


    alert(txt:string){
        this.modalAllertSubject.next(txt)
    }

    confirm(txt:string){
        this.modalPromptSubject.next(txt);
        this.userResp = new BehaviorSubject(null)
        return this.userResp;
    }

    waiting(txt:string){
        this.modalWaitingSubject.next(txt);
    }

}