<div class="space" *ngIf="!isRootPage">
    <div class="jumbotron-content layout-corral">
        <header-user-bar></header-user-bar>
        <div class="breadcrumbs" *ngIf="parents">
            <div *ngFor="let parent of parents">
                <a [routerLink]="parent.link" *ngIf="parent.type.name!='nav-page'">                    
                    <span>{{parent.name}}</span>
                    <ico ico="chevron_right_24" [size]="24"></ico>
                </a>
            </div>
            <div>{{currentPage.name}}</div>
        </div>
        <h1>{{name}}</h1>
        <img [src]="pagePicture" *ngIf="pagePicture">        
    </div>
</div>


<div class="space root-page" *ngIf="isRootPage">
    <div class="jumbotron-content layout-corral">
        <header-user-bar></header-user-bar>
        <div class="breadcrumbs" *ngIf="parents?.length>1">
            <div *ngFor="let parent of parents">
                <a [routerLink]="parent.link">                    
                    <span>{{parent.name}}</span>
                    <ico ico="chevron_right_24" [size]="24"></ico>
                </a>
            </div>
            <div>{{currentPage.name}}</div>
        </div>
        <h1>{{name}}</h1>


        <div class="root-menu">
            <a  class="root-menu-item" routerLink="kommunalnye-uslugi">
                <div class="root-menu-img">
                    <img src="assets/images/root_menu_services.jpg" alt="">
                </div>
                <div class="root-menu-name">Услуги</div>
            </a>
            <div  class="root-menu-item" routerLink="otzyvy">
                <div class="root-menu-img">
                    <img src="assets/images/root_menu_replies.png" alt="">
                </div>
                <div class="root-menu-name">Отзывы</div>
            </div>
            <div  class="root-menu-item"  routerLink="platnye-uslugi">
                <div class="root-menu-img">
                    <img src="assets/images/root_menu_payment.png" alt="">
                </div>
                <div class="root-menu-name">Платные услуги</div>
            </div>
            <div  class="root-menu-item" routerLink="planovye-i-avariinye-otklyuceniya">
                <div class="root-menu-img">
                    <img src="assets/images/root_menu_disabling.png" alt="">
                </div>
                <div class="root-menu-name">Плановые и аварийные отключения</div>
            </div>
        </div>   
    </div>
</div>

<div class="space jumbotron-edit-field" *ngIf="editable" dir="ltr">
    <div class="layout-corral jumbotron-edit-field-row">
        <div class="switch-host" (click)="toggleEditMode()">
            Режим редактирования
            <switch [val]="editMode"></switch>       
        </div>         
    </div>
</div>
