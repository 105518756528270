import { Component, Output, EventEmitter } from '@angular/core';
import {WindowScrollingService} from "@app/_services/window-scrolling.service";

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Output() close = new EventEmitter<string>();

  constructor( private windowScrollingService: WindowScrollingService ){}

  ngOnInit(){
    this.windowScrollingService.disable();
  }
  ngOnDestroy(){
    this.windowScrollingService.enable();
  }

  cls(){
    this.close.emit('close')
  }
}
