import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {RegistryCategoriesService} from "@app/_services/registry-categories.service";

@Component({
  selector: 'registry-category',
  templateUrl: 'registry-category.component.html',
  styleUrls: ['../../registry.component.scss', 'registry-category.component.scss']
})
export class RegistryCategoryComponent {
  @Input() registry: any;
  @Input() parent: any;
  @Input() category: any;
  @Input() editMode: boolean;
  public isExpanded = false;

  constructor(private categoriesService: RegistryCategoriesService, private formsService: FormsService, private listsService: ListsService) {
  }

  get parentListId() {
    return (this.parent?.id || this.registry?.id) + '-categories';
  }
  get categoriesListId() {
    return this.category.id + '-categories';
  }
  get entriesListId() {
    return this.category.id + '-entries';
  }


  ngOnInit() {

  }


  edit() {
    this.formsService.editModel('registryCategory', this.category.id, null, this.parentListId);
  }

  addCategory() {
    this.isExpanded = true;
    this.formsService.createModel('registryCategory', {extraProps: {registry: this.registry.id, parent: this.category.id}}, this.categoriesListId);
  }

  addEntry() {
    this.isExpanded = true;
    this.formsService.createModel('registryEntry', {extraProps: {registry: this.registry.id, category: this.category.id}}, this.entriesListId);
  }

  delete() {
    if (confirm('Удалить категорию?')) this.categoriesService.delete(this.category.id).subscribe(res => {
      this.listsService.refresh(this.parentListId, true);
    });
  }


  toggle() {
    this.isExpanded = !this.isExpanded;
  }

}
