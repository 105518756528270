import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import { PagesService } from '@app/_services/pages.service';
import {PublicationsService} from "@app/_services/publications.service";

@Component({
  selector: 'grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.scss']
})
export class GridComponent {

  public classActive = '';

  public bright: number = 150;
  public contrast: number = 100;

  public news:any;

  subscription?: Subscription;

  constructor(
    private listsService: ListsService, private pagesService: PagesService, private publicationsService: PublicationsService
  ) {
  }
  get currentPage(){
    return this.pagesService.currentPage
  }

  ngOnInit() {
    this.pagesService.currentPageSubject.subscribe(
      currentPage=>{
        if(currentPage && this.isRootPage){
          console.log('currentPage',currentPage.link)
          this.pagesService.find(currentPage.link,{include: 'children.children'}).subscribe(
            resp=> {
              let children = resp.data.children.data;
              console.log('children',children)
              this.news = children.find(
                (child:any) => {
                  console.log('child',child)
                  if (!child.children.data.length) {
                    console.log('notChild',child)
                    return false
                  } else {
                    return child.children.data.reduce(
                     (resp:boolean, page:any ) =>{
                      return resp && page.type.name == 'publications'
                     }, true
                    )
                  }
                } 
              ).children.data
              
              this.news.forEach(page => {
                this.publicationsService.list({
                  page:1,
                  filters:JSON.stringify({
                    page: page.id
                  }),
                  include: 'posters'
                }).subscribe(
                  resp=>{
                    page.publications = resp.data.slice(0,3).map(
                      publication => ({
                        name: publication.name,
                        img: publication.posters.data[0]?publication.posters.data[0].links.thumb:null,
                        link: publication.link,
                        date: publication.publishDateRus
                      })
                    );
                  }
                )
              });
            }
          )
        }
      }
    )
    this.subscription = this.listsService.result('class').subscribe(val => {
      this.classActive = val;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  get isRootPage(){
    return this.pagesService.currentPage && this.pagesService.currentPage.parents && this.pagesService.currentPage.parents.data.length == 0
  }


}
