import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ObjectsService} from "@app/_services/objects.service";
import {FormGroup} from "@angular/forms";
import {ListsService} from "@app/_services/lists.service";

@Component({
  selector: 'object-filters',
  templateUrl: 'object-filters.component.html',
  styleUrls: ['object-filters.component.scss']
})
export class ObjectFiltersComponent {
  @Input() formGroup!: FormGroup;
  @Input() target: string;
  @Input() type: string;
  @Input() objectTypeName?: string;
  @Input() list: string;

  @Output() onFiltersUpdated = new EventEmitter<number>();

  public formData: any;

  constructor(private objectsService: ObjectsService, private listsService: ListsService) {
  }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(data => {
      this.fetch(data);
    });
    this.fetch(this.listsService.controls(this.list).value.filters);
  }

  get groups() {
    return this.formData?.groups?.data;
  }


  fetch(filters: any) {
    (this.target === 'object') ? this.fetchObjectFilters(filters) : this.fetchModelFilters(filters);
  }

  fetchObjectFilters(filters: any) {
    this.objectsService.fetchType(this.type, {include: 'groups.fields.represented,groups.fields.range'}).subscribe(res => {
      this.formData = res.data;
    });
  }

  fetchModelFilters(filters: any) {
    this.objectsService.fetchModelFilters(this.type, {include: 'value,represented,range', filters: JSON.stringify(filters)}).subscribe(res => {
      this.formGroup.reset(undefined, {emitEvent: false});
      this.formData = res;
      this.onFiltersUpdated.emit(res.total || 0);
    });
  }


}
