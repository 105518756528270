import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'search-sections-item',
  templateUrl: 'search-sections-item.component.html',
  styleUrls: ['search-sections-item.component.scss']
})
export class SearchSectionsItemComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService) {
  }

  get page() {
    return this.section.pages?.data[0];
  }
  get content() {
    let result = (this.objectsService.getValue(this.section, 'header-required') || this.objectsService.getValue(this.section, 'html-required')).replace(/<[^>]+>/g, '').replace('&nbsp;', ' ');
    return (result.length > 200) ? result.substring(0, 200) + '...' : result;
  }

  ngOnInit() {
  }
}
