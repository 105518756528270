import {Component, Input} from '@angular/core';
import {FormsService, ListsService, ObjectsService} from "@app/_services";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'page-menu',
  templateUrl: 'page-menu.component.html',
  styleUrls: ['page-menu.component.scss']
})
export class PageMenuComponent {

  delete() {
    this.dialog.confirm('Удалить этот блок?').subscribe(
      resp=>{
        if (resp) this.objectsService.destroy(this.section.id).subscribe(res => {
          this.listsService.refresh();
        });
      }
    )
  }
  
  @Input() page: any;
  @Input() section: any;
  @Input() editMode: boolean;
  @Input() index: number;
  @Input() modelId: string;
  @Input() modelType: string;

  constructor(
    private formsService: FormsService, 
    private objectsService: ObjectsService, 
    private listsService: ListsService,
    private dialog: DialogService) {
  }

  ngOnInit() {
    //console.log(this.section)
  }


  get ord() {
    return this.section?.pivot?.ord;
  }

  get total() {
    return this.page?.sections?.data?.length || 1;
  }

  get isFirst() {
    return this.index === 0;
  }

  get isLast() {
    return (this.index + 1) === this.total;
  }


  edit() {
    this.formsService.editObject(this.section.id);
  }

  move(ord) {
    this.objectsService.move(this.section.id, {modelType: this.modelType, modelId: this.modelId, ord: ord}).subscribe(res => {
      this.listsService.refresh();
    });
  }


}
