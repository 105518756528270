import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {SearchPageComponent} from "@app/_modules/search/page/search-page.component";
import {PublicationsModule} from "@app/_modules/publications/publications.module";
import {RegistriesModule} from "@app/_modules/registries/registries.module";
import {ReactiveFormsModule} from "@angular/forms";
import {PagesModule} from "@app/_modules/pages/pages.module";

const routes = [
  {path: 'search', component: SearchPageComponent}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    PublicationsModule,
    RegistriesModule,
    PagesModule
  ],
  declarations: [
    SearchPageComponent
  ],
  exports: [
    RouterModule
  ]
})
export class SearchModule {}
