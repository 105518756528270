<div class="main">
  <div class="left">
    <div class="title">{{application.title}}</div>
    <div class="status {{status?.name}}">{{status?.title}}</div>
    <table class="default">
      <tr><td class="caption">Hаименование продукции</td><td class="value">{{product?.name}}</td></tr>
      <tr><td class="caption">Hазначение продукции</td><td class="value">{{product?.purpose}}</td></tr>
      <tr><td class="caption">Заявитель</td><td class="value">{{application?.applicant}}</td></tr>
    </table>
  </div>
  <div class="right"><button type="button" class="toggle" [class.active]="active" (click)="toggle()"></button></div>
</div>
<div class="details" *ngIf="active">
  <table class="default">
    <tr><td class="caption">Дата формирования заявки</td><td class="value">{{application?.createdAt | date: 'dd.MM.yyyy HH:mm'}}</td></tr>
    <tr><td class="caption">Область применения продукции</td><td class="value">{{product?.usage || '—'}}</td></tr>
    <tr><td class="caption">Нормативно-технический документ</td><td class="value">{{product?.normative || '—'}}</td></tr>
    <tr><td class="caption">Изготовитель / разработчик</td><td class="value">{{product?.producer || '—'}}</td></tr>
    <tr><td class="caption">Ф.И.О. контактного лица</td><td class="value">{{submitter?.name || '—'}}</td></tr>
    <tr>
      <td class="caption">Документы</td>
      <td class="value">
        <div class="documents list default" *ngIf="documents?.length">
          <div class="items">
            <div class="item" *ngFor="let document of documents"><a [href]="document.links?.open" target="_blank">{{document.id}}</a></div>
          </div>
        </div>
        <span *ngIf="!documents?.length">—</span>
      </td>
    </tr>
  </table>
  <table class="default" *ngIf="conclusion">
    <tr><td class="caption">Эксперт</td><td class="value">{{expert?.name}}</td></tr>
    <tr><td class="caption">Дата ответа</td><td class="value">{{conclusion?.createdAt | date: 'dd.MM.yyyy HH:mm'}}</td></tr>
    <tr><td class="caption">Мнение эксперта</td><td class="value" [innerHTML]="conclusion?.message"></td></tr>
  </table>
  <div class="reply" *ngIf="permissions?.reply && !conclusion"><button type="button" class="btn" (click)="reply()">Ответить</button></div>
</div>
