import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from "@angular/router";
import {AuthFormLoginComponent} from "@app/_modules/auth/forms/login/auth-form-login.component";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthFormPasswordForgetComponent} from "@app/_modules/auth/forms/forget/auth-form-password-forget.component";
import {AuthFormPasswordResetComponent} from "@app/_modules/auth/forms/reset/auth-form-password-reset.component";
import {AuthFormSignupComponent} from "@app/_modules/auth/forms/signup/auth-form-signup.component";
import {AuthPageComponent} from "@app/_modules/auth/page/auth-page.component";
import {NotAuthGuard} from "@app/_helpers/not-auth.guard";
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { WidjetModule } from '../widjet/widjet.module';

const routes = [
  {path: 'login', component: AuthPageComponent, canActivate: [NotAuthGuard]},
  {path: 'login', component: AuthModalComponent, canActivate: [NotAuthGuard],  outlet: 'auth'},
  {path: 'signup', component: AuthPageComponent, canActivate: [NotAuthGuard]},
  {path: 'signup', component: AuthModalComponent, canActivate: [NotAuthGuard], outlet: 'auth'},
  {path: 'password/forget', component: AuthPageComponent, canActivate: [NotAuthGuard]},
  {path: 'password/forget', component: AuthModalComponent, canActivate: [NotAuthGuard], outlet: 'auth'},
  {path: 'password/reset/:token/:email', component: AuthPageComponent, canActivate: [NotAuthGuard]},
  {path: 'password/reset/:token/:email', component: AuthPageComponent, canActivate: [NotAuthGuard], outlet: 'auth'}
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    WidjetModule,
    RouterModule.forRoot(routes)
  ],
  exports: [

  ],
  declarations: [
    AuthModalComponent,
    AuthPageComponent,
    AuthFormLoginComponent,
    AuthFormSignupComponent,
    AuthFormPasswordForgetComponent,
    AuthFormPasswordResetComponent,
    AuthModalComponent
  ],
})
export class AuthModule {}
