import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";

@Component({
  templateUrl: 'search-page.component.html',
  styleUrls: ['search-page.component.scss']
})
export class SearchPageComponent {
  public filters: FormGroup;
  public tabs: any = [
    {name: 'pages', title: 'Страницы сайта', active: true},
    {name: 'publications', title: 'Публикации'},
    {name: 'entries', title: 'Записи реестров'}
  ];
  public total = 0;
  public searchString = '';
  public loading: any = {pages: true, publications: true, entries: true};

  pagesSubscription: Subscription;
  publicationsSubscription: Subscription;
  entriesSubscription: Subscription;

  constructor(private listsService: ListsService) {
    this.pagesSubscription = this.listsService.result('sections-list').subscribe(res => {
      this.setCount('pages', res?.meta?.pagination?.total);
    });
    this.publicationsSubscription = this.listsService.result('publications-list').subscribe(res => {
      this.setCount('publications', res?.meta?.pagination?.total);
    });
    this.entriesSubscription = this.listsService.result('common-entries').subscribe(res => {
      this.setCount('entries', res?.meta?.pagination?.total);
    });
  }

  get activeTab() {
    return this.tabs.filter(item => {return item.active})[0];
  }
  get enabledTabs() {
    return this.tabs.filter(item => {return !item.disabled});
  }

  get isLoaded() {
    return !this.loading?.pages && !this.loading?.publications && !this.loading?.entries;
  }

  ngOnInit() {
    this.filters = new FormGroup({search: new FormControl()});
    this.filters.valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.loading = {pages: true, publications: true, entries: true};
      if (val.search) {
        this.listsService.addFilters(val, 'sections-list');
        this.listsService.addFilters(val, 'publications-list');
        this.listsService.addFilters(val, 'common-entries');
      } else this.resetResults();
      this.searchString = val.search;
    });
  }

  ngOnDestroy() {
    this.pagesSubscription?.unsubscribe();
    this.publicationsSubscription?.unsubscribe();
    this.entriesSubscription?.unsubscribe();
  }


  toggleTab(tab: any) {
    if (!tab.disabled) {
      this.tabs = this.tabs.map(item => {
        item.active = item.name === tab.name;
        return item;
      });
    }
  }

  setCount(tabName, value) {
    this.total = 0;
    this.tabs = this.tabs.map(item => {
      if (item.name === tabName) {
        item.count = value || 0;
        item.disabled = item.count < 1;
      }
      this.total += (item.count || 0);
      return item;
    });
    this.loading[tabName] = false;
    if (this.isLoaded) this.checkActiveTab();
  }

  checkActiveTab() {
    let active = this.activeTab;
    let enabled = this.enabledTabs;
    if ((!active || active.disabled) && enabled.length) {
      this.toggleTab(enabled[0]);
    }
  }

  resetResults() {
    this.listsService.result('sections-list').next(null);
    this.listsService.result('publications-list').next(null);
    this.listsService.result('common-entries').next(null);

  }

}
