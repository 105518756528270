<div class="item member">
  <div class="bar">
    <div class="mid">
      <div class="info">
        <div class="logo" [class.voter]="isVoter">
          <img *ngIf="avatar" src="{{avatar}}" alt="" />
          <div *ngIf="!avatar" class="initials">{{user?.abbreviationName}}</div>
        </div>
        <div class="name"><p>{{user?.name}}</p><p class="sub">{{member.position}}</p></div>
      </div>
    </div>
    <div class="right" *ngIf="isEditable">
      <button type="button" class="btn icon delete" (click)="delete()"></button>
    </div>
  </div>
</div>
