<div class="item {{advisory?.type?.name}}" [class.main]="advisory.isMain" [class.active]="active" (click)="touched = true">
  <div class="bar">
    <div class="left" (click)="toggle()"></div>
    <div class="mid">
      <div class="info">
        <div class="logo">
          <img *ngIf="logo" src="{{logo}}" alt="" />
          <span *ngIf="!logo">{{noLogoLetters}}</span>
        </div>
        <div class="name">
          <p>{{advisory?.caption}}<span *ngIf="type?.name">. <span class="link" (click)="info()">Посмотреть</span></span></p>
          <p *ngIf="secretary" class="sub">Секретарь - {{secretary?.user?.data.name}}</p>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="menu" *ngIf="isEditable">
        <button *ngIf="advisory.isMain" type="button" class="btn icon add-group" (click)="addGroup()"></button>
        <button type="button" class="btn icon add-child" (click)="addAdvisory()"></button>
        <!--button type="button" class="btn icon add-company" (click)="addCompanies()"></button>-->
        <button type="button" class="btn icon edit" (click)="edit()"></button>
        <button *ngIf="isDeletable" type="button" class="btn icon close-blue" (click)="delete()"></button>
      </div>
    </div>
  </div>
  <div class="items">
    <advisories-tree-company [advisoryCompany]="advisoryCompany" [parent]="advisory" *ngFor="let advisoryCompany of advisoryCompanies"></advisories-tree-company>
    <advisories-tree-advisory [advisory]="child" [parent]="advisory" *ngFor="let child of children"></advisories-tree-advisory>
  </div>
</div>
