import {Component, OnInit, Input, HostListener, ElementRef, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UtilityService} from "@app/_services/utility.service";

@Component({
  selector: 'combobox-multiple',
  templateUrl: 'combobox-multiple.component.html',
  styleUrls: ['combobox-multiple.component.scss', '../../form-field-relation.component.scss']
})
export class ComboboxMultipleComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;
  @ViewChild("searchInput") searchInput?: ElementRef;

  public active = false;
  public options = <any>[];
  public search = '';

  constructor(private eRef: ElementRef, private utilityService: UtilityService) {
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (!this.eRef.nativeElement.contains(target)) this.active = false;
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
    setTimeout(() => {this.value = this.initialValue});
    this.updateOptions();
  }

  get initialValue() {
    return (this.value?.length) ? this.value : (this.field.value?.data || []);
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value: any[]) {
    this.control.setValue(value);
  }

  get caption() {
    return this.value?.length ? `Выбрано ${this.value?.length} значений` : 'Не выбрано';
  }


  onInput(event) {
    this.search = event.target.value;
    this.updateOptions();
  }

  updateOptions() {
    this.options = this.utilityService.filterOptions(this.search, this.field.options?.data || []);
  }

  toggleValue(option: any) {
    this.isSelected(option) ? this.removeValue(option) : this.addValue(option);
    this.active = false;
  }
  addValue(option: any) {
    this.control.markAsTouched();
    let val = this.value;
    val.push(option);
    this.value = val;
  }
  removeValue(option: any) {
    this.control.markAsTouched();
    this.value = this.value.filter(val => {return option.id !== val.id});
  }
  isSelected(option: any) {
    for (let i = 0; i < this.value?.length; i++) {
      if (this.value[i]?.id === option.id) return true;
    }
  }

  toggleActivity() {
    this.active = !this.active;
    if (this.active) setTimeout(() => {this.searchInput?.nativeElement.focus()}, 10);
  }
}
