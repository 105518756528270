import {Component, Input} from '@angular/core';
import {DepartmentsService} from "@app/_services/departments.service";
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";

@Component({
  selector: 'company-department',
  templateUrl: 'company-department.component.html',
  styleUrls: ['company-department.component.scss']
})
export class CompanyDepartmentComponent {
  @Input() department: any;
  @Input() active = false;
  @Input() parent: any;
  @Input() editable = false;
  public controlsSubscription?: Subscription;

  public touched = false;

  constructor(private departmentsService: DepartmentsService, private listsService: ListsService, private formsService: FormsService) {
  }


  get ftl() {
    return this.department.title.substring(0, 2);
  }
  get isRoot() {
    return this.department.name === 'root';
  }
  get isDeletable() {
    return !this.isRoot;
  }

  get departments() {
    return this.department?.children?.data || [];
  }
  get members() {
    return this.department?.members?.data || [];
  }


  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.department?.id).subscribe(val => {
      if (this.active || this.touched) this.fetch();
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
  }


  fetch() {
    this.departmentsService.fetch(this.department?.id, {include: 'members.user.avatar,children'}).subscribe(res => {
      this.department = res.data;
      this.active = true;
    });
  }



  edit() {
    this.formsService.editModel('department', this.department.id, null, this.department.id);
  }

  addMember() {
    this.formsService.createModel('companyMember', {extraProps: {department: this.department.id}}, this.department.id);
  }

  addDepartment() {
    this.formsService.createModel('department', {extraProps: {department: this.department.id}}, this.department.id);
  }

  delete() {
    if (confirm(`Удалить подразделение «${this.department.title}»?`)) this.departmentsService.delete(this.department.id).subscribe(res => {
      this.listsService.refresh(this.parent.id);
    });
  }


  toggle() {
    if (!this.department.children) this.fetch();
    else this.active = !this.active;
  }


}
