import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'field-time-single',
  templateUrl: 'field-time-single.component.html',
  styleUrls: ['field-time-single.component.scss']
})
export class FieldTimeSingleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
    setTimeout(() => {this.value = this.initialValue || this.value || null});
  }


  get initialValue() {
    return this.field?.value?.data[0];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value) {
    this.control?.setValue((value?.length > 5) ? this.datePipe.transform(value, 'HH:mm') : value);
  }
}
