import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class CompanyMembersService {
  constructor(private http: HttpClient) {
  }

  list(params?: {}): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/members`, {params: params});
  }

  fetch(id: string, params?: {}): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/members/${id}`, {params: params});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/members/${id}`);
  }

}
