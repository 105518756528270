import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {PagesService} from "@app/_services/pages.service";
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";
import {SortableOptions} from "sortablejs";

@Component({
  selector: 'pages-tree',
  templateUrl: 'pages-tree.component.html',
  styleUrls: ['pages-tree.component.scss']
})
export class PagesTreeComponent {
  @Input() parent: any;
  public pages = <any>[];
  subscription: Subscription;

  public optionsPages: SortableOptions = {
    group: 'site-pages',
    handle: '.logo',
    onUpdate: (event: any) => {this.move(event)},
    onAdd: (event: any) => {this.move(event)}
  };
  public optionsLocales: SortableOptions = {
    group: 'site-locales',
    handle: '.logo',
    onUpdate: (event: any) => {this.move(event)},
    onAdd: (event: any) => {this.move(event)}
  };

  constructor(private router: Router, private pagesService: PagesService, private listsService: ListsService, private formsService: FormsService) {
  }

  get listId() {
    return this.parent?.id || 'pages-tree-root';
  }

  ngOnInit() {
    this.subscription = this.listsService.controls(this.listId).subscribe(res => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  fetch() {
    this.parent ? this.fetchSubpages() : this.fetchRootPages();
  }

  fetchRootPages() {
    let include = ['children.children'];
    this.pagesService.root({include: include.join(','), withTrashed: true}).subscribe(res => {
      this.pages = res.data;
    });
  }

  fetchSubpages() {
    let include = ['children.children'];
    this.pagesService.show(this.parent.id, {include: include.join(','), withTrashed: true}).subscribe(res => {
      this.pages = res.data?.children?.data;
    });
  }


  add() {
    this.formsService.createModel('page', null, this.listId);
  }


  move(event: any) {
    this.pagesService.move(event.item.id, {parent: this.parent?.id, ord: event.newIndex}).subscribe(res => {
      this.listsService.refresh(this.parent?.id);
      this.pagesService.root({include:'children'}).subscribe(
        res => { this.pagesService.rootPages = res }
      )
    });
  }
}
