import {Component, Input} from '@angular/core';
import {CompaniesService} from "@app/_services/companies.service";

@Component({
  selector: 'company-structure',
  templateUrl: 'company-structure.component.html',
  styleUrls: ['company-structure.component.scss']
})
export class CompanyStructureComponent {
  @Input() companyId: string;
  @Input() editable = false;
  public company: any;

  constructor(private companiesService: CompaniesService) {
  }


  get department() {
    return this.company?.rootDepartment?.data;
  }

  get members() {
    return this.company?.rootMembers?.data;
  }



  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.companiesService.fetch(this.companyId, {include: 'rootDepartment.children,rootDepartment.members.user.avatar,rootMembers.user'}).subscribe(res => {
      this.company = res.data;
    });
  }



}
