<div class="questions">
  <div class="question" *ngFor="let items of field.items; let i = index;">
    <div class="head">
      <div class="title">Вопрос №{{i+1}}</div>
      <button type="button" class="btn icon close" (click)="remove(i)" *ngIf="value.length > 1"></button>
    </div>
    <ng-template ngFor let-item [ngForOf]="items">
      <div class="row {{item.type}}">
        <div class="name" *ngIf="item.type!=='hidden'" [innerHTML]="item.title"></div>
        <div class="field">
          <form-field-text *ngIf="item.type==='text'" [field]="item" [formGroup]="topics[i]"></form-field-text>
          <form-field-relation *ngIf="item.type==='relation'" [field]="item" [formGroup]="topics[i]"></form-field-relation>
          <form-field-document *ngIf="item.type==='document'"  [field]="item" [formGroup]="topics[i]"></form-field-document>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div class="question-add" (click)="add(emptyItem)">
  <img src="assets/images/icons/add_circle_24dp.svg" />
  Добавить вопрос
</div>
