import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterFieldTextComponent} from "@app/_modules/filter-fields/types/text/filter-field-text.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FilterFieldTextComponent
  ],
  exports: [
    FilterFieldTextComponent
  ]
})
export class FilterFieldTextModule {}
