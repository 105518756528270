import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjectFormComponent} from "@app/_modules/objects/form/object-form.component";
import {ObjectFormGroupComponent} from "@app/_modules/objects/form/group/object-form-group.component";
import {FormFieldsModule} from "@app/_modules/form-fields/form-fields.module";
import {ObjectFiltersComponent} from "@app/_modules/objects/filters/object-filters.component";
import {ObjectFiltersGroupComponent} from "@app/_modules/objects/filters/group/object-filters-group.component";
import {FilterFieldsModule} from "@app/_modules/filter-fields/filter-fields.module";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "@app/_helpers";
import {SliderFormComponent} from "@app/_modules/objects/form/slider/slider-form.component";
import {SliderFiltersComponent} from "@app/_modules/objects/filters/slider/slider-filters.component";
import {FormFrameComponent} from "@app/_modules/objects/form/frame/form-frame.component";
import { WidjetModule } from '../widjet/widjet.module';

const routes: Routes = [
  {path: 'form/:target/:type', component: SliderFormComponent, outlet: 'slider', canActivate: [AuthGuard]},
  {path: 'form/:target/:type/:id', component: SliderFormComponent, outlet: 'slider', canActivate: [AuthGuard]},
  {path: 'form/:target/:type/:id/:list', component: SliderFormComponent, outlet: 'slider', canActivate: [AuthGuard]},
  {path: 'filters/:target/:type', component: SliderFiltersComponent, outlet: 'slider'},
  {path: 'filters/:target/:type/:list', component: SliderFiltersComponent, outlet: 'slider'}
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormFieldsModule,
    FilterFieldsModule,
    WidjetModule
  ],
  declarations: [
    ObjectFormComponent,
    ObjectFormGroupComponent,
    ObjectFiltersComponent,
    ObjectFiltersGroupComponent,
    SliderFormComponent,
    SliderFiltersComponent,
    FormFrameComponent
  ],
  exports: [
    ObjectFormComponent,
    ObjectFiltersComponent,
    ObjectFormGroupComponent
  ]
})
export class ObjectsModule {}
