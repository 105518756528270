<cms-modal (close)="close()" *ngIf="showAllert">
    <div header>
        <h4>Внимание</h4>
    </div>
    <div body>
        {{allert}}
    </div>
    <div footer>
        <button class="prime" (click)="close()">
            Закрыть
        </button>
    </div>
</cms-modal>

<cms-modal (close)="respNo()" *ngIf="showPrompt">
    <div header>
        <h4>Подтверждение</h4>
    </div>
    <div body>
        {{prompt}}
    </div>
    <div footer>
        <button class="prime outline" (click)="respNo()">
            ОТМЕНА
        </button>
        <button class="prime" (click)="respYes()">
            ПОДТВЕРДИТЬ
        </button>
    </div>
</cms-modal>

<cms-modal *ngIf="showWaiting" [blocked]="true">

        <div body>
            <div>
                {{waiting}}
            </div>
            <spinner>
                <ico [size]="48" ico="spinner_48"></ico>
            </spinner>
        </div>


    
</cms-modal>

