import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from "@angular/router";

import {environment} from '@environments/environment';
import {OauthToken, User} from '@app/_models';
import {UsersService} from "@app/_services/users.service";
import {LicenceService} from "@app/_services/licence.service";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public tokenSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('oauthToken')));
  public userSubject = new BehaviorSubject(null);

  constructor(private http: HttpClient, private router: Router, private usersService: UsersService, private licenceService: LicenceService) {
  }

  get token(): OauthToken {
    return this.tokenSubject.value;
  }
  set token(value: OauthToken) {
    this.tokenSubject.next(value);
    if (value) {
      localStorage.setItem('oauthToken', JSON.stringify(value));
      this.getCurrentUser();
    } else {
      localStorage.removeItem('oauthToken');
      this.user = null;
    }
  }

  get user(): User {
    return this.userSubject.value;
  }
  set user(value: User) {
    this.userSubject.next(value);
  }

  get isLoggedIn(): boolean {
    return !!this.token?.access_token;
  }


  public get privileges() {
    return this.user?.privileges;
  }
  get isSuperAdmin() {
    return this.privileges?.superAdmin;
  }
  get isAdmin() {
    return this.privileges?.admin;
  }
  get isExpert() {
    return this.privileges?.expert;
  }
  get isMainCompanyMember() {
    return this.privileges?.mainCompanyMember;
  }
  get isEditor() {
    return this.privileges?.editor;
  }


  signup(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/signup`, data);
  }
  login(username: string, password: string): Observable<any> {
    let data = {grant_type: 'password', client_id: environment.clientId, client_secret: environment.clientSecret, username: username, password: password};
    return this.http.post(`${environment.apiUrl}/oauth/token`, data);
  }
  forget(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/passwords/reset`, {email});
  }
  restore(data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/passwords/reset`, data);
  }


  getCurrentUser(params?: any) {
    if (this.isLoggedIn) this.usersService.me(params).subscribe(res => {
      this.user = res.data;
      this.licenceService.check();
    });
  }


  logout() {
    this.token = null;
  }



  popup(path: any) {
    this.router.navigate(
      [ {outlets: {auth: path}}], 
      {skipLocationChange: true}
    ).then();
  }
}
