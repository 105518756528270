import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {AdvisoryCompaniesService} from "@app/_services/advisory-companies.service";
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'advisories-tree-company',
  templateUrl: 'advisories-tree-company.component.html',
  styleUrls: ['../advisories-tree.component.scss', 'advisories-tree-company.component.scss']
})
export class AdvisoriesTreeCompanyComponent {
  @Input() advisoryCompany: any;
  @Input() parent: any;
  @Input() active = false;
  public controlsSubscription?: Subscription;

  public touched = false;


  constructor(private advisoryCompaniesService: AdvisoryCompaniesService, private listsService: ListsService, private formsService: FormsService, private router: Router, private dialog: DialogService) {
  }


  get logo() {
    return this.advisory?.logo?.data.links?.full || this.company?.logo?.data.links?.full;
  }
  get company() {
    return this.advisoryCompany?.company?.data;
  }
  get advisory() {
    return this.advisoryCompany?.advisory?.data;
  }
  get members() {
    return this.advisoryCompany?.advisoryMembers?.data;
  }
  get voter() {
    return this.advisoryCompany?.voter?.data.companyMember?.data;
  }

  get parentPermissions() {
    return this.parent?.permissions;
  }
  get isEditable() {
    return this.parentPermissions?.edit || this.parentPermissions?.anything;
  }
  get permissions() {
    return this.company?.permissions;
  }
  get isViewable() {
    return this.permissions?.view || this.permissions?.anything || this.isEditable;
  }


  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.advisoryCompany.id).subscribe(val => {
      if (this.active || this.touched) this.fetch();
    });
  }

  ngOnDestroy() {
    if (this.controlsSubscription) this.controlsSubscription.unsubscribe();
  }



  fetch() {
    this.advisoryCompaniesService.show(this.advisoryCompany.id, {include: 'advisoryMembers.companyMember.user.avatar,voter.companyMember.user'}).subscribe(res => {
      this.advisoryCompany.voter = res.data.voter;
      this.advisoryCompany.advisoryMembers = res.data.advisoryMembers;
      this.active = true;
    });
  }


  toggle() {
    if (!this.members) this.fetch();
    else this.active = !this.active;
  }

  open() {
    if (this.company) this.router.navigate(['companies', this.company?.id]).then();
    else this.formsService.editModel('advisoryInfo', this.advisory.id);
  }

  addMembers() {
    this.formsService.editModel('advisoryCompanyMembers', this.advisoryCompany.id, null, this.advisoryCompany.id);
  }


  delete() {
    this.dialog.confirm('Исключить организацию из списка участников комитета?').subscribe(
      resp=>{
        if (resp) {
          this.advisoryCompaniesService.delete(this.advisoryCompany.id).subscribe(res => {
            this.listsService.refresh(this.parent?.id);
          });
        }
      }
    )
  }
}
