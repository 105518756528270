import { Component, Input } from '@angular/core';

@Component({
  selector: 'gellery',
  templateUrl: './gellery.component.html',
  styleUrls: ['./gellery.component.scss']
})
export class GelleryComponent {
 @Input() images:any[];
 public fullScreen = false;
 public currentImage:any;
 public id:number
  ngOnInit() {
    if (this.images) this.currentImage = this.images[0];
  }
}
