import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {WindowScrollingService} from "@app/_services/window-scrolling.service";

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() width: 'string';
  @Input() side: 'left'|'right' ='right';

  css:any;
  direction:any;

  constructor(
    private router:Router,
    private windowScrollingService: WindowScrollingService
  ){}

  ngOnInit(){
    this.css = {
      'left-side': this.side=='left',
      'right-side': this.side=='right'
    }
    this.direction = {
      direction: this.side == 'left' ? 'auto':'ltr'
    }
    this.windowScrollingService.disable();
  }
  ngOnDestroy(){
    this.windowScrollingService.enable();
  }


  close() {
    this.router.navigate([{outlets: {slider: null}}]).then();
  }

}
