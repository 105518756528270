import { Component, EventEmitter, Input, Output } from '@angular/core';
import {FormGroup, FormControl} from "@angular/forms";
import {AssetsService} from "@app/_services";

@Component({
  selector: 'field-document-value',
  templateUrl: 'field-document-value.component.html',
  styleUrls: ['field-document-value.component.scss']
})
export class FieldDocumentValueComponent {
  @Input() asset: any;
  @Output() delete = new EventEmitter;
  public formGroup: FormGroup;
  public edit:any;


  constructor(private assetsService: AssetsService) {
  }


  ngOnInit(){
    this.default();
  }

  default() {
    this.formGroup = new FormGroup({
      name: new FormControl(this.asset.name),
      description: new FormControl(this.asset.description)
    });
  }

  update() {
    this.assetsService.update(this.asset.id, this.formGroup.value).subscribe(res => {
      this.asset = res.data;
      this.edit = {off:true};
    });
  }

  close(){
    this.edit = {off:true};
  }

  del(){
    this.delete.emit(this.asset)
  }

}
