import { Component, Input } from '@angular/core';

@Component({
  selector: 'file-ico',
  templateUrl: './file-ico.component.html',
  styleUrls: ['./file-ico.component.scss']
})
export class FileIcoComponent {
  typeList = {
    PDF:'PDF',
    DOC:'DOC',
    DOCX:'DOC',
    DOTM:'DOC',
    DOTX:'DOC',
    TXT:'DOC',
    RTF:'DOC',
    ODT:'DOC',
    JPG:'JPG',
    JPEG:'JPG',
    JPE:'JPG',
    JFIF :'JPG',
    PPT:'PPT',
    PPTX:'PPT',
    XLS:'XLS',
    XLSM:'XLS',
    XLSB:'XLS',
    XLSX:'XLS',
    CSV:'XLS',
    XML:'XLS',
  }

  @Input() type: 'string';

  get url(){
    let type = this.type?.toUpperCase()
    let svg = this.typeList[type]?
    this.typeList[type]+'_60.svg':
    'FILE_60.svg';
    return `assets/images/ico/${svg}`
  }
}
