import {Component, Input} from '@angular/core';
import {AdvisoriesService} from "@app/_services/advisories.service";
import {FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'advisories-tree-advisory',
  templateUrl: 'advisories-tree-advisory.component.html',
  styleUrls: ['../advisories-tree.component.scss', 'advisories-tree-advisory.component.scss']
})
export class AdvisoriesTreeAdvisoryComponent {
  @Input() advisory: any;
  @Input() parent: any;
  @Input() active = false;
  public controlsSubscription?: Subscription;

  public touched = false;


  constructor(private advisoriesService: AdvisoriesService, private listsService: ListsService, private formsService: FormsService, private dialog: DialogService) {
  }


  get type() {
    return this.advisory?.type;
  }
  get logo() {
    return this.advisory?.logo?.data.links?.full;
  }
  get noLogoLetters() {
    let result = '';
    if (this.type?.name === 'committee') result = this.advisory.isMain ? 'ТК' : 'ПК';
    if (this.type?.name === 'workgroup') result = 'РГ';
    return result;
  }
  get companies() {
    return this.advisoryCompanies.map(item => {return item.company?.data}) || [];
  }
  get advisoryCompanies() {
    return this.advisory?.advisoryCompanies?.data || [];
  }
  get children() {
    return this.advisory?.children?.data || [];
  }
  get secretary() {
    return this.advisory?.secretary?.data;
  }
  get permissions() {
    return this.advisory?.permissions;
  }
  get isEditable() {
    return this.permissions?.edit || this.permissions?.anything;
  }
  get isDeletable() {
    return this.isEditable && !this.advisory.isMain;
  }

  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.advisory.id).subscribe(val => {
      if (this.active || this.touched) this.fetch();
    });
  }

  ngOnDestroy() {
    if (this.controlsSubscription) this.controlsSubscription.unsubscribe();
  }


  fetch() {
    let include = ['logo', 'children.logo' ,'advisoryCompanies.company.logo', 'advisoryCompanies.voter.companyMember.user', 'secretary.user', 'children.secretary.user',
      'permissions', 'children.permissions', 'advisoryCompanies.permissions', 'advisoryCompanies.company.permissions'];
    //let include = 'logo,children.logo,advisoryCompanies.company.logo,permissions,children.permissions,advisoryCompanies.company.permissions';
    this.advisoriesService.show(this.advisory.id, {include: include.join(',')}).subscribe(res => {
      this.advisory = res.data;
      this.active = true;
    });
  }



  edit() {
    this.formsService.editModel(this.type?.name ? 'advisory' : 'advisoryGroup', this.advisory.id, null, this.advisory.id);
  }

  delete() {
    this.dialog.confirm(`Удалить ${this.advisory.caption}`).subscribe(
      resp=>{
        if (resp) this.advisoriesService.delete(this.advisory.id).subscribe(res => {
          this.listsService.refresh(this.parent.id);
        });
      }
    )
  }

  addAdvisory() {
    this.formsService.createModel('advisory', {extraProps: {parent: this.advisory.id}}, this.advisory.id);
  }

  addGroup() {
    this.formsService.createModel('advisoryGroup', {extraProps: {parent: this.advisory.id}}, this.advisory.id);
  }


  info() {
    this.formsService.editModel('advisoryInfo', this.advisory.id);
  }



  toggle() {
    if (!this.advisory.children) this.fetch();
    else this.active = !this.active;
  }


}
