import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'relation-checkbox',
  templateUrl: 'relation-checkbox.component.html',
  styleUrls: ['relation-checkbox.component.scss']
})
export class RelationCheckboxComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;

  ngOnInit(): void {
    setTimeout(() => {this.value = this.initialValue});
  }


  get initialValue() {
    return (this.value?.length) ? this.value : (this.field.value?.data || []);
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get options() {
    return this.field.options?.data || [];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value: any[]) {
    this.control.setValue(value);
  }


  onChange(event: any, option: any) {
    this.control.markAsTouched();
    event.target.checked ? this.addValue(option) : this.removeValue(option);
  }
  addValue(option: any) {
    let val = this.value;
    val.push(option);
    this.value = val;
  }
  removeValue(option: any) {
    this.value = this.value.filter(val => {return option.id !== val.id});
  }

  isSelected(option: any) {
    for (let i = 0; i < this.value?.length; i++) {
      if (this.value[i]?.id === option.id) return true;
    }
  }

}
