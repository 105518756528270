import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";

@Component({
  selector: 'add-section',
  templateUrl: 'add-section.component.html',
  styleUrls: ['add-section.component.scss']
})
export class AddSectionComponent {
  @Input() page: any;
  @Input() ord: number;
  @Input() modelType: string;
  public types = <any>[];
  public ddVisible = false;
  public sub:boolean[] =[]

  constructor(private formsService: FormsService, private objectsService: ObjectsService) {
  }

  ngOnInit() {
    this.fetchTypes();
  }


  fetchTypes() {
    this.objectsService.fetchType('page-section', {include: 'children.children'}).subscribe(res => {
      this.types = res.data?.children?.data;
      this.types = this.types.map(item => {
        item.parent = item?.children?.data?.length > 0;
        item.showChilren = false;
        return item;
      });
    });
  }
  add(type: string) {
    this.types = this.types.map(item => {
      item.showChilren = false;
      return item;
    });
    let attach = {modelType: this.modelType, modelId: this.page.id, group: 'sections', ord: this.ord};
    this.formsService.createObject(type, {extraProps: {attach: attach}});
    this.hideDD();
  }

  submenu(type: any) {
    this.types = this.types.map(item => {
      item.showChilren = item.showChilren ? false : item.id === type.id;
      return item;
    });
  }

  toggleDD() {
    this.ddVisible = !this.ddVisible;
  }
  showDD() {
    this.ddVisible = true;
  }
  hideDD() {
    this.ddVisible = false;
  }
  toggleSub(i:number){
    let val=!this.sub[i];
    this.sub = this.sub.map(item=>false);
    this.sub[i]=val;
  }
}
