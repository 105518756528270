import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

import {FormFieldRelationComponent} from "@app/_modules/form-fields/types/relation/form-field-relation.component";
import {FieldRelationReadonlyComponent} from "@app/_modules/form-fields/types/relation/readonly/field-relation-readonly.component";
import {FieldRelationSingleComponent} from "@app/_modules/form-fields/types/relation/single/field-relation-single.component";
import {FieldRelationMultipleComponent} from "@app/_modules/form-fields/types/relation/multiple/field-relation-multiple.component";
import {ComboboxMultipleComponent} from "@app/_modules/form-fields/types/relation/multiple/combobox/combobox-multiple.component";
import {RelationCheckboxComponent} from "@app/_modules/form-fields/types/relation/multiple/checkbox/relation-checkbox.component";
import {ComboboxSingleComponent} from "@app/_modules/form-fields/types/relation/single/combobox/combobox-single.component";
import {RelationRadioComponent} from "@app/_modules/form-fields/types/relation/single/radio/relation-radio.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldRelationComponent,
    FieldRelationReadonlyComponent,
    FieldRelationSingleComponent,
    FieldRelationMultipleComponent,
    ComboboxSingleComponent,
    RelationRadioComponent,
    ComboboxMultipleComponent,
    RelationCheckboxComponent,
  ],
  exports: [
    FormFieldRelationComponent
  ]
})
export class FormFieldRelationModule {}
