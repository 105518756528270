import { Component } from '@angular/core';
import { DialogService } from '@app/_services/dialog.service';
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cms-dialog',
  templateUrl: './cms-dialog.component.html',
  styleUrls: ['./cms-dialog.component.scss']
})
export class CmsDialogComponent {
  constructor(
    private dialog: DialogService
  ){}

  allert: string;
  prompt: string;
  waiting: string;
  resp: BehaviorSubject<string>;
  showAllert: boolean;
  showPrompt: boolean;
  showWaiting: boolean;

  ngOnInit(){
    this.dialog.modalAllertSubject.subscribe(
      allert=>{
        if (allert) {
          this.allert = allert;
          this.showAllert = true;
        }
      }
    )
    this.dialog.modalPromptSubject.subscribe(
      prompt=>{
        if (prompt) {
          this.prompt = prompt;
          this.showPrompt = true;
        }
      }
    )
    this.dialog.modalWaitingSubject.subscribe(
      waiting=>{
        if (waiting) {
          this.waiting = waiting;
          this.showWaiting = true;
        } else {
          this.showWaiting = false;
        }
      }
    )
  }

  respYes(){
    this.dialog.modalRespSubject.next(true);
    this.close();
  }

  respNo(){
    this.dialog.modalRespSubject.next(false);
    this.close();
  }

  close(){
    this.showPrompt = this.showAllert = false;
  }

}
