import {Component} from '@angular/core';
import {AuthenticationService, FormsService, ListsService, UsersService} from "@app/_services";
import {Subscription} from "rxjs";

@Component({
  selector: 'users-list',
  templateUrl: 'users-list.component.html',
  styleUrls: ['users-list.component.scss']
})
export class UsersListComponent {
  controlsSubscription: Subscription;

  constructor(private usersService: UsersService, private listsService: ListsService, private formsService: FormsService, private authService: AuthenticationService) {
  }

  get listId(): string {
    return 'users-list';
  }

  get users() {
    return this.listsService.result(this.listId).value?.data;
  }


  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(data => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
  }


  fetch() {
    let include = ['avatar', 'roles'];
    this.usersService.list({include: include.join(',')}).subscribe(res => {
      this.listsService.result(this.listId).next(res);
      this.authService.getCurrentUser();
    });
  }


  add() {
    this.formsService.createModel('user', null, this.listId);
  }

}
