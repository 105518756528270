<div class="feedback-form">
  <form [formGroup]="formGroup" (submit)="onSubmit()">
    <div class="form" *ngIf="!sent">
      <object-form [formGroup]="formGroup" [type]="objectType?.name" target="object"></object-form>
      <div class="row">
        <button [disabled]="loading" class="btn">Отправить</button>
      </div>
    </div>
    <p *ngIf="sent">Обращение успешно отправлено</p>
  </form>
</div>
