import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldBooleanComponent} from "@app/_modules/form-fields/types/boolean/form-field-boolean.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldBooleanReadonlyComponent} from "@app/_modules/form-fields/types/boolean/readonly/field-boolean-readonly.component";
import {FieldBooleanSingleComponent} from "@app/_modules/form-fields/types/boolean/single/field-boolean-single.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldBooleanComponent,
    FieldBooleanReadonlyComponent,
    FieldBooleanSingleComponent
  ],
  exports: [
    FormFieldBooleanComponent
  ]
})
export class FormFieldBooleanModule {}
