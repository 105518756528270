import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authService.isLoggedIn) return true;
      let extras = (state.url !== '/') ? {queryParams: {returnUrl: state.url}} : {};
      this.authService.popup('login');
      //this.router.navigate(['login'], extras).then();
      return false;
    }
}
