<div class="combobox" [class.active]="active">
  <div class="toggle" (click)="toggleActivity()">
    <div class="caption">{{caption}}</div>
    <div class="filter">
      <input type="text" #searchInput (click)="$event.stopPropagation()" (input)="onInput($event)" (keydown.escape)="active = false" />
    </div>
  </div>
  <div class="dropdown">
    <ul>
      <li *ngFor="let option of options; let i = index;">
        <div class="title" (click)="toggleValue(option)" [class.selected]="isSelected(option)">
          <p>{{option.caption || option.title || option.name}}</p>
          <p class="faded" *ngIf="option.subtitle">{{option.subtitle}}</p>
        </div>
      </li>
      <li class="empty-result" *ngIf="!options.length">
        Результатов не найдено, укажите менее строгий запрос
      </li>
    </ul>
  </div>
</div>
<div class="values">
  <div class="item" *ngFor="let val of selected">
    <div class="name">
      <p>{{val.caption || val.title || val.name}}</p>
      <p class="faded" *ngIf="val.subtitle">{{val.subtitle}}</p>
    </div>
    <button type="button" class="remove" (click)="removeValue(val)"></button>
  </div>
</div>

