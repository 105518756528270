import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthenticationService} from '@app/_services';

@Component({
  selector: 'auth-form-password-forget',
  templateUrl: 'auth-form-password-forget.component.html',
  styleUrls: ['auth-form-password-forget.component.scss']
})
export class AuthFormPasswordForgetComponent implements OnInit {
  form: FormGroup;
  loading = false;
  success = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.form.controls;
  }
  get email() {
    return this.f?.email?.value;
  }

  onSubmit() {
    if (this.form.invalid) return;
    this.loading = true;
    this.authenticationService.forget(this.email).subscribe(res => {
      this.success = true;
      this.loading = false;
    }, error => {
      this.setError(error.message);
      this.loading = false;
    });
  }

  setError(error: string) {
    let trans = {'The given data was invalid.': 'Указанный адрес почты не найден'};
    this.error = trans[error] || error;
  }

  login(){
    this.router.navigate(
      [ {outlets: {auth: 'login'}}], 
      {skipLocationChange: true}
    ).then();
  }
}
