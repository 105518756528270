<div class="documents list default">
  <div class="items">
    <div class="item" *ngFor="let item of items">
      <file-ico [type]="item.extension"></file-ico>
      <div class="value">
        <a [href]="item.links?.open" target="_blank">{{item.name}}</a>
        <div class="description">{{item.description}}</div>
      </div>
    </div>
  </div>
</div>
