import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService, ObjectsService} from "@app/_services";

@Component({
  selector: 'search-sections',
  templateUrl: 'search-sections.component.html',
  styleUrls: ['search-sections.component.scss']
})
export class SearchSectionsComponent {
  controlsSubscription?: Subscription;
  resultsSubscription?: Subscription;

  public items: any;

  constructor(private objectsService: ObjectsService, private listsService: ListsService) {
  }

  get listId() {
    return 'sections-list';
  }

  ngOnInit() {
    this.listsService.addFilters({types: ['page-section-header', 'page-section-html']}, this.listId);
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch(controls);
    });
    this.resultsSubscription = this.listsService.result(this.listId).subscribe(val => {
      this.items = val?.data || [];
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultsSubscription?.unsubscribe();
  }



  fetch(controls) {
    let include = ['groups.fields.value', 'pages'];
    let params = {page: controls.page || 0, filters: JSON.stringify(controls.filters), include: include.join(',')};
    this.objectsService.listObjects(params).subscribe(result => {
      this.listsService.result(this.listId).next(result);
    });
  }
}
