import {Component, Input} from '@angular/core';
import {FormsService} from "@app/_services";

@Component({
  selector: 'company',
  templateUrl: 'company.component.html',
  styleUrls: ['company.component.scss']
})
export class CompanyComponent {
  @Input() company: any;

  constructor(private formsService: FormsService) {
  }


  get logo() {
    return this.company?.logo?.data.links?.full;
  }
  get phones() {
    return this.company?.phones?.data.map(item => {
      return item.value;
    }).join('; ') || 'не указан';
  }
  get emails() {
    return this.company?.emails?.data.map(item => {
      return item.value;
    }).join('; ') || 'не указана';
  }
  get types() {
    return this.company?.types?.data.map(item => {
      return item.title;
    }).join(', ');
  }
  get permissions() {
    return this.company?.permissions;
  }
  get isViewable() {
    return this.permissions?.view || this.permissions?.anything;
  }
  get isEditable() {
    return this.permissions?.edit || this.permissions?.anything;
  }



  edit() {
    this.formsService.editModel('company', this.company.id, null, 'company-main-info');
  }


}
