import {Component, Input} from '@angular/core';
import {FormsService, ListsService, ObjectsService} from "@app/_services";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'cards-section-item',
  templateUrl: 'cards-section-item.component.html',
  styleUrls: ['cards-section-item.component.scss']
})
export class CardsSectionItemComponent {
  @Input() card: any;
  @Input() type: any;
  @Input() editMode = false;

  constructor(private objectsService: ObjectsService, private formsService: FormsService, private listsService: ListsService, private dialog: DialogService) {
  }


  ngOnInit() {
  }

  edit() {
    this.formsService.editObject(this.card.id);
  }

  delete() {
    this.dialog.confirm('Удалить эту карточку?').subscribe(
      resp=>{
        if (resp) this.objectsService.destroy(this.card.id).subscribe(res => {
          this.listsService.refresh();
        });
      }
    )
  }
}

