import {Component} from '@angular/core';

@Component({
  selector: 'homepage-intro',
  templateUrl: 'homepage-intro.component.html',
  styleUrls: ['homepage-intro.component.scss']
})
export class HomepageIntroComponent {
  constructor() {
  }

  ngOnInit() {
  }

}
