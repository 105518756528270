import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {AuthGuard} from "@app/_helpers";
import {BrowserModule} from "@angular/platform-browser";
import {PhonebookPageComponent} from "@app/_modules/phonebook/page/phonebook-page.component";
import {ReactiveFormsModule} from "@angular/forms";
import {PhonebookListComponent} from "@app/_modules/phonebook/list/phonebook-list.component";
import {PaginationModule} from "@app/_modules/pagination/pagination.module";
import {PhonebookListItemComponent} from "@app/_modules/phonebook/list/item/phonebook-list-item.component";

const routes = [
  {path: 'phonebook', component: PhonebookPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        PaginationModule,
    ],
  declarations: [
    PhonebookPageComponent,
    PhonebookListComponent,
    PhonebookListItemComponent
  ],
  exports: [
    RouterModule
  ]
})
export class PhonebookModule {}
