import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'auth-page',
  templateUrl: 'auth-page.component.html',
  styleUrls: ['auth-page.component.scss']
})
export class AuthPageComponent {
  constructor(private router: Router) {
  }

  get url() {
    return this.router.url.split('?')[0].split('(')[0].split('/').slice(1, 3).join('/');
  }


}
