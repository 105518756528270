import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageSidebarsComponent} from "@app/_modules/pages/sidebars/list/page-sidebars.component";
import {PageSidebarComponent} from "@app/_modules/pages/sidebars/item/page-sidebar.component";
import {AddSidebarComponent} from "@app/_modules/pages/sidebars/add/add-sidebar.component";

@NgModule({
  imports: [
    CommonModule
  ],
    declarations: [
        PageSidebarsComponent,
        PageSidebarComponent,
        AddSidebarComponent
    ],
  exports: [
    PageSidebarsComponent,
    AddSidebarComponent
  ]
})
export class PageSidebarsModule {}
