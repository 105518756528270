import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldIntegerComponent} from "@app/_modules/form-fields/types/integer/form-field-integer.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldIntegerReadonlyComponent} from "@app/_modules/form-fields/types/integer/readonly/field-integer-readonly.component";
import {FieldIntegerSingleComponent} from "@app/_modules/form-fields/types/integer/single/field-integer-single.component";
import {FieldIntegerMultipleComponent} from "@app/_modules/form-fields/types/integer/multiple/field-integer-multiple.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
    declarations: [
        FormFieldIntegerComponent,
        FieldIntegerReadonlyComponent,
        FieldIntegerSingleComponent,
        FieldIntegerMultipleComponent
    ],
  exports: [
    FormFieldIntegerComponent
  ]
})
export class FormFieldIntegerModule {}
