import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {FormFieldDocumentComponent} from "@app/_modules/form-fields/types/document/form-field-document.component";
import {FieldDocumentReadonlyComponent} from "@app/_modules/form-fields/types/document/readonly/field-document-readonly.component";
import {FieldDocumentSingleComponent} from "@app/_modules/form-fields/types/document/single/field-document-single.component";
import {FieldDocumentMultipleComponent} from "@app/_modules/form-fields/types/document/multiple/field-document-multiple.component";
import { WidjetModule } from '@app/_modules/widjet/widjet.module';
import {FieldDocumentValueComponent} from "@app/_modules/form-fields/types/document/value/field-document-value.component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidjetModule,
    SortablejsModule
  ],
  declarations: [
    FormFieldDocumentComponent,
    FieldDocumentReadonlyComponent,
    FieldDocumentSingleComponent,
    FieldDocumentMultipleComponent,
    FieldDocumentValueComponent,
    FieldDocumentValueComponent
  ],
  exports: [
    FormFieldDocumentComponent
  ]
})
export class FormFieldDocumentModule {}
