import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-datetime',
  templateUrl: 'form-field-datetime.component.html',
  styleUrls: ['form-field-datetime.component.scss']
})
export class FormFieldDatetimeComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {

  }


}
