<slider>
  <div header>
      <h2>{{title}}</h2>
  </div>
    <object-filters body [formGroup]="formGroup" [target]="target" [type]="type" [list]="list" (onFiltersUpdated)="this.total = $event"></object-filters>
  <div footer>
    <div class="left">Найдено: {{total}}</div>
    <div class="right">
      <button type="button" class="outline" (click)="clear()">Сбросить</button>
      <button type="button" class="" (click)="save()">Найти</button>
    </div>
  </div>
</slider>