import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'text-section',
  templateUrl: 'text-section.component.html',
  styleUrls: ['text-section.component.scss']
})
export class TextSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService) {
  }

  get content() {
    return this.objectsService.getValue(this.section, 'text-required');
  }

  ngOnInit() {

  }
}
