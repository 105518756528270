import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

@Component({
  selector: 'field-date-single',
  templateUrl: 'field-date-single.component.html',
  styleUrls: ['field-date-single.component.scss']
})
export class FieldDateSingleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
    setTimeout(() => {this.value = this.value || this.initialValue || null});
  }


  get initialValue() {
    return this.field?.value?.data[0];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value) {
    this.control?.setValue(value ? this.datePipe.transform(value, 'yyyy-MM-dd') : null);
  }

  get min() {
    return this.field?.range?.min || null;
  }
  get max() {
    return this.field?.range?.max || null;
  }



  onChange(event: MatDatepickerInputEvent<Date>) {
    this.value = event.value;
  }


}
