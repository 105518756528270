import { Component } from '@angular/core';


@Component({
  selector: 'right-content',
  templateUrl: './right-content.component.html',
  styleUrls: ['./right-content.component.scss']
})
export class RightContentComponent {

  
}
