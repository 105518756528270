import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SwiperModule} from "swiper/angular";

import {ObjectsModule} from "@app/_modules/objects/objects.module";
import { WidjetModule } from '@app/_modules/widjet/widjet.module';

import {PageSectionsComponent} from "@app/_modules/pages/sections/list/page-sections.component";
import {PageSectionComponent} from "@app/_modules/pages/sections/item/page-section.component";
import {HeaderSectionComponent} from "@app/_modules/pages/sections/types/basic/header/header-section.component";
import {TextSectionComponent} from "@app/_modules/pages/sections/types/basic/text/text-section.component";
import {AddSectionComponent} from "@app/_modules/pages/sections/add/add-section.component";
import {ImagesSectionComponent} from "@app/_modules/pages/sections/types/basic/images/images-section.component";
import {DocumentsSectionComponent} from "@app/_modules/pages/sections/types/basic/documents/documents-section.component";
import {ListSectionComponent} from "@app/_modules/pages/sections/types/basic/list/list-section.component";
import {HtmlSectionComponent} from "@app/_modules/pages/sections/types/basic/html/html-section.component";
import {VideoSectionComponent} from "@app/_modules/pages/sections/types/basic/video/video-section.component";
import {ButtonSectionComponent} from "@app/_modules/pages/sections/types/basic/button/button-section.component";
import {IframeSectionComponent} from "@app/_modules/pages/sections/types/basic/iframe/iframe-section.component";
import {FeedbackSectionComponent} from "@app/_modules/pages/sections/types/basic/feedback/feedback-section.component";
import {ContactSectionComponent} from "@app/_modules/pages/sections/types/basic/contact/contact-section.component";
import {MapsSectionComponent} from "@app/_modules/pages/sections/types/basic/maps/maps-section.component";
import {MemberSectionComponent} from "@app/_modules/pages/sections/types/basic/member/member-section.component";
import {DocumentSectionComponent} from "@app/_modules/pages/sections/types/basic/document/document-section.component";
import {PageMenuComponent} from "@app/_modules/pages/sections/menu/page-menu.component";
import {ImageGroupSectionComponent} from "@app/_modules/pages/sections/types/basic/image-group/image-group-section.component";
import {MemberSectionItemComponent} from "@app/_modules/pages/sections/types/basic/member/item/member-section-item.component";
import {MemberSectionListComponent} from "@app/_modules/pages/sections/types/basic/member/list/member-section-list.component";
import {CardsSectionModule} from "@app/_modules/pages/sections/types/basic/cards/cards-section.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ObjectsModule,
    SwiperModule,
    WidjetModule,
    CardsSectionModule
  ],
  declarations: [
    PageMenuComponent,
    PageSectionsComponent,
    PageSectionComponent,
    HeaderSectionComponent,
    TextSectionComponent,
    AddSectionComponent,
    ImagesSectionComponent,
    DocumentsSectionComponent,
    ListSectionComponent,
    HtmlSectionComponent,
    VideoSectionComponent,
    ButtonSectionComponent,
    IframeSectionComponent,
    FeedbackSectionComponent,
    ContactSectionComponent,
    MapsSectionComponent,
    MemberSectionComponent,
    ImageGroupSectionComponent,
    DocumentSectionComponent,
    MemberSectionItemComponent,
    MemberSectionListComponent
  ],
  exports: [
    PageSectionsComponent,
    ImagesSectionComponent
  ]
})
export class PageSectionsModule {
}
