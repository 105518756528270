import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-breadcrumbs',
  templateUrl: 'page-breadcrumbs.component.html',
  styleUrls: ['page-breadcrumbs.component.scss']
})
export class pageBreadcrumbsComponent {
  @Input() page: any;

  constructor() {
  }

  get parents() {
    return this.page?.parents?.data;
  }

  ngOnInit() {

  }

}
