import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {FormFieldsService} from "@app/_services/form-fields.service";
import {FormsService} from "@app/_services";

@Component({
  selector: 'form-frame',
  templateUrl: 'form-frame.component.html',
  styleUrls: ['form-frame.component.scss']
})
export class FormFrameComponent {
  @Input() formGroup!: FormGroup;
  @Input() form: any;
  @Input() readonly = false;
  @Input() params?: any = {};

  public formData: any;

  constructor(private formsService: FormsService, private fieldsService: FormFieldsService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setFormData();
  }

  get groups() {
    return this.formData?.groups?.data || this.formData?.groups || [];
  }


  setFormData() {
    let data = this.form?.data || this.form;
    let groups = (data?.groups?.data || data?.groups || data || []).map(group => {
      return this.fieldsService.prepareGroup(group, this.params);
    });
    this.formData = {groups: {data: groups}};
    this.applyDynamic();
  }


  applyDynamic() {
    this.formData.groups.data.map(group => {
      if (group.dynamic?.length) this.formGroup.valueChanges.subscribe(data => {
        group.dynamic.forEach(rule => {
          if (rule.field && this.control(rule.field)) {
            let value = this.normalizeValue(data[rule.field]);
            if ((value.indexOf(rule.value) !== -1) || !rule.value) this.applyRule(rule);
          }
        });
      });
    });
  }
  applyRule(rule: any) {
    this.formData.groups.data = this.formData.groups.data.map(group => {
      group.fields.data = group.fields.data.map(field => {
        if (rule.show && (rule.show.indexOf(field.name) !== -1)) field = this.show(field);
        if (rule.hide && (rule.hide.indexOf(field.name) !== -1)) field = this.hide(field);
        return field;
      });
      return group;
    });
  }
  show(field: any) {
    field.hidden = false;
    this.control(field.name)?.setValidators(field.required ? Validators.required : null);
    this.control(field.name)?.updateValueAndValidity({emitEvent: false});
    return field;
  }
  hide(field: any) {
    field.hidden = true;
    this.control(field.name)?.setValidators(null);
    this.control(field.name)?.updateValueAndValidity({emitEvent: false});
    return field;
  }
  control(name: any) {
    return this.formGroup.controls[name];
  }


  normalizeValue(value) {
    return [value?.id, value?.name, value];
  }

}
