import {Component, Input} from '@angular/core';
import {FormsService, ListsService, ObjectsService} from "@app/_services";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'entry-operation',
  templateUrl: 'entry-operation.component.html',
  styleUrls: ['entry-operation.component.scss']
})
export class EntryOperationComponent {
  @Input() entry: any;
  @Input() operation: any;
  @Input() editMode: boolean;

  constructor(private formsService: FormsService, private listsService: ListsService,
              private objectsService: ObjectsService, private datePipe: DatePipe) {
  }

  get type() {
    return this.getValue('operation-type');
  }
  get orderName() {
    return this.getValue('order-name');
  }
  get orderDate() {
    let v = this.getValue('order-date');
    return v ? this.datePipe.transform(v, 'dd.MM.yyyy') : null;
  }
  get activeSince() {
    let v = this.getValue('active-since');
    return v ? this.datePipe.transform(v, 'dd.MM.yyyy') : null;
  }
  get activeTill() {
    let v = this.getValue('active-till');
    return v ? this.datePipe.transform(v, 'dd.MM.yyyy') : null;
  }
  get developer() {
    return this.getValue('developer');
  }
  get orderDocument() {
    return this.getValue('order-document');
  }
  get listings() {
    return this.getValue('listings');
  }

  ngOnInit() {

  }

  getValue(name) {
    return this.objectsService.getValue(this.operation, name);
  }


  edit() {
    this.formsService.editModel('entryOperation', this.operation.id, {extraProps: {entry: this.entry.id}}, this.entry.id);
  }

  delete() {
    if (confirm('r u sure?')) this.objectsService.destroy(this.operation.id).subscribe(res => {
      this.listsService.refresh(this.entry.id);
    });
  }

}
