import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {PublicationsService} from "@app/_services/publications.service";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'publications-list-item',
  templateUrl: 'publications-list-item.component.html',
  styleUrls: ['publications-list-item.component.scss']
})
export class PublicationsListItemComponent {
  @Input() publication: any;
  @Input() editMode: boolean;
  currentPoster:any;

  constructor(private publicationsService: PublicationsService, private formsService: FormsService, private listsService: ListsService, private dialog: DialogService) {
  }

  get listId() {
    return 'publications-list';
  }

  get posters() {
    return this.publication.posters?.data;
  }

  get isPublished(){
    return this.publication.isPublished
  }

  ngOnInit() {
    if (this.publication.posters) this.currentPoster = this.posters[0];
  }

  edit() {
    this.formsService.editModel('publication', this.publication.id, null, this.listId);
  }

  delete() {
    this.dialog.confirm('Удалить эту новость?').subscribe(
      resp=>{
        if (resp) {
          this.publicationsService.delete(this.publication.id).subscribe(res => {
            this.listsService.refresh(this.listId, true);
          });
        }
      }
    )
  }



}
