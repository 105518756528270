import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'slider-menu',
  templateUrl: './slider-menu.component.html',
  styleUrls: ['./slider-menu.component.scss']
})
export class SliderMenuComponent {

  constructor(
    private router: Router,
    public pagesService: PagesService
    ) {
  }

  get rootPages() {
    return this.pagesService.rootPages;
  }

  get rootPage() {
    return this.pagesService.rootPage;
  }

  get isRusPage(){
    return this.rootPage.slug ===""
  }

}
