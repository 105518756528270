<div class="buttons" *ngIf="editMode"><a class="btn default" routerLink="/administrate/committee">Перейти к управлению</a></div>
<div class="title card">Секретариат на базе ФАУ «ФЦС» <span class="label">Направления стандартизации</span></div>
<div class="columns"> 
  <div class="column" *ngFor="let group of groups">
    <div class="head card">{{group.title}}</div>
    <div class="cells">
      <div class="cell" *ngFor="let subcommittee of getChildren(group)">
        <div class="card">
          <div class="advisory" [class.active]="isActive(advisory)" *ngFor="let advisory of getAdvisoryWithChildren(subcommittee)">
            <div class="caption" (click)="toggleActivity(advisory)">{{advisory.caption}}</div>
            <div class="info">
              <div class="in">
                <p *ngIf="advisory.directorName">Руководитель: {{advisory.directorName}}</p>
                <p *ngIf="advisory.secretaryName">Секретарь: {{advisory.secretaryName}}</p>
                <p *ngIf="advisory.phone" class="icon phone">{{advisory.phone}}</p>
                <p *ngIf="advisory.email" class="icon email">{{advisory.email}}</p>
                <p *ngIf="advisory.document?.data?.links?.open">
                  <a href="{{advisory.document.data.links.open}}" target="_blank">Списочный состав</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
