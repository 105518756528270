import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {GalleryService} from "@app/_services/gallery.service";

@Component({
  selector: 'images-section',
  templateUrl: 'images-section.component.html',
  styleUrls: ['images-section.component.scss']
})
export class ImagesSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService, private galleryService: GalleryService) {
  }


  ngOnInit() {
  }

  get items() {
    return this.objectsService.getValue(this.section, 'images-required');
  }
  get imageType() {
    let type = this.objectsService.getValue(this.section, 'image-type');
    return type.name;
  }


  show(i:number): void {
    this.galleryService.show(this.items, i);
  }
}
