import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'filter-field-float',
  templateUrl: 'filter-field-float.component.html',
  styleUrls: ['filter-field-float.component.scss']
})
export class FilterFieldFloatComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;
  public controlGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.controlGroup = new FormGroup({gt: new FormControl(this.initialValue?.gt || null), lt: new FormControl(this.initialValue?.lt || null)});
    this.controlGroup.valueChanges.pipe(debounceTime(700)).subscribe(res => {this.value = res});
  }


  get initialValue() {
    return this.field.value?.data[0] || {};
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  set value(val: any) {
    if (val.gt && (val.gt < this.min)) val.gt = this.min;
    if (val.lt && (val.lt > this.max)) val.lt = this.max;
    this.control?.setValue(val);
  }

  get min() {
    return this.field?.range?.min || 0;
  }
  get max() {
    return this.field?.range?.max || 0;
  }

}
