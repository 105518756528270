import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-string-single',
  templateUrl: 'field-string-single.component.html',
  styleUrls: ['field-string-single.component.scss']
})
export class FieldStringSingleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {this.value = this.initialValue || this.value || null});
  }


  get initialValue() {
    return this.field.value?.data[0];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value) {
    this.control?.setValue(value);
  }
  get maxLength() {
    return this?.field?.maxLength || 255;
  }

}
