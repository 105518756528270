<div class="administration-page" dir="ltr">
  <div>
    <h2>Панель управления</h2>
  </div>

  <div class="tabs default">
    <div *ngFor="let tab of tabs" >
      <button type="button"[class.active]="tab.active" *ngIf="showTab(tab)" routerLink="/administrate/{{tab.name}}">{{tab.title}}</button>
    </div>    
  </div>
  <div [ngSwitch]="tab.name">
    <administrate-company *ngSwitchCase="'company'" [companyId]="'main'"></administrate-company>
    <administrate-committee *ngSwitchCase="'committee'"></administrate-committee>
    <administrate-site-pages *ngSwitchCase="'site-pages'"></administrate-site-pages>
    <administrate-licence *ngSwitchCase="'licence'"></administrate-licence>
    <administrate-users *ngSwitchCase="'users'"></administrate-users>
    <p *ngSwitchDefault>Страница не найдена</p>
  </div>
</div>
