<div class="header layout-corral">
  <div class="header-top-menu">
    <div class="header-top-menu-ref">
      <ico ico="frame_24" color="white" [size]="24"></ico>
      Версия для слабовидящих
    </div>
    <div class="header-top-menu-ref">
      <ico ico="search_24" color="white" [size]="24"></ico>
      Поиск
    </div>
    <div class="header-top-menu-ref" (click)="login()" *ngIf="!isLoggedIn">
      <ico ico="login_24" color="white" [size]="24"></ico>
      Вход
    </div>
      <locale></locale>
  </div>
  <div  class="header-contact">
    <div  class="header-contact-left">
      <a routerLink="/"><img src="assets/images/logo_gbu.png"></a>
      
      <div>
        <span>Государственное бюджетное<br>учреждение города Москвы</span><br>
        <strong>«Жилищник Таганского района»</strong>
      </div>
    </div>
    <div  class="header-contact-right">
      <div>
        <ico ico="watch_24" color="var(--prime)" [size]="24"></ico>
        <span>пн-чт: 8:00–17:00, пт: 8:00–15:45; перерыв на обед: 12:00–12:45</span>
      </div>
      <div>
        <ico ico="location_24" color="var(--prime)" [size]="24"></ico>
        <span>109147, г. Москва, Воронцовская ул., д. 21б</span>        
      </div>
      <div>
        <ico ico="mail_24" color="var(--prime)" [size]="24"></ico>
        <span>          
          gbu-taganka@mail.ru
        </span>
        <ico ico="phone_24" color="var(--prime)" [size]="24"></ico>
        <span>8 (495) 230-57-87</span>
        <ico ico="telegram_24" color="var(--prime)" [size]="24"></ico>
      </div>
    </div>
  </div>

  <pages-menu class="lvl-0"></pages-menu>
  
</div>
