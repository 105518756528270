<div class="title">{{type?.title || 'Вид не указан'}}</div>
<table class="default">
  <tr>
    <td class="caption">Приказ</td>
    <td class="value">
      <a *ngIf="orderDocument?.links?.open" class="document" href="{{orderDocument?.links?.open}}" target="_blank">{{orderName}} от {{orderDate}}</a>
      <span *ngIf="!orderDocument?.links?.open">{{orderName}} от {{orderDate}}</span>
    </td>
  </tr>
  <tr>
    <td class="caption">Вхождение в перечень ПП</td>
    <td class="value" *ngIf="listings?.length"><a class="document" href="#" target="_blank" *ngFor="let listing of listings">{{listing.title}}</a></td>
    <td class="value" *ngIf="!listings?.length">—</td>
  </tr>
  <tr><td class="caption">Действует с</td><td class="value">{{activeSince || '—'}}</td></tr>
  <tr><td class="caption">Действует по</td><td class="value">{{activeTill || '—'}}</td></tr>
  <tr><td class="caption">Разработчик</td><td class="value">{{developer || '—'}}</td></tr>
</table>
<div class="inline-menu default" *ngIf="editMode">
  <button type="button" class="edit" title="Редактировать" (click)="edit()"></button>
  <button type="button" class="delete" title="Удалить" (click)="delete()"></button>
</div>
