<a class="right-card" routerLink="elektronnoe-obrashhenie">
    <ico ico="web_req_48" [size]="48"></ico>
    <span>Электронное обращение</span>
</a>
<a class="right-card" href="https://www.mos.ru/pgu/ru/app/mosenergo/counters/#step_1" target="_blank" >
    <ico ico="lightbulb_48" [size]="48"></ico>
    <span>Прием показаний и оплата электроэнергии</span>
</a>
<a class="right-card" href="https://www.mos.ru/services/pokazaniya-vodi-i-tepla/" target="_blank" >
    <ico ico="water_48" [size]="48"></ico>
    <span>Прием показаний приборов учета воды</span>
</a>
<a class="right-card" href="https://www.mos.ru/pgu/ru/services/link/2170/" target="_blank">
    <ico ico="get_n_pay_48" [size]="48"></ico>
    <span>Получить и оплатить ЕПД</span>
</a>
<a class="right-baner" href="https://ag.mos.ru/home" target="_blank">
    <img src="assets/images/adv_00.png">
</a>
<a class="right-baner" href="https://crowd.mos.ru/propose" target="_blank">
    <img src="assets/images/adv_01.png">
</a>

 