import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldFloatComponent} from "@app/_modules/form-fields/types/float/form-field-float.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldFloatReadonlyComponent} from "@app/_modules/form-fields/types/float/readonly/field-float-readonly.component";
import {FieldFloatSingleComponent} from "@app/_modules/form-fields/types/float/single/field-float-single.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldFloatComponent,
    FieldFloatReadonlyComponent,
    FieldFloatSingleComponent
  ],
  exports: [
    FormFieldFloatComponent
  ]
})
export class FormFieldFloatModule {}
