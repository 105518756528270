import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";

@Component({
  selector: 'add-sidebar',
  templateUrl: 'add-sidebar.component.html',
  styleUrls: ['add-sidebar.component.scss']
})
export class AddSidebarComponent {
  @Input() page: any;
  @Input() ord: number;
  public ddVisible = false;
  public types = <any>[];

  constructor(private formsService: FormsService, private objectsService: ObjectsService) {
  }

  ngOnInit() {
  }


  fetchTypes() {
    this.objectsService.fetchType('page-sidebar', {include: 'children'}).subscribe(res => {
      this.types = res.data?.children?.data;
    });
  }

  add(type: string) {
    let attach = {modelType: 'page', modelId: this.page.id, group: 'sidebars', ord: this.ord};
    this.formsService.createObject(type, {extraProps: {attach: attach}});
    this.ddVisible = false;
  }

  toggle() {
    if (!this.types?.length) this.fetchTypes();
    this.ddVisible = !this.ddVisible;
  }

}
