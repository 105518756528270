import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthenticationService} from '@app/_services';
import {Subscription} from "rxjs";

@Component({
  selector: 'auth-form-signup',
  templateUrl: 'auth-form-signup.component.html',
  styleUrls: ['auth-form-signup.component.scss']
})
export class AuthFormSignupComponent implements OnInit {
  form: FormGroup;
  loading = false;
  error = '';
  asyncErrors: any = {};
  subscription: Subscription;

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      phone: [''],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
      //agree: [null, Validators.requiredTrue],
      //read: [null, Validators.requiredTrue]
    });
  }

  ngOnDestroy() {
  }

  get f() {
    return this.form.controls;
  }
  get email() {
    return this.f.email;
  }
  get name() {
    return this.f.name;
  }
  get password() {
    return this.f.password;
  }
  get confirmation() {
    return this.f.passwordConfirmation;
  }
  get agree() {
    return this.f.agree;
  }
  get read() {
    return this.f.read;
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.loading = true;
    this.authService.signup(this.form.value).subscribe(res => {
      this.authService.login(this.email.value, this.password.value).subscribe(res => {
        this.authService.token = res;
      });
    }, error => {
      this.setError(error);
      this.loading = false;
    });
  }

  setError(error: any) {
    this.asyncErrors = error.errors;
    for (let prop in error.errors) {
      if (error.errors.hasOwnProperty(prop)) this.f[prop].setErrors({custom: true});
    }
    let trans = {'The given data was invalid.': 'Проверьте правильность заполнения формы'};
    this.error = trans[error.message] || error.message;
  }

  login(){
    this.router.navigate(
      [ {outlets: {auth: 'login'}}], 
      {skipLocationChange: true}
    ).then();
  }
}
