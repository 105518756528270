import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import {RegistryEntriesService} from "@app/_services/registry-entries.service";

@Component({
  selector: 'entry-operations',
  templateUrl: 'entry-operations.component.html',
  styleUrls: ['entry-operations.component.scss']
})
export class EntryOperationsComponent {
  @Input() entry: any;
  @Input() editMode: boolean;

  subscription: Subscription;

  constructor(private entriesService: RegistryEntriesService, private listsService: ListsService) {
  }

  get listId() {
    return this.entry.id;
  }

  get operations() {
    return this.entry.operations?.data;
  }

  ngOnInit() {
    this.subscription = this.listsService.controls(this.listId).subscribe(res => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  fetch() {
    let include = ['operations.groups.fields.value'];
    this.entriesService.show(this.entry.id, {include: include.join(',')}).subscribe(res => {
      this.entry = res.data;
    });
  }


}
