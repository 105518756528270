<div class="body" *ngIf="!success">
  <form [formGroup]="form" (submit)="onSubmit()">
    <div class="field" [class.invalid]="email.invalid && email.touched">
      <label for="username">Электронная почта*</label>
      <input id="username" formControlName="email" type="email" />
    </div>
    <div *ngIf="error" class="error">{{error}}</div>
    <div class="description">Если указанный адрес зарегистрирован, то на него будет выслан новый пароль</div>
    <div class="bottom">
      <button type="button" class="btn secondary" (click)="login()">Авторизоваться</button>
      <button *ngIf="!success" [disabled]="loading" type="submit" class="btn">Запросить</button>
    </div>
  </form>
</div>
<div class="body success" *ngIf="success">
  <h3>Письмо с инструкциями было отправлено на указанный адрес.</h3>
  <p>Пожалуйста, проверьте почту и выполните указанные в письме инструкции.</p>
</div>

