import {Component} from '@angular/core';

@Component({
  selector: 'homepage-promo',
  templateUrl: 'homepage-promo.component.html',
  styleUrls: ['homepage-promo.component.scss']
})
export class HomepagePromoComponent {
  constructor() {
  }

  ngOnInit() {
  }

}
