<table class="default">
  <tr *ngFor="let field of fields" [ngSwitch]="field.type">
    <td class="caption">{{field.title}}</td>
    <td *ngSwitchCase="'document'">
      <div class="documents list default">
        <div class="items">
          <div class="item"><a [href]="field.value?.links?.open" target="_blank">{{field.value?.name}}</a></div>
        </div>
      </div>
    </td>
    <td *ngSwitchDefault class="value">{{getValue(field)}}</td>
  </tr>
</table>
