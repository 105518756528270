import { Component } from '@angular/core';
import { PagesService } from '@app/_services/pages.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent {
  private subscription:Subscription 
  public currentPage:any;
  public pagePicture:any;



  constructor(
    private pagesService: PagesService,
    public authService: AuthenticationService,
    private router: Router
  ){}

  ngOnInit(){
    this.subscription = this.pagesService.currentPageSubject.subscribe(
      resp=> {
        this.currentPage = resp;
        if (this.currentPage) {
          if(this.currentPage.picture){
            this.pagePicture = this.currentPage.picture.data.links.open
          }else{
            let parents = this.currentPage.parents?.data
            let content = parents?.map(page=>page.picture).filter(page=>page)
            this.pagePicture = content.pop()?.data.links.open
          }
        }else{this.pagePicture = null;}
      }
    )
  }
  ngOnDestoy(){
    this.subscription.unsubscribe();
  }

  get name(){
    let name:string
    if(this.currentPage){
      name = this.currentPage?.h1 || this.currentPage?.name;
      let parents = this.currentPage?.parents?.data
        if (parents&&parents[2]) name = parents[2].h1|| parents[2].name
    }
    return name
  }



  get mainPage(){
    return this.currentPage?.parents?.data.length === 0;
  }

  get editable(){
    return this.currentPage&&(this.authService.privileges?.admin|| this.authService.privileges?.editor);
  }

  get editMode(){
    return this.pagesService.editMode;
  }

  toggleEditMode(){
    this.pagesService.editMode = !this.pagesService.editMode;
  }

  get isRootPage(){
    return !this.pagesService.currentPage?.parents?.data.length && this.pagesService.currentPage
  }

  get since(){
    return this.currentPage?.link === '/'
  }

  get parents() {
    return this.currentPage?.parents?.data;
  }

}
