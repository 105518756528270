<div class="item">
    <div class="doc-item-header">
      <file-ico [type]="asset.extension"></file-ico>
      <div class="info">
        <div class="name"><a href="{{asset.links?.open}}" target="_blank">{{asset.name}}</a></div>
        <div class="description">{{asset.description}}</div>
      </div>
      <drop-down [target]="form" [order]="edit" ico="edit_24" [angle]="[0,0]" (toggle)="default()"></drop-down>
      <ico ico="delete_24" (click)="del()" [size]="[24,24]"></ico>
    </div>

    <div [formGroup]="formGroup" #form class="doc-item-form">
      <div class="form">
        <label for="name" class="caption">Название документа</label>
        <input id="name" type="text" name="name" formControlName="name" />
        <label for="description" class="caption">Описание документа</label>
        <input id="description" type="text" name="description" formControlName="description"  />
        <div class="doc-item-form-bar">
          <div class="close" (click)="close()">
            <ico></ico>
          </div>
          <div class="update" (click)="update()">           
            <ico ico="done_24"></ico>
          </div>  
        </div>
      </div>
      <div class="fader" (click)="close()"></div>
    </div>
</div>
