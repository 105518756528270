<div class="radio" [formGroup]="formGroup">
  <label><input type="radio" [formControlName]="field.name" value=""> Не важно</label>
  <label><input type="radio" [formControlName]="field.name" value="yes"> Есть</label>
  <label><input type="radio" [formControlName]="field.name" value="no"> Нет</label>
  <!--select [formControlName]="field.name">
    <option value="">Не важно</option>
    <option value="yes">Присутствует</option>
    <option value="no">Отсутствует</option>
  </select-->
</div>
