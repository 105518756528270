import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'relation-radio',
  templateUrl: 'relation-radio.component.html',
  styleUrls: ['relation-radio.component.scss']
})
export class RelationRadioComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;

  ngOnInit(): void {
    setTimeout(() => {this.value = this.initialValue});
  }


  get initialValue() {
    return this.value || this.optionByValue(this.field.value?.data[0]) || null;
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get options() {
    return this.field.options?.data || [];
  }
  get value() {
    return this.control?.value;
  }
  set value(value: any) {
    this.control?.setValue(value);
  }


  optionByValue(value: any) {
    return this.field.options?.data.filter(option => {return option.id === value?.id})[0];
  }

}
