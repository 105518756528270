import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class AdvisoryMembersService {

  constructor(private http: HttpClient) {

  }


  show(id: string, params?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/advisory-members/${id}`, {params: params});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/advisory-members/${id}`);
  }

}
