<div class="top-panel pt-40">
  <h2>Личный кабинет</h2>
  <div class="profile" *ngIf="user">
    <div class="row">
      <div class="left">
        <div class="user">
          <div class="avatar">
            <img *ngIf="avatar" [src]="avatar?.links?.thumb" />
            <span class="initials" *ngIf="!avatar">{{user.initials}}</span>
          </div>
          <div class="data">
            <div *ngIf="user?.name" class="name">{{user.name}}</div>
            <div *ngIf="user?.email" class="email">{{user.email}}</div>
            <div *ngIf="user?.phone" class="phone">{{user.phone}}</div>
          </div>
        </div>
      </div>
      <div class="right"><button type="button" class="btn icon edit" (click)="editProfile()"></button></div>
    </div>
    <!--div class="sub"><span class="link" (click)="changePassword()">Изменить пароль</span></div-->
  </div>
</div>
