import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-time',
  templateUrl: 'form-field-time.component.html',
  styleUrls: ['form-field-time.component.scss']
})
export class FormFieldTimeComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

}
