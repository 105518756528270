import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {AdvisoriesTreeComponent} from "@app/_modules/advisories/tree/advisories-tree.component";
import {AdvisoriesTreeAdvisoryComponent} from "@app/_modules/advisories/tree/advisory/advisories-tree-advisory.component";
import {AdvisoriesTreeCompanyComponent} from "@app/_modules/advisories/tree/company/advisories-tree-company.component";
import {AdvisoriesTreeMemberComponent} from "@app/_modules/advisories/tree/member/advisories-tree-member.component";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AdvisoriesTreeComponent,
    AdvisoriesTreeAdvisoryComponent,
    AdvisoriesTreeCompanyComponent,
    AdvisoriesTreeMemberComponent
  ],
  exports: [
    AdvisoriesTreeComponent,
    AdvisoriesTreeCompanyComponent
  ]
})
export class AdvisoriesModule {}
