import {Component} from '@angular/core';
import SwiperCore, {Pagination, Autoplay, SwiperOptions} from 'swiper';
SwiperCore.use([Pagination, Autoplay]);
import {PublicationsService} from "@app/_services/publications.service";

@Component({
  selector: 'homepage-portfolio',
  templateUrl: 'homepage-portfolio.component.html',
  styleUrls: ['homepage-portfolio.component.scss'],
})
export class HomepagePortfolioComponent {
  public config: SwiperOptions = {
    lazy: true,
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {el: 'homepage-portfolio .bullets', clickable: true},
  };

  public items: any[] = [];

  constructor(
    private publicationsService: PublicationsService,
  ) {
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.publicationsService.list({type: 'portfolio', limit: 5}).subscribe(result => {
      this.items = result.data;
    });
  }
}
