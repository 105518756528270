import {Component, Input} from '@angular/core';

@Component({
  selector: 'content-page',
  templateUrl: 'content-page.component.html',
  styleUrls: ['../../page.component.scss', 'content-page.component.scss']
})
export class ContentPageComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  constructor() {
  }

  ngOnInit() {
  }


  get sections() {
    return this.page?.sections?.data;
  }

  get sidebars() {
    return this.page?.sidebars?.data;
  }
}
