import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {FormsService} from "@app/_services/forms.service";

@Injectable({providedIn: 'root'})
export class FiltersService {

  constructor(private router: Router, private formsService: FormsService) {
  }

  slider(target: string, type: string, params?: any, list?: string) {
    let commands = ['filters', target, type];
    if (list) commands.push(list);
    this.router.navigate([{outlets: {slider: commands}}], {queryParams: this.formsService.makeQueryParams(params), skipLocationChange: true}).then();
  }


}
