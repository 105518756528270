import {Component, Input} from '@angular/core';
import {FormsService, ListsService, ObjectsService} from "@app/_services";
import {ApplicationsService} from "@app/_services/applications.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'applications-list-item',
  templateUrl: 'applications-list-item.component.html',
  styleUrls: ['applications-list-item.component.scss']
})
export class ApplicationsListItemComponent {
  @Input() application: any;
  public active = false;

  subscription: Subscription;

  constructor(private applicationsService: ApplicationsService, private formsService: FormsService, private objectsService: ObjectsService, private listsService: ListsService) {
  }

  get status() {
    return this.application?.status;
  }
  get submitter() {
    return this.application?.submitter?.data;
  }
  get product() {
    return this.application?.product?.data;
  }
  get conclusion() {
    return this.application?.conclusions?.data[0];
  }
  get expert() {
    return this.conclusion?.author?.data;
  }
  get properties() {
    return this.application?.properties?.data;
  }
  get documents() {
    return this.objectsService.getValue(this.properties, 'documents');
  }

  get permissions() {
    return this.application?.permissions;
  }

  ngOnInit() {
    this.subscription = this.listsService.controls(this.application.id).subscribe(controls => {
      if (this.active) this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  fetch() {
    let include = ['submitter', 'product', 'permissions', 'properties.groups.fields.value', 'conclusions.author'];
    this.applicationsService.show(this.application.id, {include: include.join(',')}).subscribe(res => {
      this.application = res.data;
    });
  }


  reply() {
    this.formsService.createModel('applicationConclusion', {extraProps: {application: this.application.id}}, this.application.id);
  }

  toggle() {
    this.active = !this.active;
    if (this.active) this.listsService.refresh(this.application.id);
  }

}
