import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {AuthGuard} from "@app/_helpers";
import {ReactiveFormsModule} from "@angular/forms";
import {PaginationModule} from "@app/_modules/pagination/pagination.module";
import {ApplicationsPageComponent} from "@app/_modules/applications/page/applications-page.component";
import {ApplicationsListComponent} from "@app/_modules/applications/list/applications-list.component";
import {ApplicationsListItemComponent} from "@app/_modules/applications/list/item/applications-list-item.component";

const routes = [
  {path: 'applications', component: ApplicationsPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    PaginationModule,
  ],
  declarations: [
    ApplicationsPageComponent,
    ApplicationsListComponent,
    ApplicationsListItemComponent
  ],
  exports: [
    RouterModule
  ]
})
export class ApplicationsModule {}
