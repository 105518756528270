import {Component} from '@angular/core';
import {AdvisoriesService} from "@app/_services/advisories.service";
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";

@Component({
  selector: 'advisories-tree',
  templateUrl: 'advisories-tree.component.html',
  styleUrls: ['advisories-tree.component.scss']
})
export class AdvisoriesTreeComponent {
  public advisories: any[];
  public controlsSubscription?: Subscription;

  constructor(private advisoriesService: AdvisoriesService, private listsService: ListsService) {
  }

  ngOnInit() {
    this.controlsSubscription = this.listsService.controls().subscribe(val => {
      this.fetch();
    });
  }

  fetch() {
    this.advisoriesService.show('main', {include: 'logo,permissions'}).subscribe(res => {
      this.advisories = [res.data];
    });
  }


}
