<div class="add" (mouseleave)="hideDD()" dir="ltr">
  <div class="toggle prime" [class.active]="ddVisible" (click)="toggleDD()">
    <div class="two-lines"></div>
    <ico ico="add_circle_24"></ico>
    <div class="two-lines"></div>
    <!-- <a [class.active]="ddVisible">Добавить элемент</a>
    <drop-down ico="arrow_drop_down_24" [target]="menu" [visible]="ddVisible"></drop-down> -->
  </div>
  <div class="dropdown" *ngIf="ddVisible">
    <div class="menu" *ngIf="types?.length">
      <div *ngFor="let type of types; let i=index" class="type">
        <div class="row" *ngIf="type?.children?.data?.length > 0; else addItem" (click)="toggleSub(i)">
          <div class="title">{{type.title}}</div>
          <drop-down ico="expand_less_24" [target]="submenu" [visible]="sub[i]" [angle] = "[180,0]"></drop-down>
        </div>
        <div #submenu>
          <div class="sub-row" *ngFor="let childrenType of type?.children?.data" (click)="add(childrenType.name)" [innerText]="childrenType.title"></div>
        </div>
        <ng-template #addItem>
          <div class="row" (click)="add(type.name)"><div class="title" [innerText]="type.title"></div></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
