import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'filter-field-datetime',
  templateUrl: 'filter-field-datetime.component.html',
  styleUrls: ['filter-field-datetime.component.scss']
})
export class FilterFieldDatetimeComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;
  public controlGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.controlGroup = new FormGroup({gt: new FormControl(), lt: new FormControl()});
    this.formGroup.addControl(this.field.name, this.controlGroup, {emitEvent: false});
  }

}
