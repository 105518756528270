import {Component, Input} from '@angular/core';
import {AdvisoryMembersService} from "@app/_services/advisory-members.service";
import {ListsService} from "@app/_services";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'advisories-tree-member',
  templateUrl: 'advisories-tree-member.component.html',
  styleUrls: ['../advisories-tree.component.scss', 'advisories-tree-member.component.scss']
})
export class AdvisoriesTreeMemberComponent {
  @Input() advisoryMember: any;
  @Input() parent: any;

  constructor(private advisoryMembersService: AdvisoryMembersService, private listsService: ListsService, private dialog: DialogService) {
  }


  get member() {
    return this.advisoryMember?.companyMember?.data;
  }
  get user() {
    return this.member?.user?.data;
  }
  get avatar() {
    return this.user.avatar?.data.links?.full;
  }
  get isVoter() {
    return this.advisoryMember?.rank.name === 'voter';
  }
  get parentPermissions() {
    return this.parent?.permissions;
  }
  get isEditable() {
    return this.parentPermissions?.edit || this.parentPermissions?.anything;
  }


  ngOnInit() {

  }


  delete() {
    this.dialog.confirm('Исключить сотрудника из участников комитета?').subscribe(
      resp=>{
        if (resp) {
          this.advisoryMembersService.delete(this.advisoryMember.id).subscribe(res => {
            this.listsService.refresh(this.parent?.id);
          });
        }
      }
    )
  }
}
