import {Component, Input} from '@angular/core';
import {FormsService} from "@app/_services";

@Component({
  selector: 'image-group-section',
  templateUrl: 'image-group-section.component.html',
  styleUrls: ['image-group-section.component.scss']
})
export class ImageGroupSectionComponent {
  @Input() section: any;
  @Input() editMode: boolean;
  @Input() page: any;

  constructor(private formsService: FormsService) {
  }

  get title() {
    return this.section?.groups?.data[0]?.fields?.data[0]?.value?.data[0];
  }

  get members() {
    return this.section?.objectables?.data;
  }

  get ord() {
    return this.members.length + 1;
  }

  get modelId() {
    return this.section.id;
  }

  get modelType() {
    return 'object';
  }


  add() {
    let attach = {modelType: 'object',  group: 'sections', modelId: this.section.id, ord: this.ord};
    this.formsService.createObject('page-section-list-image', {extraProps: {attach: attach}});
  }

  getLink(group: any) {
    return group?.fields?.data[1]?.value?.data[0];
  }

  getImg(group: any) {
    return group?.fields?.data[0]?.value?.data[0]?.links?.full;
  }
}
