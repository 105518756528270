import {Component} from '@angular/core';
import {LicenceService} from "@app/_services/licence.service";

@Component({
  selector: 'administrate-licence',
  templateUrl: 'administrate-licence.component.html',
  styleUrls: ['administrate-licence.component.scss']
})
export class AdministrateLicenceComponent {
  constructor(private licenceService: LicenceService) {
  }

  get number() {
    return this.licenceService.number;
  }
  get licence() {
    return this.licenceService.licence;
  }
  get product() {
    return this.licence?.product?.data;
  }
  get options() {
    return this.licence?.options?.data;
  }

  get classState(){
    return {
      ok: this.licence.state.name == 'active',
      warn: this.licence.state.name == 'expired'
    }
  }

}
