import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";

@Component({
  selector: 'cards-section',
  templateUrl: 'cards-section.component.html',
  styleUrls: ['cards-section.component.scss']
})
export class CardsSectionComponent {
  @Input() section: any;
  @Input() editMode = false;

  constructor(private objectsService: ObjectsService, private formsService: FormsService) {
  }

  get type() {
    return this.objectsService.getValue(this.section, 'cards-section-type');
  }
  get cards() {
    return this.section?.objects?.data;
  }

  ngOnInit() {
  }


  add() {
    let attach = {modelType: 'object', modelId: this.section.id, group: 'default'};
    this.formsService.createObject(this.type.name, {extraProps: {attach: attach}});
  }

}

