import {NgModule} from '@angular/core';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { WidjetModule } from '@app/_modules/widjet/widjet.module';

import {PagesModule} from "@app/_modules/pages/pages.module";
import {HeaderComponent} from "@app/_modules/layout/header/header.component";
import {FooterComponent} from "@app/_modules/layout/footer/footer.component";
import {GridComponent} from "@app/_modules/layout/grid/grid.component";
import {SettingSiteComponent} from "@app/_modules/layout/setting-site/setting-site.component";
import {HeaderUserBarComponent} from "@app/_modules/layout/header/user-bar/header-user-bar.component";
import {LeftContentComponent} from './left-content/left-content.component';
import {RightContentComponent} from './right-content/right-content.component';
import {JumbotronComponent} from './jumbotron/jumbotron.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PagesModule,
    WidjetModule
  ],
  declarations: [
    GridComponent,
    HeaderComponent,
    FooterComponent,
    SettingSiteComponent,
    HeaderUserBarComponent,
    LeftContentComponent,
    RightContentComponent,
    JumbotronComponent
  ],
  exports: [
    GridComponent,
  ]
})
export class LayoutModule {
}
