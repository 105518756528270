import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-topics',
  templateUrl: 'form-field-topics.component.html',
  styleUrls: ['form-field-topics.component.scss']
})
export class FormFieldTopicsComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  public topics: any[] = [];

  public emptyItem = [
    {name: "name", title: 'Формулировка', type: "text", required: true},
    {name: "speakers", title: 'ФИО и должность докладчика', type: "text", required: true},
    {name: "attachments", title: "Прилагаемые материалы", type: "document", multiple: true, required: true}
  ];

  constructor() {
  }

  ngOnInit() {
    this.field.items.forEach((item: any) => {
      let topic = new FormGroup({});
      item.forEach((row: any) => {
        topic.addControl(row.name, new FormControl([row.value?.data || row.value]));
      });
      this.topics.push(topic);
      this.value = this.topics;
    })
  }

  set value(value) {
    this.control.setValue(value);
  }

  get control() {
    return this.formGroup.controls[this.field.name];
  }

  get value() {
    return this.control.value || [];
  }

  add(item: any) {
    let question = new FormGroup({});
    item.forEach((row: any) => {
      question.addControl(row.name, new FormControl(row.value?.data));
    });
    this.topics.push(question);
    this.field.items.push(item);
    this.value = this.topics;
  }

  remove(index: number) {
    let number = index + 1;
    if (confirm(`Удалить "Вопрос №${number}" из повестки?`)) {
      this.topics = this.topics.filter((question: any, key: any) => {
        return key !== index;
      });
      this.value = this.topics;
      this.field.items = this.field.items.filter((item: any, key: any) => {
        return key !== index;
      });
    }
  }
}
