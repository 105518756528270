<div  class="footer layout-corral">
  <div  class="footer-contact-left">
    <a routerLink="/"><img src="assets/images/logo_gbu.png"></a>    
    <div class="footer-contact-left-name">
      <span>Государственное бюджетное<br>учреждение города Москвы</span><br>
      <strong>«Жилищник Таганского района»</strong>
    </div>
    <div class="footer-contact-left-license">
      Все материалы сайта доступны по лицензии Creative Commons Attribution 3.0 при условии ссылки на первоисточник.
    </div>
  </div>
  <div  class="footer-contact-right">
    <a href="">Политика обработки персональных данных</a>
    <a href="">Техническая поддержка</a>
    <a href="">Соглашение о пользовании информационными системами и ресурсами города Москвы</a>
  </div>
  <div class="footer-down">
    <div>
      © 2017–2024, ГБУ «Жилищник»
    </div>
    <div>
      Работает на платформе НИР
    </div>
  </div>
</div>