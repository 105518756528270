import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {AuthGuard} from "@app/_helpers";
import {AdministrationPageComponent} from "@app/_modules/administration/page/administration-page.component";
import {BrowserModule} from "@angular/platform-browser";
import {AdministrateCompanyComponent} from "@app/_modules/administration/company/administrate-company.component";
import {AdministrateCommitteeComponent} from "@app/_modules/administration/committee/administrate-committee.component";
import {AdvisoriesModule} from "@app/_modules/advisories/advisories.module";
import {CompaniesModule} from "@app/_modules/companies/companies.module";
import {AdministrateSitePagesComponent} from "@app/_modules/administration/site-pages/administrate-site-pages.component";
import {PagesModule} from "@app/_modules/pages/pages.module";
import {WidjetModule} from "@app/_modules/widjet/widjet.module";
import {AdministrateLicenceComponent} from "@app/_modules/administration/licence/administrate-licence.component";
import {AdministrateUsersComponent} from "@app/_modules/administration/users/administrate-users.component";
import {UsersModule} from "@app/_modules/users/users.module";

type PathMatch = "full" | "prefix" | undefined;
const routes = [
  {path: 'administrate', redirectTo: 'administrate/site-pages', pathMatch: 'full' as PathMatch},
  {path: 'administrate/:tab', component: AdministrationPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes),
    AdvisoriesModule,
    CompaniesModule,
    PagesModule,
    WidjetModule,
    UsersModule,
  ],
    declarations: [
        AdministrationPageComponent,
        AdministrateCompanyComponent,
        AdministrateCommitteeComponent,
        AdministrateSitePagesComponent,
        AdministrateLicenceComponent,
        AdministrateUsersComponent
    ],
  exports: [
    RouterModule
  ]
})
export class AdministrationModule {}
