import {Component, Input} from '@angular/core';
import {AdvisoriesService} from "@app/_services/advisories.service";

@Component({
  selector: 'tk-structure-page',
  templateUrl: 'tk-structure-page.component.html',
  styleUrls: ['../../page.component.scss', 'tk-structure-page.component.scss']
})
export class TkStructurePageComponent {
  @Input() page: any;
  @Input() editMode: boolean;

  public mainCommittee: any;
  public activeAdvisories = {};

  constructor(private advisoriesService: AdvisoriesService) {
  }

  get groups() {
    return this.mainCommittee?.children?.data;
  }


  ngOnInit() {

  }

  ngOnChanges() {
    this.fetch();
  }

  fetch() {
    let include = ['children.children.document', 'children.children.children.document'];
    this.advisoriesService.show('main', {include: include.join(',')}).subscribe(res => {
      this.mainCommittee = res.data;
    });
  }


  toggleActivity(advisory: any) {
    this.activeAdvisories[advisory.id] = !this.activeAdvisories[advisory.id];
  }

  isActive(advisory: any) {
    return this.activeAdvisories[advisory.id];
  }


  getChildren(advisory: any) {
    return advisory?.children?.data;
  }
  getAdvisoryWithChildren(advisory: any) {
    let result = [advisory];
    this.getChildren(advisory).forEach(item => {
      result.push(item);
    });
    return result;
  }

}
