import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'filter-field-text',
  templateUrl: 'filter-field-text.component.html',
  styleUrls: ['filter-field-text.component.scss']
})
export class FilterFieldTextComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;
  @Input() maxLength = 0;

  constructor() {
  }

  ngOnInit() {
  }


  get control() {
    return this.formGroup.controls[this.field.name];
  }

}
