<form [formGroup]="form" (submit)="onSubmit()">  
  <div class="field" [class.invalid]="username.invalid && username.touched">
    <label for="username">Логин</label>
    <input id="username" formControlName="username" type="email" />
  </div>
  <div class="field" [class.invalid]="password.invalid && password.touched">
    <label for="password">Пароль</label>
    <input id="password" formControlName="password" type="password" autocomplete="off" />
  </div>
  <div *ngIf="error" class="error">{{error}}</div>
  <div class="bar">
    <div class="remember">
      <input id="remember" type="checkbox" />
      <label for="remember">Запомнить меня</label>
    </div>
    <div class="forget">
      <a (click)="forget()">Забыли пароль?</a>
    </div>
  </div>
  <div class="bottom">
    <!-- <button [disabled]="loading" (click)="signup()" class="prime outline">Регистрация</button> -->
    <button [disabled]="loading" type="submit" class="prime">Войти</button>
  </div>
</form>
