import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ico',
  templateUrl: './ico.component.html',
  styleUrls: ['./ico.component.scss']
})
export class IcoComponent {

  @Input() size:number|number[] = 24;
  @Input() rotate:number = 0;
  @Input() ico:string = 'close_24';
  @Input() color:string;

  public width:number;
  public height:number;
  public url:string;

  ngOnInit(){
    this.ngOnChanges()
  } 

  ngOnChanges():void{
    if (typeof this.size=='number'){
      this.width = this.height = this.size;
    } else {
      this.width = this.size[0]
      this.height = this.size[1]
    }
    this.url = `assets/images/ico/${this.ico}.svg#ico`
  }


  get style(){
    return {
      color: this.color,
      transform: `rotate(${this.rotate}deg)`,
      transition: 'transform 0.2s'
    }
  }
}
