import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'pages-list',
  templateUrl: 'pages-list.component.html',
  styleUrls: ['pages-list.component.scss']
})
export class PagesListComponent {
  controlsSubscription?: Subscription;
  resultsSubscription?: Subscription;

  public pages: any;

  constructor(private pagesService: PagesService, private listsService: ListsService) {
  }

  get listId() {
    return 'pages-list';
  }

  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch(controls);
    });
    this.resultsSubscription = this.listsService.result(this.listId).subscribe(val => {
      this.pages = val.data;
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultsSubscription?.unsubscribe();
  }



  fetch(controls) {
    let params = {page: controls.page || 0, filters: JSON.stringify(controls.filters)};
    this.pagesService.list(params).subscribe(result => {
      this.listsService.result(this.listId).next(result);
    })
  }
}
