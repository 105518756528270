import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-hidden',
  templateUrl: 'form-field-hidden.component.html',
  styleUrls: ['form-field-hidden.component.scss']
})
export class FormFieldHiddenComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {this.control.setValue(this.field.value?.data)});
  }

  get control() {
    return this.formGroup.controls[this.field.name];
  }
}
