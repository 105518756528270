import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

import {FormFieldDateComponent} from "@app/_modules/form-fields/types/date/form-field-date.component";
import {FieldDateReadonlyComponent} from "@app/_modules/form-fields/types/date/readonly/field-date-readonly.component";
import {FieldDateSingleComponent} from "@app/_modules/form-fields/types/date/single/field-date-single.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {NoopAnimationsModule} from "@angular/platform-browser/animations";
import { DateAdapter } from '@angular/material/core';
import { FormDateAdapter } from './form-field-date.component.spec';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NoopAnimationsModule
  ],
  declarations: [
    FormFieldDateComponent,
    FieldDateReadonlyComponent,
    FieldDateSingleComponent
  ],
  exports: [
    FormFieldDateComponent,
    FieldDateSingleComponent
  ],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: FormDateAdapter }
  ]
})
export class FormFieldDateModule {}
