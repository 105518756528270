<div class="fullwidth">
  <div class="limiter">
    <div class="row">
      <div class="left">
        <h2>Портфолио</h2>
        <button type="button" class="btn default" routerLink="/uslugi/portfolio-proektov">Подробнее</button>
      </div>
      <div class="right">
        <div class="holder">
          <swiper [config]="config">
            <ng-template ngFor let-item [ngForOf]="items">
              <ng-template swiperSlide>
                <div class="slide"><a routerLink="{{item?.link}}"><img [src]="item?.poster?.data?.links?.full" /></a></div>
              </ng-template>
            </ng-template>
          </swiper>
          <div class="bullets"></div>
        </div>
      </div>
    </div>
  </div>
</div>
