import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-section',
  templateUrl: 'page-section.component.html',
  styleUrls: ['page-section.component.scss']
})
export class PageSectionComponent {
  @Input() page: any;
  @Input() section: any;
  @Input() index: number;
  @Input() editMode: boolean;
  @Input() modelType: string;

  constructor() {
  }

  ngOnInit() {
  }


  get type() {
    return this.section?.type?.data;
  }

  get ord() {
    return this.section?.pivot?.ord;
  }

  get modelId() {
    return this.page.id;
  }
}
