import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {PublicationsService} from "@app/_services/publications.service";

@Component({
  selector: 'publications-list-item-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class PublicationsListItemMenuComponent {
  @Input() data: any;
  @Input() editMode: boolean;

  constructor(
    private formsService: FormsService,
    private publicationsService: PublicationsService,
    private listsService: ListsService,
  ) {
  }

  get listId() {
    return 'publications-list';
  }


  edit(id: string) {
    this.formsService.editModel(this.type, id, null, this.listId);
  }

  get type() {
    let type = this.data.type;
    if (this.data?.subtype?.name) type += '-' + this.data.subtype.name;
    return type;
  }

  show(id: string, published: boolean) {
    if (confirm(published?'Опубликовать?':'Снять с публикации?')) {
      this.publicationsService.published(id, published).subscribe(res => {
        this.listsService.refresh(this.listId, true);
      });
    }
  }

  delete(id: string) {
    if (confirm('Вы действительно хотите удалить эту запись?')) {
      this.publicationsService.delete(id).subscribe(res => {
        this.listsService.refresh(this.listId, true);
      });
    }
  }
}
