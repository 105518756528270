import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(private http: HttpClient) {
  }

  getList(params?: {}): Observable<any> {
    let url = environment.apiUrl + `/api/me/notifications`;
    return this.http.get(url, {params: params});
  }

  getCount(): Observable<any> {
    let url = environment.apiUrl + `/api/me/notifications/count`;
    return this.http.get(url, {});
  }

  sendMessageRead(notificationId: string): Observable<any> {
    const url = environment.apiUrl + '/api/me/notifications';
    return this.http.post(url, {id: notificationId});
  }

  show(id: string): Observable<any> {
    let url = environment.apiUrl + `/api/me/notifications/show/${id}`;
    return this.http.get(url, {});
  }

  update(id: string, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/me/notifications/update/${id}`, data);
  }
}
