import {Injectable} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import {FormGroup} from "@angular/forms";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  normalizeData(data: any) {
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        data[prop] = this.normalizeValue(data[prop]);
      }
    }
    return data;
  }
  normalizeValue(value: any) {
    let result = value;
    if (Array.isArray(value)) {
      result = value.map(val => {return this.normalizeValue(val)}).filter(val => {return (val !== null) && (val !== undefined)});
      if (!result.length) result = null;
    } else if (value instanceof FormGroup) result = this.normalizeData(value.value);
    else if (value?.id) result = value.id;
    return result;
  }



  filterOptions(search: string, options: []) {
    let result: any = options;
    if (search) {
      let regExps = this.getSearchRegexps(search);
      result = options.filter((option: any) => {
        return this.testOption(option, regExps);
      });
    }
    return result;
  }
  testOption(option: any, regExps: RegExp[]) {
    let result = true;
    regExps.forEach((regExp: RegExp) => {
      if (!regExp.test(option.caption) && !regExp.test(option.title) && !regExp.test(option.subtitle) && !regExp.test(option.name)) result = false;
    });
    return result;
  }
  getSearchRegexps(search: string) {
    return search.split(' ').map(term => {
      return new RegExp(term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    });
  }




  makeHttpParams(params: any): HttpParams {
    let result = new HttpParams()
    for (let param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        if (params[param] !== null && params[param] !== '') {
          if (typeof params[param] === 'object') {
            for (let param2 in params[param]) {
              if (Object.prototype.hasOwnProperty.call(params[param], param2)) {
                if (params[param][param2] !== null && params[param][param2] !== '') {
                  result = result.append(param + '[' + param2 + ']', params[param][param2])
                }
              }
            }
          } else {
            result = result.append(param, params[param])
          }
        }
      }
    }
    return result
  }
}
