import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";

import {FormFieldTimeComponent} from "@app/_modules/form-fields/types/time/form-field-time.component";
import {FieldTimeReadonlyComponent} from "@app/_modules/form-fields/types/time/readonly/field-time-readonly.component";
import {FieldTimeSingleComponent} from "@app/_modules/form-fields/types/time/single/field-time-single.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldTimeComponent,
    FieldTimeReadonlyComponent,
    FieldTimeSingleComponent,
  ],
  exports: [
    FormFieldTimeComponent
  ]
})
export class FormFieldTimeModule {}
