import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'documents-section',
  templateUrl: 'documents-section.component.html',
  styleUrls: ['documents-section.component.scss']
})
export class DocumentsSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService) {
  }


  get items() {
    return this.objectsService.getValue(this.section, 'documents-required');
  }

  ngOnInit() {

  }
}
