import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'relation-checkbox',
  templateUrl: 'relation-checkbox.component.html',
  styleUrls: ['relation-checkbox.component.scss']
})
export class RelationCheckboxComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;

  public selected = <any>[];

  ngOnInit(): void {
    this.initValue();
  }


  get initialValue() {
    return this.field.value?.data || [];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get options() {
    return this.field.represented?.data || [];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value: any[]) {
    this.selected = value;
    this.control.setValue(value.map(val => {return val.id}).filter(val => {return (val !== undefined) && (val !== null)}));
  }

  initValue() {
    this.selected = this.initialValue;
    this.control.setValue(this.initialValue.map(val => {return val.id}).filter(val => {return (val !== undefined) && (val !== null)}), {emitEvent: false});
  }


  onChange(event: any, option: any) {
    event.target.checked ? this.addValue(option) : this.removeValue(option);
  }

  isChecked(option: any) {
    return this.value.indexOf(option.id) !== -1;
  }

  addValue(option: any) {
    let val = this.selected;
    val.push(option);
    this.value = val;
  }
  removeValue(option: any) {
    this.value = this.selected.filter(val => {return option.id !== val.id});
  }


}
