import {Component} from '@angular/core';
import {FormGroup, FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {AuthenticationService, FiltersService, FormsService, ListsService} from "@app/_services";

@Component({
  templateUrl: 'applications-page.component.html',
  styleUrls: ['applications-page.component.scss']
})
export class ApplicationsPageComponent {
  public filters: FormGroup;

  constructor(private listsService: ListsService, private formsService: FormsService, private filtersService: FiltersService, public authService: AuthenticationService) {
  }

  ngOnInit() {
    this.filters = new FormGroup({search: new FormControl('')});
    this.filters.valueChanges.pipe(debounceTime(200)).subscribe(val => {
      this.listsService.addFilters(val);
    });
  }

  ngOnDestroy() {
  }


  create() {
    this.formsService.createModel('application');
  }


  showFilters() {
    this.filtersService.slider('model', 'applications');
  }

}
