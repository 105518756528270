import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'object-filters-group',
  templateUrl: 'object-filters-group.component.html',
  styleUrls: ['object-filters-group.component.scss']
})
export class ObjectFiltersGroupComponent {
  @Input() group: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

  get fields() {
    return this.group.fields.data || this.group.fields || [];
  }

}
