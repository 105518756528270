import { NgModule } from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { IcoComponent } from "./ico/ico.component";
import {RouterModule} from "@angular/router";
// import { QRCodeComponent } from "./qrcode/qrcode.component";
// import { QRCodeModule } from "angularx-qrcode";
import {DropDownComponent } from "./drop-down/drop-down.component";
import { SliderComponent } from './slider/slider.component';
import { SwitchComponent } from './switch/switch.component';
import { LocaleComponent } from './locale/locale.component';
import { FileIcoComponent } from './file-ico/file-ico.component';
import { ModalComponent } from './modal/modal.component';
import {SwiperModule} from "swiper/angular";
import { SwiperGalleryComponent } from './swiper-gallery/swiper-gallery.component';
import {FullscreenGalleryComponent} from "@app/_modules/widjet/swiper-gallery/fullscreen/fullscreen-gallery.component";
import { CmsModalComponent } from './cms-modal/cms-modal.component';
import { CmsDialogComponent } from './cms-dialog/cms-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';


@NgModule({
  imports:[
    BrowserModule,
    RouterModule,
    SwiperModule
    // QRCodeModule
  ],
  declarations: [
    IcoComponent,
    // QRCodeComponent,
    DropDownComponent,
    SliderComponent,
    SwitchComponent,
    LocaleComponent,
    FileIcoComponent,
    ModalComponent,
    SwiperGalleryComponent,
    FullscreenGalleryComponent,
    CmsModalComponent,
    CmsDialogComponent,
    SpinnerComponent
  ],
  exports: [
    IcoComponent,
    // QRCodeComponent,
    DropDownComponent,
    SliderComponent,
    SwitchComponent,
    LocaleComponent,
    FileIcoComponent,
    ModalComponent,
    SwiperGalleryComponent,
    FullscreenGalleryComponent,
    CmsModalComponent,
    CmsDialogComponent,
    SpinnerComponent
  ]
})
export class WidjetModule {

}
