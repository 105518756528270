import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'filter-field-date',
  templateUrl: 'filter-field-date.component.html',
  styleUrls: ['filter-field-date.component.scss']
})
export class FilterFieldDateComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;
  public controlGroup: FormGroup;

  constructor(private datePipe: DatePipe) {
  }


  ngOnInit() {
    this.controlGroup = new FormGroup({gt: new FormControl(this.initialValue?.gt || null), lt: new FormControl(this.initialValue?.lt || null)});
    this.control.setValue(this.controlGroup.value, {emitEvent: false});
    this.controlGroup.valueChanges.pipe(debounceTime(700)).subscribe(res => {this.value = res});
  }


  get initialValue() {
    return this.field.value?.data[0] || this.field.value?.data || {};
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control.value;
  }
  set value(val: any) {
    val.gt = val.gt ? this.datePipe.transform(val.gt, 'yyyy-MM-dd') : null;
    val.lt = val.lt ? this.datePipe.transform(val.lt, 'yyyy-MM-dd') : null;
    this.control?.setValue(val);
  }

  get min() {
    return this.field?.range?.min || null;
  }
  get max() {
    return this.field?.range?.max || null;
  }


  onChange(prop: string, event: MatDatepickerInputEvent<Date>) {
    this.value = event.value;
  }


}
