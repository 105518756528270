<div class="locale" style="position: relative;" (mouseleave)="close()">
    <div class="locales-btn" (click)="ddMenu=!ddMenu">
      <img [src]="flag(rootPage?.slug)">
      <drop-down [target]="langMenu" ico="arrow_drop_down_24" [visible]="ddMenu"></drop-down>
    </div>

      <div class="dropdown" (click)="close()" #langMenu>
        <div class="menu">
          <div class="type" *ngFor="let locale of locales" (click)="select(locale)" >
            <div class="row">
              <img [src]="flag(locale.slug)">
              {{locale.name}}
            </div>
          </div>
        </div>
      </div>
  </div>