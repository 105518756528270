<div class="field" [class.hidden]="field.hidden">
  <div class="caption">
    <label for="{{field.name}}">{{field.title}}</label>
  </div>
  <div class="value">
    <div class="control" [ngSwitch]="field.type">
      <filter-field-boolean *ngSwitchCase="'boolean'" [field]="field" [formGroup]="formGroup"></filter-field-boolean>
      <filter-field-date *ngSwitchCase="'date'" [field]="field" [formGroup]="formGroup"></filter-field-date>
      <filter-field-datetime *ngSwitchCase="'datetime'" [field]="field" [formGroup]="formGroup"></filter-field-datetime>
      <filter-field-float *ngSwitchCase="'float'" [field]="field" [formGroup]="formGroup"></filter-field-float>
      <filter-field-integer *ngSwitchCase="'integer'" [field]="field" [formGroup]="formGroup"></filter-field-integer>
      <filter-field-relation *ngSwitchCase="'relation'" [field]="field" [formGroup]="formGroup"></filter-field-relation>
      <filter-field-string *ngSwitchCase="'string'" [field]="field" [formGroup]="formGroup"></filter-field-string>
      <filter-field-text *ngSwitchCase="'text'" [field]="field" [formGroup]="formGroup"></filter-field-text>
      <p *ngSwitchDefault>field type {{field.type}} filter is undefined</p>
    </div>
  </div>
</div>
