import {Component, OnInit, Input, HostListener, ElementRef, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UtilityService} from "@app/_services/utility.service";

@Component({
  selector: 'combobox-single',
  templateUrl: 'combobox-single.component.html',
  styleUrls: ['combobox-single.component.scss', '../../form-field-relation.component.scss']
})
export class ComboboxSingleComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;
  @ViewChild("searchInput") searchInput?: ElementRef;

  public active = false;
  public options = <any>[];
  public search = '';

  constructor(
    private eRef: ElementRef,
    private utilityService: UtilityService
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (!this.eRef.nativeElement.contains(target)) this.active = false;
  }

  ngOnInit(): void {
    setTimeout(() => {this.value = this.initialValue});
    this.updateOptions();
    //if (!this.value && this.field.required && (this.options.length === 1)) this.value = this.options[0];
  }


  get initialValue() {
    return this.value || this.field.value?.data[0] || null;
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value: any) {
    this.control.setValue(value);
  }

  get caption() {
    return this.value?.caption || this.value?.title || this.value?.name || 'Не выбрано';
  }


  toggleValue(option: any) {
    this.control.markAsTouched();
    this.isSelected(option) ? (this.value = null) : (this.value = option);
    this.active = false;
  }
  isSelected(option: any) {
    return option.id === this.value?.id;
  }


  onInput(event) {
    this.search = event.target.value;
    this.updateOptions();
  }
  updateOptions() {
    this.options = this.utilityService.filterOptions(this.search, this.field.options?.data || []);
  }



  toggleActivity() {
    this.active = !this.active;
    if (this.active) setTimeout(() => {this.searchInput?.nativeElement.focus()}, 10);
  }
}

