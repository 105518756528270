import {Component} from '@angular/core';
import {PagesService} from "@app/_services/pages.service";
import { FormsService } from '@app/_services';
import {LicenceService} from "@app/_services/licence.service";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'administrate-site-pages',
  templateUrl: 'administrate-site-pages.component.html',
  styleUrls: ['administrate-site-pages.component.scss']
})
export class AdministrateSitePagesComponent {
  constructor(private pagesService: PagesService, private formsService: FormsService, public licenceService: LicenceService, private dialog: DialogService) {
  }

  get isLicenceActive() {
    return this.licenceService.isActive;
  }
  get isMultilang() {
    return this.isLicenceActive && this.licenceService.hasOption('multilang');
  }


  get showDeleted() {
    return this.pagesService.showDeleted;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.pagesService.showDeleted = false;
  }

  addLocale() {
    if (this.isMultilang) this.formsService.createModel('localePage', null, 'pages-tree-root');
    else this.dialog.alert('Данная опция не входит в Вашу лицензию. Для включения опции обратитесь в службу поддержки НИР (+7 499 490 04 65, help@nirgroup.ru)');
  }

  toggle() {
    this.pagesService.showDeleted = !this.pagesService.showDeleted;
  }

}
