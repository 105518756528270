<div class="block" [formGroup]="formGroup">
  <div class="title" [innerHTML]="field.titleBlock"></div>
  <div class="options">
    <div class="option"><label><input type="radio" [formControlName]="field.name" value="">Все</label></div>
    <div class="option" *ngFor="let option of options">
      <label>
        <input type="radio" [value]="option.id" [formControlName]="field.name" />
        {{option.caption || option.title || option.name}} {{option?.subtitle ? ' - ' + option.subtitle : ''}}
      </label>
    </div>
  </div>
</div>
