import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {FormsService, ListsService} from "@app/_services";


import { PagesService } from '@app/_services/pages.service';


@Component({
  templateUrl: 'slider-form.component.html',
  styleUrls: ['slider-form.component.scss']
})
export class SliderFormComponent {
  public formGroup: FormGroup;
  public target: string;
  public type: string;
  public id: string;
  public readonly = false;
  public formParams: any = {};

  public list: string;

  public title = '';
  public btnSaveHidden = true;
  public btnSaveLabel = '';
  public noticeVisible = false;
  public frames = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private listsService: ListsService,
    private pagesService: PagesService
  ) {
  }

  get activeFrame() {
    return this.frames[this.activeFrameIndex];
  }
  get activeFrameIndex() {
    for(let i = 0; i <= this.frames.length; i++) {
      if (this.frames[i]?.active) return i;
    }
  }
  get isFirstFrame() {
    return this.activeFrameIndex === 0;
  }
  get isLastFrame() {
    return this.activeFrameIndex >= this.frames.length - 1;
  }


  ngOnInit() {
    this.parseUrlParams(this.route.snapshot.params);
    this.initFormGroup(JSON.parse(this.route.snapshot.queryParamMap.get('extraProps')));
    this.formParams = JSON.parse(this.route.snapshot.queryParamMap.get('formParams')) || {};
    this.fetch();
  }

  fetch() {
    let params = {include: 'groups.fields.value,groups.fields.options,value,options', extraProps: JSON.stringify(this.formGroup.value)};
    this.formsService.get(this.target, this.type, this.id, params).subscribe(res => {
      this.title = res.title || res.data?.title || res.data?.typeTitle;
      this.btnSaveHidden = res.btnSaveHidden;
      this.btnSaveLabel = res.btnSaveLabel || 'Сохранить';
      this.frames = res.frames || [res.data || res];
      this.frames[0].active = true;
      setTimeout(() => this.noticeVisible = !!Object.keys(this.formGroup.controls).length);
    });
  }

  parseUrlParams(params) {
    this.target = params.target;
    this.type = params.type;
    this.id = params.id;
    this.list = params.list;
  }

  initFormGroup(props) {
    this.formGroup = new FormGroup({});
    for (let prop in props) {
      if (props.hasOwnProperty(prop)) this.formGroup.addControl(prop, new FormControl(props[prop]));
    }
  }



  activateNextFrame() {
    this.activateFrame(this.activeFrameIndex + 1);
  }
  activatePreviousFrame() {
    this.removeUnfilledControls();
    this.activateFrame(this.activeFrameIndex - 1);
  }
  activateFrame(index) {
    this.frames = this.frames.map((frame, i) => {
      frame.active = i === index;
      return frame;
    });
  }

  removeUnfilledControls() {
    this.activeFrame.groups?.data.forEach(group => {
      group.fields?.data.forEach(field => {
        let val = this.formGroup.controls[field.name]?.value;
        if (field.required && !(Array.isArray(val) ? val.length : val)) this.formGroup.removeControl(field.name);
      });
    });
  }



  proceed() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.isLastFrame ? this.save() : this.activateNextFrame();
    }
  }

  back() {
    this.isFirstFrame ? this.close() : this.activatePreviousFrame();
  }

  save() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.formsService.save(this.target, this.type, this.id, this.formGroup.value).subscribe(res => {
        if (res?.asset) {
          window.open(res.asset.data.links[res.action || 'download'], "_blank");
        } else {
          this.listsService.refresh(this.list);
          if(this.type=='page') this.pagesService.root({include:'children'}).subscribe(
            res => { this.pagesService.rootPages = res }
          )
          this.close();
        }
      }, error => {
        console.log(error);
        //for (let prop in error) {
        //  if (error.hasOwnProperty(prop)) this.formGroup.controls[prop].setErrors(error);
        //}
        //this.formGroup.controls[error.field].setErrors(error.message);
      });
    }
  }

  close() {
    this.router.navigate([{outlets: {slider: null}}]).then();
  }
}
