import {Component} from '@angular/core';
import {GalleryService} from "@app/_services/gallery.service";

@Component({
  selector: 'fullscreen-gallery',
  templateUrl: 'fullscreen-gallery.component.html',
  styleUrls: ['fullscreen-gallery.component.scss']
})
export class FullscreenGalleryComponent {

  constructor(private galleryService: GalleryService) {
  }

  get items() {
    return this.galleryService.images;
  }
  get position() {
    return this.galleryService.position;
  }

  get isSingle(): boolean {
    return this.items?.length < 2;
  }


  ngOnInit() {
  }


  close() {
    this.galleryService.close();
  }
}
