import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent {

  @Input() ico = 'drop_down_24';
  @Input() angle = [0,-180];
  @Input() visible = false;
  @Input() order: {
    on:boolean,
    off:boolean
  };
  @Input() target:HTMLElement;

  @Output() toggle =  new EventEmitter<boolean>()

  ngOnChanges(){
    if (this.order?.on === true) this.visible = true;
    if (this.order?.off === true) this.visible = false;
    this.setToggle()
  }



  
  click(){    
    this.visible = !this.visible;

    this.setToggle()
    
    this.toggle.emit(this.visible)
  }

  setToggle(){
    if(this.target) {
      if(this.visible){
        this.target.classList.remove('toggle-hide')
      }else{
        this.target.classList.add('toggle-hide')
      }
    };
  }
}
