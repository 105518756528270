import {Component, Input} from '@angular/core';
import {FormArray, FormGroup, FormControl, FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'field-text-multiple',
  templateUrl: 'field-text-multiple.component.html',
  styleUrls: ['field-text-multiple.component.scss', '../form-field-text.component.scss']
})
export class FieldTextMultipleComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    //let control = this.formGroup.controls[this.field.name];
    this.formGroup.setControl(this.field.name, new FormArray([]));
    if (this.initialValue?.length) this.initialValue.forEach(val => {this.add(val, true);});
    else this.add('', true);
  }



  get initialValue() {
    return (this.value?.length) ? this.value : (this.field.value?.data || []);
  }
  get control() {
    return this.formGroup.controls[this.field.name] as FormArray;
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value) {
    this.control?.setValue(value);
  }


  add(value = '', required = false) {
    this.control.push(new FormControl(value, required ? [Validators.required] : null));
  }

  remove(i: number) {
    this.control.removeAt(i);
  }


  get maxLength() {
    return this?.field?.maxLength || 65535;
  }

}
