<add-sidebar *ngIf="editMode" [page]="page" [ord]="ord"></add-sidebar>
<div class="sidebar">
  <div class="menu" *ngIf="editMode">
    <div class="block">
      <button type="button" class="up" *ngIf="!isFirst" (click)="move(ord - 1)"></button>
      <button type="button" class="down" *ngIf="!isLast" (click)="move(ord + 1)"></button>
      <button type="button" class="edit" (click)="edit()"></button>
      <button type="button" class="delete" (click)="delete()"></button>
    </div>
  </div>
  <div class="header" *ngIf="header" [innerHTML]="header"></div>
  <div class="text" *ngIf="html" [innerHTML]="html"></div>
  <div class="documents" *ngIf="documents?.length">
    <div class="items">
      <div class="item" *ngFor="let document of documents"><a [href]="document.links.open" target="_blank">{{document.name}}</a></div>
    </div>
  </div>
</div>
