import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-html',
  templateUrl: 'form-field-html.component.html',
  styleUrls: ['form-field-html.component.scss']
})
export class FormFieldHtmlComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

}
