import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {FormFieldHtmlComponent} from "@app/_modules/form-fields/types/html/form-field-html.component";
import {FieldHtmlReadonlyComponent} from "@app/_modules/form-fields/types/html/readonly/field-html-readonly.component";
import {FieldHtmlSingleComponent} from "@app/_modules/form-fields/types/html/single/field-html-single.component";
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditorModule
  ],
  declarations: [
    FormFieldHtmlComponent,
    FieldHtmlReadonlyComponent,
    FieldHtmlSingleComponent
  ],
  exports: [
    FormFieldHtmlComponent
  ],
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
  ]
})
export class FormFieldHtmlModule {
}
