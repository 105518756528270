import {Component, Input} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'maps-section',
  templateUrl: 'maps-section.component.html',
  styleUrls: ['maps-section.component.scss']
})
export class MapsSectionComponent {
  @Input() section: any;
  public mapUrl: any = '';

  constructor(
    private sanitized: DomSanitizer,
    private objectsService: ObjectsService,
  ) {
  }

  ngOnInit() {
    this.setMapUrl();
  }

  setMapUrl() {
    const url = this.objectsService.getValue(this.section, 'maps-url');
    const arUrl = url.split('?');
    const getUrl = arUrl.length==2 ? arUrl[1] : '';
    const arOid = arUrl[0].split('/').filter(value => value);
    let newUrl = 'https://yandex.ru/map-widget/v1/?' +  getUrl;
    if (arOid.length === 6) {
      newUrl += '&ol=biz&mode=search&oid=' + arOid[5];
    }
    this.mapUrl = this.sanitized.bypassSecurityTrustResourceUrl(newUrl);
  }
}
