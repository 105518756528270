import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '@environments/environment';
import {Observable} from "rxjs";
import {UtilityService} from "@app/_services/utility.service";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class FormsService {
  constructor(private http: HttpClient, private utility: UtilityService, private router: Router) {
  }

  get(target: string, type: string, id?: string, params?: {}): Observable<any> {
    let url = `${environment.apiUrl}/api/forms/${target}/${type}` + ((id && (id !== 'null')) ? `/${id}` : '');
    return this.http.get(url, {params: params});
  }

  save(target: string, type: string, id?: string, data?: any): Observable<any> {
    let url = `${environment.apiUrl}/api/forms/${target}/${type}` + ((id && (id !== 'null')) ? `/${id}` : '');
    return this.http.post(url, this.utility.normalizeData(data));
  }



  createModel(type: string, params?: any, list?: string) {
    this.slider('model', type, null, params, list);
  }

  editModel(type: string, id: string, params?: any, list?: string) {
    this.slider('model', type, id, params, list);
  }

  createObject(type: string, params?: any, list?: string) {
    this.slider('object', type, null, params, list);
  }

  editObject(id: string, params?: any, list?: string) {
    this.slider('object', null, id, params, list);
  }


  slider(target: string, type: string, id?: string, params?: any, list?: string) {
    let commands = ['form', target, type || null, id || null];
    if (list) commands.push(list);
    this.router.navigate([{outlets: {slider: commands}}], {queryParams: this.makeQueryParams(params), skipLocationChange: true}).then();
  }

  makeQueryParams(params) {
    let result: any = {};
    if (params) {
      for (let prop in params) {
        if (params.hasOwnProperty(prop)) result[prop] = JSON.stringify(params[prop]);
      }
    }
    return result;
  }


}
