import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class CompaniesService {
  constructor(private http: HttpClient) {
  }

  fetch(id: string, params?: {}): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/companies/${id}`, {params: params});
  }


  getDataByInn(inn): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/dadata/${inn}`);
  }

}
