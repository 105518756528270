import {Component} from '@angular/core';
import {AuthenticationService} from "@app/_services";

@Component({
  selector: 'administrate-users',
  templateUrl: 'administrate-users.component.html',
  styleUrls: ['administrate-users.component.scss']
})
export class AdministrateUsersComponent {
  constructor(private authService: AuthenticationService) {
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  ngOnInit() {
  }
}
