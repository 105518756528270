import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'cards-item-partners',
  templateUrl: 'cards-item-partners.component.html',
  styleUrls: ['cards-item-partners.component.scss']
})
export class CardsItemPartnersComponent {
  @Input() card: any;

  constructor(private objectsService: ObjectsService) {
  }

  get image() {
    return this.objectsService.getValue(this.card, 'image-required');
  }
  get link() {
    return this.objectsService.getValue(this.card, 'link-required');
  }


  ngOnInit() {
  }
}

