<div class="main">
  <div class="left"><div class="logo"><img *ngIf="logo" src="{{logo}}" alt="" /></div></div>
  <div class="mid"><div class="name">{{company?.name}}</div></div>
  <div class="right"><div class="menu" *ngIf="isEditable"><button type="button" title="Редактировать параметры организации" class="edit" (click)="edit()"></button></div></div>
</div>
<table class="default">
  <tr><td class="caption">Юридический адрес</td><td class="value">{{company?.legalAddress?.data.full}}</td></tr>
  <tr><td class="caption">Электронная почта</td><td class="value">{{emails}}</td></tr>
  <tr><td class="caption">Телефон</td><td class="value">{{phones}}</td></tr>
  <!--tr *ngIf="isEditable"><td colspan="2"><span class="link" (click)="edit()">Изменить</span></td></tr-->
</table>

