<form [formGroup]="form" (submit)="onSubmit()">
  <div class="field password">
    <label for="password">Укажите новый пароль</label>
    <input id="password" formControlName="password" type="password" />
  </div>
  <div class="field confirmation">
    <label for="confirmation">Новый пароль еще раз</label>
    <input id="confirmation" formControlName="confirmation" type="password" />
  </div>
  <div *ngIf="error" class="error">{{error}}</div>
  <div class="bottom">
    <button type="button" class="btn secondary" routerLink="/login">Авторизоваться</button>
    <button [disabled]="loading" class="btn">Сохранить</button>
  </div>
</form>

