<div class="registry">
  <div class="top-panel">
    <registry-filters [registry]="registry" [filters]="filters" [hasCustomFilters]="hasCustomFilters" [isFiltersApplied]="isFiltersApplied" [listId]="entriesListId"></registry-filters>
  </div>
  <div class="bar" *ngIf="editMode">
    <div class="name">Корневая категория</div>
    <div class="menu">
      <ico ico="add_library_24" *ngIf="hasCategories" title="Добавить категорию" (click)="addCategory()"></ico>
      <ico ico="add_notes_24" (click)="addEntry()" title="Добавить запись"></ico>
    </div>
  </div>
  <registry-categories *ngIf="hasCategories && !isFiltersApplied" [registry]="registry" [editMode]="editMode"></registry-categories>
  <registry-entries [registry]="registry" [editMode]="editMode"></registry-entries>
</div>
