import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomepageComponent} from "@app/_modules/pages/home/homepage.component";
import {HomepageIntroComponent} from "@app/_modules/pages/home/intro/homepage-intro.component";
import {ParticlesDirective} from "@app/_directives/particles.directive";
import {HomepagePromoComponent} from "@app/_modules/pages/home/intro/promo/homepage-promo.component";
import {HomepageCardsComponent} from "@app/_modules/pages/home/cards/homepage-cards.component";
import {RouterModule} from "@angular/router";
import {HomepagePortfolioComponent} from "@app/_modules/pages/home/portfolio/homepage-portfolio.component";
import {HomepageNewsComponent} from "@app/_modules/pages/home/news/homepage-news.component";
import {HomepagePartnersComponent} from "@app/_modules/pages/home/partners/homepage-partners.component";
import {SwiperModule} from "swiper/angular";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule
  ],
  declarations: [
    HomepageComponent,
    HomepageIntroComponent,
    ParticlesDirective,
    HomepagePromoComponent,
    HomepageCardsComponent,
    HomepagePortfolioComponent,
    HomepageNewsComponent,
    HomepagePartnersComponent
  ],
  exports: [
    HomepageComponent
  ]
})
export class HomepageModule {}
