<div *ngIf="editMode" class="add">
  <div class="toggle prime" [class.active]="ddVisible" (click)="add()">
    <div class="two-lines"></div>
    <ico ico="add_circle_24"></ico>
    <div class="two-lines"></div>
  </div>
</div>
<pagination [listId]="listId"></pagination>
<div class="items">
  <publications-list-item *ngFor="let publication of publications" [publication]="publication" [editMode]="editMode"></publications-list-item>
</div>
<pagination [listId]="listId"></pagination>
