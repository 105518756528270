import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldStringComponent} from "@app/_modules/form-fields/types/string/form-field-string.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FieldStringSingleComponent} from "@app/_modules/form-fields/types/string/single/field-string-single.component";
import {FieldStringReadonlyComponent} from "@app/_modules/form-fields/types/string/readonly/field-string-readonly.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    FormFieldStringComponent,
    FieldStringSingleComponent,
    FieldStringReadonlyComponent
  ],
  exports: [
    FormFieldStringComponent
  ]
})
export class FormFieldStringModule {}
