import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'relation-radio',
  templateUrl: 'relation-radio.component.html',
  styleUrls: ['relation-radio.component.scss']
})
export class RelationRadioComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() field!: any;

  ngOnInit(): void {
    this.initValue();
  }


  get initialValue() {
    return this.field.value?.data[0]?.id || '';
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get options() {
    return this.field.represented?.data || [];
  }

  initValue() {
    this.control.setValue(this.initialValue, {emitEvent: false});
  }

}
