import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'header-section',
  templateUrl: 'header-section.component.html',
  styleUrls: ['header-section.component.scss']
})
export class HeaderSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService) {
  }
  ngOnInit() {
  }


  get content() {
    return this.objectsService.getValue(this.section, 'header-required');
  }
  get type() {
    return this.objectsService.getValue(this.section, 'header-type');
  }
}
