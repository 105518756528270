import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";

@Component({
  selector: 'list-section',
  templateUrl: 'list-section.component.html',
  styleUrls: ['list-section.component.scss']
})
export class ListSectionComponent {
  @Input() section: any;

  constructor(private objectsService: ObjectsService) {
  }

  get type() {
    return this.objectsService.getValue(this.section, 'list-type');
  }
  get items() {
    return this.objectsService.getValue(this.section, 'list-items');
  }

  ngOnInit() {

  }
}
