<div class="members">
  <div class="add"  *ngIf="editMode">
    <div class="title">
      <span [innerHTML]="title"></span>
    </div>
    <div class="toggle" (click)="add()"></div>
  </div>
  <div class="list">
    <div class="item" *ngFor="let member of members; let i=index;">
      <member-section-item [editMode]="editMode" [member]="member" [section]="section"></member-section-item>

<!-- 

      <page-menu [editMode]="editMode" [section]="member" [modelId]="modelId" [modelType]="modelType"></page-menu>
      <img [src]="getFields(member)['member-image']?.links?.full || cover" class="image"   (click)="showImage[i]=true"/>
      <div class="name" [innerHTML]="getFields(member)['member-name-required']"></div>
      <div class="position" [innerHTML]="getFields(member)['member-position-required']" *ngIf="getFields(member)['member-position-required']"></div>
      <a class="link tel" [innerHTML]="getFields(member)['member-phone']" [href]="'tel:'+getFields(member)['member-phone']" *ngIf="getFields(member)['member-phone']"></a>
      <a class="link mail" [innerHTML]="getFields(member)['member-email']" [href]="'mailto:'+getFields(member)['member-email']" *ngIf="getFields(member)['member-email']"></a>
      <div class="doc" *ngIf="getFields(member)['member-doc']?.links.download">
        <div class="doc-icon" [style.backgroundImage]="iconUrl(getFields(member)['member-doc'].extension)"></div>
        <div class="doc-link">
          <a [textContent]="getFields(member)['member-doc'].name" class="title" [href]="getFields(member)['member-doc'].links.download"></a>
        </div>
      </div>
      <div class="more">
        <a  *ngIf="getFields(member)['member-description']" (click)="showDescription=true">

          Подробнее <img src="/assets/images/icons/chevron_right_24dp.svg" alt="">
        </a>
        <div *ngIf="showDescription"  class="modal" (click)="showDescription=false">
          <div  [innerHTML]="getFields(member)['member-description']"></div>
        </div>
      </div>

      <img [src]="getFields(member)['member-image']?.links?.full || cover" class="full-screen" [hidden]="!showImage[i]"  (click)="showImage[i]=false"/>
 -->

      
    </div>
  </div>
</div>
