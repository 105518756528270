import {Component, Input} from '@angular/core';
import {FormsService} from "@app/_services";
import {Router} from "@angular/router";

@Component({
  selector: 'member-section-list',
  templateUrl: 'member-section-list.component.html',
  styleUrls: ['member-section-list.component.scss']
})
export class MemberSectionListComponent {
  @Input() section: any;
  @Input() editMode: boolean;
  @Input() page: any;

  public showImage:boolean[] = [];
  public showDescription = false;

  constructor(
    private formsService: FormsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  getFields(member) {
    let fields: any = {};
    member.groups.data.forEach(group => {
      group.fields.data.forEach(field => {
        if (field.name && field?.value?.data[0]) {
          fields[field.name] = field?.value?.data[0]
        }
      })
    })
    return fields
  }


  get title() {
    return this.section?.groups?.data[0]?.fields?.data[0]?.value?.data[0];
  }

  get members() {
    return this.section?.objectables?.data;
  }

  get ord() {
    return this.members.length + 1;
  }

  get modelId() {
    return this.section.id;
  }


  iconUrl(name:string) {
    if (name) return ICON_TYPE[name] && `url(/assets/images/icons/document/${ICON_TYPE[name]}.svg)`
  }

  get modelType() {
    return 'object';
  }

  public cover = '/assets/images/icons/view_man_273_370dp.svg';


  add() {
    let attach = {modelType: 'object', group: 'sections', modelId: this.section.id, ord: this.ord};
    this.formsService.createObject('page-section-list-member', {extraProps: {attach: attach}});
  }

  getLink(id: any) {
    return this.router.url + '?id=' + id;
  }

  photo(member) {
    return this.getFields(member)['member-image'].links.open
  }
}


const ICON_TYPE:any={
  rtf:'DOC',
  docx:'DOC',
  doc:'DOC',
  xl:'XLS',
  xls:'XLS',
  xlsx:'XLS',
  ppt:'PPT',
  pptx:'PPT',
  jpg:'JPG',
  jpeg:'JPG',
  pdf:'PDF'
}
