import {Component, Input} from '@angular/core';
import {CompaniesService} from "@app/_services/companies.service";
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";

@Component({
  selector: 'company-members',
  templateUrl: 'company-members.component.html',
  styleUrls: ['company-members.component.scss']
})
export class CompanyMembersComponent {
  @Input() companyId: string;
  @Input() editable = false;
  public company: any;
  public controlsSubscription?: Subscription;

  constructor(private companiesService: CompaniesService, private listsService: ListsService) {
  }


  get members() {
    return this.company?.members?.data;
  }


  ngOnInit() {
    this.controlsSubscription = this.listsService.controls().subscribe(val => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
  }


  fetch() {
    this.companiesService.fetch(this.companyId, {include: 'members.user.avatar'}).subscribe(res => {
      this.company = res.data;
    });
  }




}
