import {Component, HostListener, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {AssetsService} from "@app/_services/assets.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {SortableOptions} from "sortablejs";

@Component({
  selector: 'field-document-multiple',
  templateUrl: 'field-document-multiple.component.html',
  styleUrls: ['../form-field-document.component.scss', 'field-document-multiple.component.scss']
})
export class FieldDocumentMultipleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;
  public assets = <any>[];

  public upload: any = {file: null, progress: 0};
  public dragOver = false;

  public documentsValues: SortableOptions = {
    group: 'doc-values',
    handle: 'file-ico',
    // onUpdate: (event: any) => {this.move(event)},
    // onAdd: (event: any) => {this.move(event)}
  };

  @HostListener("dragover", ["$event"])
  @HostListener("dragenter", ["$event"])
  @HostListener("dragend", ["$event"])
  @HostListener("dragleave", ["$event"])
  @HostListener("drop", ["$event"])
  onDragEvent(event: DragEvent) {
    if ((event.type === 'drop') && this.dragOver && event.dataTransfer?.files.length) {
      this.uploadFiles(event.dataTransfer?.files);
    }
    this.dragOver = event.type === 'dragover';
    event.preventDefault()
  }


  constructor(private assetsService: AssetsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {this.value = this.initialValue});
  }


  get initialValue() {
    return this.field.value?.data || [];
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value || [];
  }
  set value(value: any[]) {
    this.assets = value;
    this.control?.setValue(value.map(val => {return val.id}).filter(val => {return val}));
  }


  addValue(asset: any) {
    let value = this.assets;
    value.push(asset);
    this.value = value;
  }
  removeValue(asset: any) {
    this.control.markAsTouched();
    this.value = this.assets.filter((item: any) => {
      return item.id !== asset.id;
    });
  }
  clear() {
    this.value = [];
  }



  onFileInput(event: any) {
    this.uploadFiles(event.target.files);
    event.target.value = null;
  }

  uploadFiles(files: any) {
    for (let file of files) {
      this.uploadFile(file);
    }
  }
  uploadFile(file: File) {
    this.upload.file = file;
    this.assetsService.upload(file).subscribe((result) => {
      if (result.type === HttpEventType.UploadProgress) {
        this.upload.progress = Math.round(100 * result.loaded / result.total);
      } else if (result instanceof HttpResponse) {
        this.upload = {file: null, progress: 0};
        this.addValue(result.body.data);
        this.control.markAsTouched();
      }
    }, error => {
      this.upload = {file: null, progress: 0};
      alert(error.error.message);
    });
  }

}
