<div class="block" [formGroup]="formGroup">
  <div class="title" [innerHTML]="field.titleBlock"></div>
  <div class="options">
    <div class="option" *ngFor="let option of options">
      <label>
        <input type="checkbox" [value]="option.id" [checked]="isChecked(option)" (change)="onChange($event, option)" />
        {{option.caption || option.title || option.name}} {{option?.subtitle ? ' - ' + option.subtitle : ''}}
      </label>
    </div>
  </div>
</div>
