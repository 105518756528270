<div class="modal-overlay" (click)="cls()">

</div>
<div class="modal-bar">
    <div class="modal-header">
        <ng-content select="[header]"></ng-content>
        <ico (click)="cls()"></ico>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>