import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'form-field-document',
  templateUrl: 'form-field-document.component.html',
  styleUrls: ['form-field-document.component.scss']
})
export class FormFieldDocumentComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }
}
