import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-boolean-single',
  templateUrl: 'field-boolean-single.component.html',
  styleUrls: ['field-boolean-single.component.scss']
})
export class FieldBooleanSingleComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;

  constructor() {
  }

  ngOnInit() {
    this.value = this.initialValue;
  }



  get initialValue() {
    return !!this.field.value?.data[0] || null;
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value) {
    this.control?.setValue(value);
  }



  toggle() {
    this.value = !this.value;
  }
}
