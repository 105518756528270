<router-outlet name="auth"></router-outlet>
<router-outlet name="slider"></router-outlet>

<div class="wrapper" [class.this-root-page] = "isRootPage">
  <header class="space"></header>
  <jumbotron></jumbotron>
  <div class="space content-sapce">
    <div class="content-grid layout-corral">
      <!-- <left-content></left-content> -->
      <div class="main-content">
        <div *ngIf="isRootPage" class="main-news">
          <div *ngFor="let publications of news" class="main-news-item">
            <a [routerLink]="publications.link"   class="main-news-item-header">
              <h3>{{publications.name}}</h3>
            </a>
            <a *ngFor="let page of publications.publications"  class="main-news-item-card" [routerLink]="page.link">
              <img src="{{page.img}}">
              <span>{{page.date}}</span>
              <h4>{{page.name}}</h4>        
            </a>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
      <right-content *ngIf="currentPage"></right-content>
    </div>
  </div>
  <footer class="space"></footer>
  <!-- <footer class="space"></footer> -->
</div>
<fullscreen-gallery></fullscreen-gallery>
<cms-dialog dir="ltr"></cms-dialog>
