import {Component} from '@angular/core';
import {AuthenticationService, FormsService, ListsService} from "@app/_services";
import {Subscription} from "rxjs";

@Component({
  templateUrl: 'user-profile.component.html',
  styleUrls: ['user-profile.component.scss']
})
export class UserProfileComponent {
  inited = false;
  controlsSubscription: Subscription;

  constructor(private listsService: ListsService, private formsService: FormsService, private authService: AuthenticationService) {
  }

  get user() {
    return this.authService.user;
  }
  get avatar() {
    return this.user?.avatar?.data;
  }

  ngOnInit() {
    this.controlsSubscription = this.listsService.controls().subscribe(res => {
      this.inited ? this.authService.getCurrentUser() : this.inited = true;
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
  }



  editProfile() {
    this.formsService.editModel('userProfile', this.user.id);
  }

  changePassword() {
    this.formsService.editModel('userPassword', this.user.id);
  }

}
