import {Component, Input} from '@angular/core';
import {FormsService, ListsService, UsersService} from "@app/_services";
import { DialogService } from '@app/_services/dialog.service';

@Component({
  selector: 'users-list-item',
  templateUrl: 'users-list-item.component.html',
  styleUrls: ['users-list-item.component.scss']
})
export class UsersListItemComponent {
  @Input() user: any;
  @Input() listId: string;

  constructor(private usersService: UsersService, private formsService: FormsService, private listsService: ListsService,  private dialog: DialogService) {
  }

  get role() {
    return this.user.roles?.data[0];
  }
  get avatar() {
    return this.user.avatar?.data;
  }

  ngOnInit() {
  }


  edit() {
    this.formsService.editModel('user', this.user.id, null, this.listId);
  }

  delete() {
    this.dialog.confirm(`Удалить пользователя ${this.user.name}?`).subscribe(
      resp=>{
        if (resp) this.usersService.delete(this.user.id).subscribe(res => {
          this.listsService.refresh(this.listId, true);
        });
      }
    )
  }
}
