import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ListsService {
  public lists: any = {};
  constructor() {
  }


  list(id?: string) {
    id = id || null;
    if (!this.lists[id]) this.lists[id] = this.init();
    return this.lists[id];
  }
  init() {
    return {
      controls: new BehaviorSubject<any>({filters: {}, order: {}, page: 1}),
      result: new BehaviorSubject<any>({})
    };
  }

  controls(id?: string): BehaviorSubject<any> {
    return this.list(id).controls;
  }
  result(id?: string): BehaviorSubject<any> {
    return this.list(id).result;
  }



  setFilters(filters: any, id?: string) {
    let controls = this.list(id).controls.value;
    controls.filters = filters;
    this.setControls(controls, id);
  }
  setFilter(prop: string, val: any, id?: string) {
    let controls = this.list(id).controls.value;
    controls.filters[prop] = val;
    this.setControls(controls, id);
  }
  addFilters(filters: any, id?: string) {
    let controls = this.list(id).controls.value;
    for (let prop in filters) {
      if (filters.hasOwnProperty(prop)) controls.filters[prop] = filters[prop];
    }
    this.setControls(controls, id);
  }
  setOrder(order: any, id?: string) {
    let controls = this.list(id).controls.value;
    controls.order = order;
    this.setControls(controls, id);
  }

  setControls(controls: any, id?: string, keepPage?: boolean) {
    if (!keepPage) controls.page = 1;
    this.list(id).controls.next(controls);
  }


  setPage(page: number, id?: string) {
    let controls = this.list(id).controls.value;
    controls.page = page;
    this.list(id).controls.next(controls);
  }

  refresh(id?: string, keepPage?: boolean) {
    this.setControls(this.list(id).controls.value, id, keepPage);
  }

}
