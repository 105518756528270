<div class="area" [class.hover]="dragOver">
  <input #fileInput type="file" [id]="field.name" (change)="onFileInput($event)" multiple="multiple" />
  <p>Перетащите сюда или <a (click)="fileInput.click()">выберите файлы</a>
    в формате PDF, XLS, DOC, JPG, PPTX
    <!-- общим объемом не более 10 Мбайт.-->
  </p>
  <div class="indicator" *ngIf="upload.file">
    <div class="label">Загружается файл {{upload.file?.name}}</div>
    <div class="progress"><div class="fill" [style.width]="upload.progress + '%'"></div></div>
  </div>
</div>
<div class="values" [sortablejs]="value" [sortablejsOptions]="documentsValues">
  <field-document-value *ngFor="let asset of assets" [asset]="asset" (delete)="removeValue(asset)"></field-document-value>
</div>
