import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class PublicationsService {
  constructor(private http: HttpClient) {
  }

  find(url: string, params?: any): Observable<any> {
    if (!params) params = {};
    params.url = url;
    return this.http.get(`${environment.apiUrl}/api/publications/find`, {params: params});
  }

  list(params?: {}): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/publications`, {params: params});
  }

  show(id: string, params?: {}): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/publications/${id}`, {params: params});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/publications/${id}`);
  }

  published(id: string, publish: boolean): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/api/publications/published/${id}`, {publish: publish});
  }
}
