import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'field-text-single',
  templateUrl: 'field-text-single.component.html',
  styleUrls: ['field-text-single.component.scss', '../form-field-text.component.scss']
})
export class FieldTextSingleComponent {
  @Input() field: any;
  @Input() formGroup: FormGroup;

  constructor() {
  }

  ngOnInit() {
    if (this.value) this.field.value = {data: [this.value]};
    setTimeout(() => {this.value = this.value || this.initialValue});
  }


  get initialValue() {
    return this.field.value?.data[0] || null;
  }
  get control() {
    return this.formGroup.controls[this.field.name];
  }
  get value() {
    return this.control?.value;
  }
  set value(value) {
    this.control?.setValue(value);
  }
  get maxLength() {
    return this?.field?.maxLength || 65535;
  }

}
