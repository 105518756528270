import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FilterFieldBooleanModule,
  FilterFieldDateModule,
  FilterFieldDatetimeModule,
  FilterFieldFloatModule,
  FilterFieldIntegerModule,
  FilterFieldRelationModule,
  FilterFieldStringModule,
  FilterFieldTextModule,
} from "@app/_modules/filter-fields/types";
import {FilterFieldComponent} from "@app/_modules/filter-fields/field/filter-field.component";

@NgModule({
  imports: [
    CommonModule,
    FilterFieldStringModule,
    FilterFieldTextModule,
    FilterFieldIntegerModule,
    FilterFieldFloatModule,
    FilterFieldBooleanModule,
    FilterFieldDateModule,
    FilterFieldDatetimeModule,
    FilterFieldRelationModule,
  ],
  declarations: [
    FilterFieldComponent
  ],
  exports: [
    FilterFieldComponent
  ]
})
export class FilterFieldsModule {}
