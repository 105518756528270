import {Component, Input} from '@angular/core';
import {FormsService, ObjectsService} from "@app/_services";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'member-section',
  templateUrl: 'member-section.component.html',
  styleUrls: ['member-section.component.scss']
})
export class MemberSectionComponent {
  @Input() section: any;
  @Input() editMode: boolean;
  @Input() page: any;

  constructor(
    private formsService: FormsService,
    private router: Router,
    private objectsService: ObjectsService,
  ) {
  }

  public id: string = '';
  public item: any;

  ngOnInit() {
    let links = this.router.url.split('?');
    if (links.length > 0) {
      this.id = links[1]?.split('=')[1];
      this.objectsService.fetchObject(this.id, {include: 'groups.fields.value'}).subscribe(res => {
        this.item = res.data;
      })
    }
  }
}
