import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {PageNotFoundComponent} from "@app/_modules/pages/not-found/page-not-found.component";
import {PageComponent} from "@app/_modules/pages/page/page.component";
import {pageBreadcrumbsComponent} from "@app/_modules/pages/page/breadcrumbs/page-breadcrumbs.component";
import {PageSectionsModule} from "@app/_modules/pages/sections/page-sections.module";
import {PageSidebarsModule} from "@app/_modules/pages/sidebars/page-sidebars.module";
import {ContentPageComponent} from "@app/_modules/pages/page/types/content/content-page.component";
import {PublicationsPageComponent} from "@app/_modules/pages/page/types/publications/publications-page.component";
import {RegistryPageComponent} from "@app/_modules/pages/page/types/registry/registry-page.component";
import {RegistriesModule} from "@app/_modules/registries/registries.module";
import {PublicationsModule} from "@app/_modules/publications/publications.module";
import {TkStructurePageComponent} from "@app/_modules/pages/page/types/tk-structure/tk-structure-page.component";
import {HomepageModule} from "@app/_modules/pages/home/homepage.module";
import { SliderMenuComponent } from './menu/slider-menu/slider-menu.component';

const routes: Routes = [
  {path: '', component: PageComponent},
  {path: '**', component: PageComponent},
  {path: 'pages-menu', component:SliderMenuComponent, outlet: 'slider'},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    HomepageModule,
    PageSectionsModule,
    PageSidebarsModule,
    RegistriesModule,
    PublicationsModule
  ],
  declarations: [
    PageComponent,
    PageNotFoundComponent,
    pageBreadcrumbsComponent,
    ContentPageComponent,
    PublicationsPageComponent,
    RegistryPageComponent,
    TkStructurePageComponent
  ],
  exports: [
    RouterModule
  ]
})
export class PagesRoutingModule {}
