import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {FormsService} from "@app/_services";

@Component({
  selector: 'object-form',
  templateUrl: 'object-form.component.html',
  styleUrls: ['object-form.component.scss']
})
export class ObjectFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() target: string;
  @Input() type?: string;
  @Input() id?: string;
  @Input() readonly = false;
  @Input() params: any = {};

  @Output() onFormActivated = new EventEmitter<any>();

  public form: any;

  constructor(private formsService: FormsService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.target && (this.type || this.id)) this.fetch();
  }


  fetch() {
    let include = 'groups.fields.value,groups.fields.options';
    this.formsService.get(this.target, this.type, this.id, {include: include}).subscribe(res => {
      this.form = res.data || res;
    });
  }


}
