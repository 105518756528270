import {Component, Input} from '@angular/core';
import {CompaniesService} from "@app/_services/companies.service";
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";

@Component({
  selector: 'administrate-company',
  templateUrl: 'administrate-company.component.html',
  styleUrls: ['administrate-company.component.scss']
})
export class AdministrateCompanyComponent {
  @Input() companyId: string;
  public company: any;

  subscription: Subscription;

  constructor(private companiesService: CompaniesService, private listsService: ListsService) {
  }


  get permissions() {
    return this.company?.permissions;
  }
  get isEditable() {
    return this.permissions?.edit || this.permissions?.anything;
  }


  ngOnInit() {
    this.subscription = this.listsService.controls('company-main-info').subscribe(res => {
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  fetch() {
    let include = ['phones', 'emails', 'legalAddress', 'logo', 'permissions'];
    this.companiesService.fetch(this.company?.id || this.companyId, {include: include.join(',')}).subscribe(res => {
      this.company = res.data;
    });
  }

}
