import {Component, Input} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {isNumeric} from "rxjs/internal-compatibility";
//import {CompaniesService} from "@app/_services/companies.service";
import {FormFieldsService} from "@app/_services/form-fields.service";

@Component({
  selector: 'form-field',
  templateUrl: 'form-field.component.html',
  styleUrls: ['form-field.component.scss']
})
export class FormFieldComponent {
  @Input() field!: any;
  @Input() formGroup!: FormGroup;
  @Input() readonly = false;
  @Input() params?: any = {};

  constructor(private fieldsService: FormFieldsService, /*private companiesService: CompaniesService*/) {
  }

  ngOnInit() {
    this.field.value = {data: this.fieldsService.prepareFieldValue(this.field)};
    if (this.readonly) this.field.readonly = true;
    if (!this.field.readonly) this.formGroup.addControl(this.field.name, this.makeControl());
  }

  get control() {
    return this.formGroup?.controls[this.field.name];
  }

  get isInvalid() {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }



  makeControl() {
    let control = new FormControl(null, (this.field.required && !this.field.hidden) ? Validators.required : null);
    if (this.field.name === 'inn') control.valueChanges.pipe(debounceTime(300)).subscribe(val => {
      if (isNumeric(val) && (`${val}`.length === 10)) this.getCompanyData(val);
    });
    return control;
  }


  getCompanyData(inn) {
    /*
    this.companiesService.getDataByInn(inn).subscribe(res => {
      this.setControlValue('name', res.company?.name);
      this.setControlValue('address', res.address?.full);
    });
    */
  }

  setControlValue(name, value) {
    let control = this.formGroup.controls[name];
    if (control && value) {
      control.setValue(value);
      control.updateValueAndValidity();
    }
  }

}
