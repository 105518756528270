import {Component, Input} from '@angular/core';
import {FormsService, ListsService, ObjectsService} from "@app/_services";

@Component({
  selector: 'page-sidebar',
  templateUrl: 'page-sidebar.component.html',
  styleUrls: ['page-sidebar.component.scss']
})
export class PageSidebarComponent {
  @Input() page: any;
  @Input() sidebar: any;
  @Input() index: number;
  @Input() editMode: boolean;

  constructor(private objectsService: ObjectsService, private formsService: FormsService, private listsService: ListsService) {
  }

  get ord() {
    return this.sidebar?.pivot?.ord;
  }
  get total() {
    return this.page?.sidebars?.data?.length;
  }
  get isFirst() {
    return this.index === 0;
  }
  get isLast() {
    return (this.index + 1) === this.total;
  }

  get header() {
    return this.objectsService.getValue(this.sidebar, 'header');
  }
  get html() {
    return this.objectsService.getValue(this.sidebar, 'html');
  }
  get documents() {
    return this.objectsService.getValue(this.sidebar, 'documents');
  }


  ngOnInit() {

  }


  edit() {
    this.formsService.editObject(this.sidebar.id);
  }

  move(ord) {
    this.objectsService.move(this.sidebar.id, {modelType: 'page', modelId: this.page.id, ord: ord}).subscribe(res => {
      this.listsService.refresh();
    });
  }

  delete() {
    if (confirm('Вы точно хотите удалить блок?')) this.objectsService.destroy(this.sidebar.id).subscribe(res => {
      this.listsService.refresh();
    });
  }


}
