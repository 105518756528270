<div [formGroup]="formGroup">
  <div class="items" [formArrayName]="field.name" [sortablejs]="value">
    <div class="item" *ngFor="let val of control.controls; let i = index">
      <div class="left">
        <textarea [formControlName]="i" [maxLength]="maxLength"></textarea>
      </div>
      <div class="right" *ngIf="control.controls.length > 1"><button type="button" class="btn icon trash" (click)="remove(i)"></button></div>
    </div>
  </div>
  <div class="add"><button class="btn" type="button" (click)="add()">Добавить значение</button></div>
</div>
