import {Component, Input} from '@angular/core';

@Component({
  selector: 'field-image-readonly',
  templateUrl: 'field-image-readonly.component.html',
  styleUrls: ['../form-field-image.component.scss', 'field-image-readonly.component.scss']
})
export class FieldImageReadonlyComponent {
  @Input() field!: any;

  constructor() {
  }

  ngOnInit() {

  }

  get value() {
    return this.field.value?.data || [];
  }


}
